<template>
  <div class="form-item form-item_groupped form-item_groupped_required">
    <label v-if="label"><span>{{label}}</span></label>
    <div class="checkboxes">
      <div class="row">
        <div v-for="(option, key) in options" :key="key" :class="{'col-12 mb-2': vertical, 'col-auto': !vertical}">
          <div class="radio">
            <input v-model="model" v-validate.immediate="validate" type="radio" :id="key" :name="option.name" :value="option.value"/>
            <label class="radio" :for="key">{{ option.name }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/form/BaseInput.vue";

export default {
  name: "BaseRadio",
  extends: BaseInput,
  props: {
    value: {
      type: [Boolean, String]
    },
    vertical: {
      type: Boolean,
      default: false
    },
    validate: {
      type: String,
      default: 'required'
    },
    options: {
      type: Array,
      default() {
        return [
          {
            name: 'Да',
            value: true
          },
          {
            name: 'Нет',
            value: false
          }
        ]
      }
    },
    label: {
      type: [String, Boolean],
      default: false
    }
  }
}
</script>

<style scoped>

</style>