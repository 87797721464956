<template>
  <div class="mt-5">
    <modal-seminar-download text="Пожалуйста, добавьте сотрудников в разделе &laquo;Регистрация сотрудников&raquo;" />
    <form @submit.prevent="validate()">
      <div class="d-flex justify-content-between align-items-center mb-32">
        <h3 class="m-0">Документы для загрузки</h3>
        <a href="#" @click.prevent="getTemplate()" class="docs-needed d-none d-md-block d-lg-block">
          Скачать шаблон направления
        </a>
      </div>
      <a href="#" @click.prevent="getTemplate()" class="docs-needed d-lg-none">
        Скачать шаблон направления
      </a>
      <base-upload :required="true" id="forward" name="forward" v-model="mspForsageOrder.forward" title="Направление от компании" description="Заполните направление на основании шаблона на бланке организации с указанием ФИО и должности сотрудников, участвующих в акселерационной программе. Подпишите у руководителя организации (например, генеральный директор), проставьте оттиск печати и загрузите скан." />
      <base-upload :required="false" v-model="mspForsageOrder.attorney" id="attorney" name="attorney" title="Доверенность на подписанта" description="Загружается в случае, если документы подписывает сотрудник организации, действующий по доверенности." />
      <div class="separator"></div>
      <msp-forsage-footer/>
    </form>
  </div>
</template>

<script>
import BaseUpload from "@/components/form/BaseUpload.vue";
import MspForsageFooter from "@/components/profile/mspForsage/MspForsageFooter.vue";
import validateMixin from "@/mixins/validateMixin";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import $ from "jquery";
import ApiService from "../../../../services/api.service";
import ModalSeminarDownload from "@/components/ModalSeminarDownload.vue";
import {TokenService} from "../../../../services/token.service";

export default {
  name: "MspForsageDocs",
  components: {ModalSeminarDownload, MspForsageFooter, BaseUpload},
  mixins: [validateMixin],
  mounted() {
    this.$on('on-submit', async () => {
      await this.updateMspForsageOrder({
        ...this.data,
        status: 'draft',
        id: this.mspForsageOrder.id
      })
      this.$toastr.s('', "Черновик успешно сохранен");
    })
  },
  methods: {
    ...mapActions(['updateMspForsageOrder']),
    ...mapMutations(['setMspForsageOrder']),
    async send() {
      await this.updateMspForsageOrder({
        ...this.data,
        status: 'send',
        id: this.mspForsageOrder.id
      })
      this.$toastr.s('', "Заявка успешно отправлена");
      window.location.reload()
    },
    async getTemplate() {
      if(!this.acceleratorMembers.length) {
        $('#seminar-download-doc').modal('show');
      } else {
        window.open(ApiService.baseURL() + 'api/documents/' + this.mspForsageOrder.id + '/order?access_token=' + TokenService.getAccessToken(), '_blank')
      }
    }
  },
  computed: {
    ...mapGetters(['acceleratorMembers']),
    data() {
      return {
        forward: this.mspForsageOrder.forward?.id,
        attorney: this.mspForsageOrder.attorney?.id
      }
    },
    ...mapState({acceleratorFromStore: 'mspForsageOrder'}),
    mspForsageOrder: {
      get () {
        return this.acceleratorFromStore.mspForsageOrder
      },
      set (value) {
        this.setMspForsageOrder(value)
      }
    }
  },
  watch: {
    'mspForsageOrder.forward': {
      handler(val, oldVal) {
        if(!oldVal || val.id !== oldVal.id) {
          this.updateMspForsageOrder({
            forward: val.id,
            id: this.mspForsageOrder.id,
            attorney: this.mspForsageOrder.attorney?.id,
            status: 'draft'
          })
        }
      }
    },
    'mspForsageOrder.attorney': {
      handler(val, oldVal) {
        if(!oldVal || val.id !== oldVal.id) {
          this.updateMspForsageOrder({
            attorney: val.id,
            forward: this.mspForsageOrder.forward?.id,
            id: this.mspForsageOrder.id,
            status: 'draft'
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>