<template>
  <div class="mb-5">
    <modal-seminar-download
        title="Шаблон договора"
        text="Пожалуйста, заполните все обязательные поля в заявке, чтобы мы сформировали для Вас шаблон договора на обучение." />
    <p>После заполнения всех полей заявки для Вас будет сформирован проект договора для прохождения обучения. Пожалуйста, ознакомьтесь с условиями договора перед отправкой заявки.</p>
    <p>В данный момент не нужно подписывать договор – сотрудники Школы экспорта РЭЦ пришлют Вам на электронную почту окончательную версию договора.</p>
    <h3 class="mt-4">Выберите способ оплаты</h3>
    <sweet-select
        id="paymentType"
        v-validate.immediate="'required'"
        :class="{invalid: errors.has('paymentType')}"
        name="paymentType"
        @update:modelValue="changes"
        v-model="model.type"
        :options="[{id: 'civil', name: 'Оплачиваю обучение самостоятельно'},{id: 'llc', name: 'За меня заплатит организация '}]">
    </sweet-select>
    <form-separator v-if="model.type === 'llc'">
      <base-input v-model="model.address" name="address" label="Юридический и почтовый адрес организации"/>
      <base-input v-model="model.kpp" name="kpp" label="КПП"/>
      <base-input v-model="model.bank" name="bank" label="Банк"/>
      <base-input v-model="model.rs" name="rs" label="Расчетный счет"/>
      <base-input v-model="model.ks" name="ks" label="Корр. счет"/>
      <base-input v-model="model.bik"  name="bik" label="БИК"/>
      <base-input v-model="model.email"  name="email" label="Email"/>
      <base-input v-model="model.phone"  name="phone" label="Телефон"/>
    </form-separator>
    <form-separator>
      <h3>Выберите модули программы, по которым вы хотите пройти обучение:</h3>
      <multiselect
          id="modules_events"
          :multiple="true"
          :class="{invalid: errors.has('modules')}"
          selectLabel=""
          trackBy="id"
          label="name"
          v-model="model.programs"
          v-validate.immediate="'required'"
          placeholder="Начните ввод"
          name="modules"
          :options="programs">
      </multiselect>
    </form-separator>
    <div class="mt-3 d-flex align-items-center">
      <img class="mr-2" src="/pic/icon-doc.svg" />
      <a href="#" @click.prevent="getTemplate()" class="docs-needed">
        Скачайте проект договора
      </a>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import SweetSelect from "@/components/form/SweetSelect.vue";
import FormSeparator from "@/components/form/FormSeparator.vue";
import $ from "jquery";
import ModalSeminarDownload from "@/components/ModalSeminarDownload.vue";
import {TokenService} from "../../services/token.service";
import BaseInput from "@/components/form/BaseInput.vue";
import vmMixin from "@/mixins/vmMixin";
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: "MspEventOrder",
  components: {BaseInput, ModalSeminarDownload, FormSeparator, SweetSelect, Multiselect},
  mixins: [vmMixin],
  data() {
    return {
      model: {},
      programs: [
        {id: "1", name: 'Модуль 1 «Стратегия для тренеров»', duration: 25},
        {id: "2", name: 'Модуль 2 «Финансовое планирование для тренеров»', duration: 25},
        {id: "3", name: 'Модуль 3 «Маркетинг и продажи для тренеров»', duration: 25},
        {id: "4", name: 'Модуль 4 «Производство и операционная эффективность для тренеров»', duration: 25},
        {id: "5", name: 'Модуль 5 «Переговоры. Подготовка бизнес-плана для тренеров»', duration: 17}
      ]
    }
  },
  async mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  },
  methods: {
    async getTemplate() {
      if(this.errors.items.length) {
        $('#seminar-download-doc').modal('show');
      } else {
        await this.$parent.draft()
        window.open(
            ApiService.baseURL()
            + 'api/documents/'
            + this.$route.params.id
            + '/contract?access_token='
            + TokenService.getAccessToken(),
            '_blank')
      }
    }
  }
}
</script>

<style scoped>

</style>