<template>
  <div class="mt-5 accelerator-profile">
    <h3>Профиль компании</h3>
    <form @submit.prevent="validate()">
      <base-input label="Основной вид(ы) продукции/услуг" name="field" v-model="acceleratorProfile.field"/>
      <base-input label="Бренд(ы) компании" name="brands" v-model="acceleratorProfile.brands"/>
      <base-input label="Перечень товаров, планируемых на экспорт (с указанием кодов ТН ВЭД)" name="tnvd" v-model="acceleratorProfile.tnvd"/>
      <base-radio label="Опыт в экспортной деятельности" name="export" :options="radiosExport" v-model="acceleratorProfile.export"/>
      <base-label label="Если «да», то в какие страны были поставки за последние 2 года? (до 3 стран)">
        <multiselect
            v-if="mixinCountries"
            :max="3"
            id="countries"
            multiple
            :class="{invalid: errors.has('countries')}"
            selectLabel=""
            trackBy="country_name_ru"
            label="country_name_ru"
            selectedLabel=""
            deselectLabel=""
            v-model="acceleratorProfile.countries"
            v-validate.immediate="'required'"
            placeholder="Начните ввод"
            name="countries"
            :options="mixinCountries">
          <template slot="maxElements">
            Максимально можно выбрать только три страны.
          </template>
        </multiselect>
      </base-label>
      <base-label label="Какие страны планируете прорабатывать в программе? (до 5 стран)">
        <multiselect
            v-if="countries"
            :max="5"
            id="aer_countries"
            multiple
            :class="{invalid: errors.has('aer_countries')}"
            selectLabel=""
            trackBy="country_name_ru"
            label="country_name_ru"
            selectedLabel=""
            deselectLabel=""
            v-model="acceleratorProfile.aer_countries"
            v-validate.immediate="'required'"
            placeholder="Начните ввод"
            name="aer_countries"
            :options="countries">
          <template slot="maxElements">
            Максимально можно выбрать только пять стран.
          </template>
        </multiselect>
      </base-label>
      <base-radio :vertical="true" label="Доля экспорта в деятельности компании" :options="radios" name="share" v-model="acceleratorProfile.share"/>
      <base-input label="Выручка компании за последний финансовый год, млн. руб." :validate="'required|decimal'"  name="revenue" v-model="acceleratorProfile.revenue"/>
      <div class="separator"></div>
      <msp-forsage-footer />
    </form>
  </div>
</template>

<script>
import BaseInput from "@/components/form/BaseInput.vue";
import BaseRadio from "@/components/form/BaseRadio.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import MspForsageFooter from "@/components/profile/mspForsage/MspForsageFooter.vue";
import validateMixin from "@/mixins/validateMixin";
import mspFooterMixin from "@/mixins/mspFooterMixin";

export default {
  name: "MspForsageProfile",
  components: {MspForsageFooter, BaseLabel, BaseRadio, BaseInput, Multiselect},
  mixins: [validateMixin, mspFooterMixin],
  data() {
    return {
      radios: [
        {name: 'доля экспорта в общем объеме выручки в среднем за три последних года более 15% и растет', value: 'доля экспорта в общем объеме выручки в среднем за три последних года более 15% и растет'},
        {name: 'доля экспорта в общем объеме выручки в среднем за три последних года более 15% и стабильна', value: 'доля экспорта в общем объеме выручки в среднем за три последних года более 15% и стабильна'},
        {name: 'доля экспорта в общем объеме выручки в среднем за три последних года более 15% и сокращается', value: 'доля экспорта в общем объеме выручки в среднем за три последних года более 15% и сокращается'},
        {name: 'доля экспорта в общем объеме выручки в среднем за три последних года менее 15%', value: 'доля экспорта в общем объеме выручки в среднем за три последних года менее 15%'},
        {name: 'в течение трех последних лет экспортные поставки осуществлялись в 2-х и менее годах', value: 'в течение трех последних лет экспортные поставки осуществлялись в 2-х и менее годах'},
      ],
      radiosExport: [
        {name: 'Да', value: 'yes'},
        {name: 'Нет', value: 'no'}
      ],
      mixinCountries: null,
      sources: [ 'из рассылки от регионального органа исполнительной власти', 'из рассылки от Школы экспорта РЭЦ', 'из рекламы']
    }
  },
  async mounted() {
    this.$on('on-submit', async () => {
      await this.updateAcceleratorProfile(
          {...this.acceleratorProfile, mspForsageOrder: this.mspForsageOrder.id}
      )
    })
    this.mixinCountries = this.getCountries()
  },
  computed: {
    ...mapState({acceleratorProfileFromStore: 'acceleratorProfile'}),
    ...mapGetters(['countries', 'mspForsageOrder']),
    acceleratorProfile: {
      get () {
        return this.acceleratorProfileFromStore.acceleratorProfile
      },
      set (value) {
        this.setAcceleratorProfile(value)
      }
    }
  },
  methods: {
    getCountries() {
      let countries = Object.assign([], this.countries)
      countries.unshift({country_name_ru: 'Поставок не было'})
      return countries
    },
    ...mapActions(['updateAcceleratorProfile']),
    ...mapMutations(['setAcceleratorProfile'])
  }
}
</script>

<style lang="scss" scoped>
</style>