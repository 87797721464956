// @ts-nocheck
export default {
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        show() {
            this.showModal = !this.showModal
        }
    }
}