<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" :class="modalClass">
      <div class="modal-content">
        <div class="modal-header" :class="calcHeaderClass">
          <div class="title-with-icon">
            <div v-if="icon" class="title-with-icon__icon title-with-icon__icon-white" :class="'title-with-icon__icon_' + icon"></div>
            <div class="title-with-icon__title" v-html="title"></div>
          </div>
          <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" :class="bodyClass">
          <h3 v-if="content" class="text mt-5 mb-2" v-html="content"></h3>
          <template v-if="confirmMode">
            <div class="d-flex justify-content-between align-items-center mt-3">
              <button type="button" class="btn btn-md btn-secondary mr-5" data-dismiss="modal">Нет</button>
              <slot></slot>
            </div>
          </template>
          <template v-else>
            <slot></slot>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
window.jQuery = $
export default {
  name: "BaseModal",
  props: {
    icon: {
      type: [String, Boolean],
      default() {
        return 'exclamation'
      }
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      default() {
        return ''
      }
    },
    modalClass: {
      type: String,
      default() {
        return 'modal-xl'
      }
    },
    headerClass: {
      type: String,
      default() {
        return 'modal-header-blue-bg'
      }
    },
    bodyClass: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: Boolean,
      required: true
    },
    confirmMode: {
      type: Boolean,
      default() {
        return false
      }
    },
    id: {
      type: String,
      default() {
        return 'modal'
      }
    }
  },
  mounted() {
    let self = this
    $('#' + this.id).on('hide.bs.modal', function () {
      self.$emit('input', false)
    })
  },
  computed: {
    calcHeaderClass() {
      return this.headerClass + (this.confirmMode ? ' confirm' : '')
    }
  },
  watch: {
    value: {
      handler: async function(val) {
        $('#' + this.id).modal(val ? 'show' : 'hide');
      }
    }
  }
}
</script>

<style scoped>
.confirm {
  padding: 2rem 4rem;
}
</style>