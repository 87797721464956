const PREFIX = 'export.';
const TOKEN_KEY = PREFIX + "token";
const EXPIRE_KEY = PREFIX + "token_expire";
const USER_KEY = PREFIX + "user";
const ORGANIZATION_KEY = PREFIX + "organization";
const PASSPORT_KEY = PREFIX + "passport";
const REFRESH_TOKEN_KEY = PREFIX + "refresh_token";

const TokenService = {
	getToken() {
		return localStorage.getItem(TOKEN_KEY);
	},
	getAccessToken() {
		return localStorage.getItem(TOKEN_KEY)?.replaceAll('"', '')
	},
	setToken(accessToken: string) {
		localStorage.setItem(TOKEN_KEY, '"' + accessToken + '"');
	},
	setExpire(expires_in: number) {
		localStorage.setItem(EXPIRE_KEY, (new Date().getTime() + (expires_in * 1000) - 5000).toString());
	},
	clear() {
		localStorage.clear()
	},
	getUser() {
		return localStorage.getItem(USER_KEY);
	},
	getPassport() {
		const org = localStorage.getItem(PASSPORT_KEY)
		if(org) {
			return JSON.parse(org);
		}
		return org;
	},
	setPassport(organization: object) {
		localStorage.setItem(PASSPORT_KEY, JSON.stringify(organization));
	},
	getOrganization() {
		const org = localStorage.getItem(ORGANIZATION_KEY)
		if(org) {
			return JSON.parse(org);
		}
		return org;
	},
	setOrganization(organization: object) {
		localStorage.setItem(ORGANIZATION_KEY, JSON.stringify(organization));
	},
	getUserObj() {
		const user = this.getUser();
		if(user) {
			return JSON.parse(user)
		}
	},
	clearUser() {
		return localStorage.removeItem(USER_KEY);
	},
	setUser(user: object) {
		localStorage.setItem(USER_KEY, JSON.stringify(user));
	},
	removeToken() {
		localStorage.removeItem(TOKEN_KEY);
	},
	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},
	setRefreshToken(refreshToken: string) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},
	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	},
	set(key: string, val: any) {
		localStorage.setItem(PREFIX + key, val);
	},
	del(key: string) {
		localStorage.removeItem(PREFIX + key);
	},
	get(key: string) {
		localStorage.getItem(PREFIX + key);
	},
	hasRole(role: any) {
		const user = this.getUser()
		if (!user) {
			return false;
		}
		const roles = JSON.parse(user).roles
		return roles.indexOf(role) > -1
	},
	hasRoles(roles: []) {
		let exist = false
		roles.forEach(role => {
			if(this.hasRole(role)) {
				exist = true
			}
		})
		return exist;
	}
};

export { TokenService };
