import ApiService from "../../services/api.service";

export default {
    state: {
        mspForsageGroups: null,
        mspForsageGroup: null,
    },
    actions: {
        async getMspForsageGroups(ctx: any, filters: any) {
            return new Promise((resolve, reject) => {
                ApiService.get('msp-forsage/groups', {
                    params: {
                        'filters[enrollProgram]': filters.program,
                        'filters[region]': filters.region,
                    }
                })
                    .then(res => {
                        ctx.commit('setMspForsageGroups', res.data)
                        resolve(res.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        async updateMspForsageGroups(ctx: any, data: any) {
            return new Promise((resolve) => {
                ctx.commit('setMspForsageGroups', data)
                resolve(data)
            })
        },
        async updateMspForsageGroup(ctx: any, group: any) {
            return new Promise((resolve, reject) => {
                ApiService.put('msp-forsage/groups/' + group.id, group)
                    .then(res => {
                        ctx.commit('setMspForsageGroup', res.data)
                        resolve(res.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
    mutations: {
        setMspForsageGroup(state: any, notes: any) {
            state.mspForsageGroup = notes
        },
        setMspForsageGroups(state: any, groups: any) {
            state.mspForsageGroups = groups
        },
    },
    getters: {
        mspForsageGroup(state: any) {
            return state.mspForsageGroup
        },
        mspForsageGroups(state: any) {
            return state.mspForsageGroups
        }
    }
}
