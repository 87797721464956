<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14 12.5H10C9.73478 12.5 9.48043 12.6054 9.29289 12.7929C9.10536 12.9805 9 13.2348 9 13.5C9 13.7652 9.10536 14.0196 9.29289 14.2071C9.48043 14.3947 9.73478 14.5 10 14.5H14C14.2652 14.5 14.5196 14.3947 14.7071 14.2071C14.8946 14.0196 15 13.7652 15 13.5C15 13.2348 14.8946 12.9805 14.7071 12.7929C14.5196 12.6054 14.2652 12.5 14 12.5ZM19 5.50002H12.72L12.4 4.50002C12.1926 3.91325 11.8077 3.40553 11.2989 3.04718C10.7901 2.68884 10.1824 2.49762 9.56 2.50002H5C4.20435 2.50002 3.44129 2.81609 2.87868 3.3787C2.31607 3.94131 2 4.70437 2 5.50002V18.5C2 19.2957 2.31607 20.0587 2.87868 20.6213C3.44129 21.184 4.20435 21.5 5 21.5H19C19.7956 21.5 20.5587 21.184 21.1213 20.6213C21.6839 20.0587 22 19.2957 22 18.5V8.50002C22 7.70437 21.6839 6.94131 21.1213 6.3787C20.5587 5.81609 19.7956 5.50002 19 5.50002ZM20 18.5C20 18.7652 19.8946 19.0196 19.7071 19.2071C19.5196 19.3947 19.2652 19.5 19 19.5H5C4.73478 19.5 4.48043 19.3947 4.29289 19.2071C4.10536 19.0196 4 18.7652 4 18.5V5.50002C4 5.23481 4.10536 4.98045 4.29289 4.79292C4.48043 4.60538 4.73478 4.50002 5 4.50002H9.56C9.76964 4.49948 9.97416 4.56484 10.1446 4.68686C10.3151 4.80889 10.4429 4.9814 10.51 5.18002L11.05 6.82002C11.1171 7.01864 11.2449 7.19116 11.4154 7.31318C11.5858 7.4352 11.7904 7.50056 12 7.50002H19C19.2652 7.50002 19.5196 7.60538 19.7071 7.79292C19.8946 7.98045 20 8.23481 20 8.50002V18.5Z"
        :fill="fill"/>
  </svg>
</template>

<script>
import iconMixin from "@/mixins/iconMixin";

export default {
  name: "IconDeleteFromFolder",
  mixins: [iconMixin]
}
</script>

<style scoped>

</style>