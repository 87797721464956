<template>
  <div class="section__lk-content-item-left">
    <div class="section__lk-content-item-data">
      <div class="section__lk-content-item-data-content">
        <div class="d-flex align-items-start mb-1">
          <div style="width: 33px;">
            <slot name="icon"></slot>
          </div>
          <div class="m-0">
            <div class="section__lk-content-item-subheader">
              Заявка от
            </div>
            <div class="text-dark m-0">
              <slot name="name"></slot>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-start mb-1">
          <div class="mr-1">
            <icon-author/>
          </div>
          <div class="m-0">
            <div class="section__lk-content-item-subheader">
              Email
            </div>
            <div class="text-dark mb-1 mt-0">
              <slot name="author"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import IconAuthor from "@/components/icons/IconAuthor.vue";

export default {
  name: "MspForsageOrdersItemName",
  components: {IconAuthor}
}
</script>

<style scoped>

</style>