<template>
  <div class="section__lk-content container-shadow fill-white">
    <modal-avatar-upload />
    <div class="d-flex">
      <div class="section__lk-content__left">
        <div class="section__lk-title">Настройки / Личные данные</div>
      </div>
      <div class="section__lk-content__right">
        <div class="section__lk-title-right">Фотография профиля</div>
      </div>
    </div>
    <div class="d-flex">
      <div class="section__lk-content__left">
        <div class="d-sm-block d-lg-none d-md-none d-xl-none">
          <upload-link />
        </div>
        <form>
          <user-settings :event-mode="true" :show-header="false" :show-address="false" v-model="user" />
          <div class="separator"></div>
          <education-settings v-model="education" />
          <div class="separator"></div>

          <change-email :email="user.username" />
          <change-password />
          <div class="separator"></div>
          <buttons-footer type="profile"  @validate="validate()" />
        </form>
      </div>
      <div class="section__lk-content__right">
        <upload-link />
        <recommend-banner />
      </div>
    </div>
  </div>
</template>

<script>
import {TokenService} from "../../../../services/token.service";
import ApiService from "../../../../services/api.service";
import ModalAvatarUpload from "@/components/modal/ModalAvatarUpload.vue";
import 'bootstrap'
import {eventBus} from "@/main";
import UploadLink from "@/components/profile/UploadLink.vue";
import ChangeEmail from "@/components/profile/ChangeEmail.vue";
import ChangePassword from "@/components/profile/ChangePassword.vue";
import UserSettings from "@/components/profile/UserSettings.vue";
import EducationSettings from "@/components/EducationSettings.vue";
import ButtonsFooter from "@/components/profile/ButtonsFooter.vue";
import RecommendBanner from "@/components/RecommendBanner.vue";
import validateMixin from "@/mixins/validateMixin";
export default {
  name: "ProfileSettings",
  components: {
    RecommendBanner,
    ButtonsFooter,
    EducationSettings,
    UserSettings, ChangePassword, ChangeEmail, UploadLink, ModalAvatarUpload
  },
  mixins: [validateMixin],
  data() {
    return {
      education: JSON.parse(TokenService.getUser()),
      user: JSON.parse(TokenService.getUser())
    }
  },
  async mounted() {
    eventBus.$on('profile.draft', async () => {
      let validate = await this.$validator.validate()
      if(validate) {
        this.$emit('on-submit')
      } else {
        this.$toastr.e('', "Проверьте правильность заполнения полей");
      }
    })
    let self = this
    eventBus.$on('user:upd', () => {
      self.$nextTick(() => {
        self.user = JSON.parse(TokenService.getUser())
      })
    })
    this.$on('on-submit', () => {
      delete this.education.sex
      ApiService.put('user', {
        ...this.user,
        ...this.education,
        region: this.user.region.id,
        institute_country: 1
      }).then((res) => {
        if(res.status === 200) {
          TokenService.setUser(res.data);
          eventBus.$emit('user:upd');
          this.$toastr.s('', "Изменения успешно сохранены");
        }
      })
    })
  }
}
</script>