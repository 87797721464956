<template>
  <div>
    <div class="d-flex justify-content-end mt-2 border-bottom pb-2" v-if="$parent.region.id">
      <button class="btn btn-primary p-1" @click="() => {showModal = true}">
        <icon-add-to-folder :fill="'#fff'" />
      </button>
      <button @click.prevent="deleteFromGroup()" v-if="onDeleteOrders.length > 0" class="btn btn-info ml-1 p-1">
        <icon-delete-from-folder :fill="'#fff'" />
      </button>
    </div>
    <base-modal title="Создать поток" modal-class="modal-lg" v-model="showModal" v-if="$parent.region.id">
      <msp-forsage-group-form :region="$parent.region.id" v-model="group" />
    </base-modal>
    <template v-if="mspForsageGroups && mspForsageGroups.length">
      <msp-forsage-group-item v-for="(group) in mspForsageGroups" :key="group.id" :group="group">
        <template v-slot:title>
          {{ group.title }}
        </template>
        <template v-slot:status>
          Статус: <span :class="'msp-status-' + group.status">&laquo;{{ group.status_ru }}&raquo;</span>
        </template>
        <template v-slot:date>
          Присвоен: {{ group.modified_at | formatDate('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:range>
          {{ group.start_date | formatDate('DD.MM.YYYY') }} ~ {{ group.end_date | formatDate('DD.MM.YYYY') }}
        </template>
        <template v-slot:companies>
          {{ group.companies.length }}
        </template>
      </msp-forsage-group-item>
    </template>
    <h2 class="mt-2" v-else>Сформируйте новый поток</h2>
  </div>
</template>
<script>

import BaseModal from "@/components/modal/BaseModal.vue";
import IconAddToFolder from "@/components/icons/IconAddToFolder.vue";
import MspForsageGroupForm from "@/views/profile/mspForsageOperator/components/MspForsageGroupForm.vue";
import {mapGetters} from "vuex";
import MspForsageGroupItem from "@/views/profile/mspForsageOperator/components/MspForsageGroupItem.vue";
import {eventBus} from "@/main";
import IconDeleteFromFolder from "@/components/icons/IconDeleteFromFolder.vue";
import ApiService from "../../../../services/api.service";
export default {
  name: "MspForsageGroups",
  components: {IconDeleteFromFolder, MspForsageGroupItem, MspForsageGroupForm, IconAddToFolder, BaseModal},
  mounted() {
    eventBus.$on('msp-groups:update', () => {
      this.showModal = false
    })
  },
  data() {
    return {
      showModal: false,
      group: {}
    }
  },
  methods: {
    async deleteFromGroup() {
      let promiseArr = this.onDeleteOrders.map(function (order) {
        return ApiService.put('/msp-forsage/order/' + order.id, {
          group: 'delete'
        })
      });
      Promise.all(promiseArr).then(async () => {
        eventBus.$emit('msp-groups:update')
      }).catch(function(err){
        console.log(err)
      })
    },
  },
  computed: {
    ...mapGetters(['mspForsageGroups']),
    onDeleteOrders() {
      let orders = []
      if(!this.mspForsageGroups) {
        return orders;
      }
      this.mspForsageGroups.forEach((group) => {
        group.companies.forEach((order) => {
          if(order.checked) {
            orders.push(order)
          }
        })
      })
      return orders;
    }
  },
}
</script>

<style scoped>
  .msp-status-approved {
    font-weight: bold;
    color: #349C6A;
  }
  .msp-status-rejected {
    font-weight: bold;
    color: #FFC65D;
  }
</style>