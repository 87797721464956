<template>
  <div class="float-right float-lg-none">
    <span class="form-save" style="text-decoration: underline; cursor: pointer;">Сохранить</span>&nbsp;
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.71 6.29L11.71 0.29C11.6178 0.200049 11.5092 0.128743 11.39 0.0799999C11.266 0.0296169 11.1338 0.00249808 11 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15V7C18.0008 6.86839 17.9755 6.73793 17.9258 6.61609C17.876 6.49426 17.8027 6.38344 17.71 6.29ZM6 2H10V4H6V2ZM12 16H6V13C6 12.7348 6.10536 12.4804 6.29289 12.2929C6.48043 12.1054 6.73478 12 7 12H11C11.2652 12 11.5196 12.1054 11.7071 12.2929C11.8946 12.4804 12 12.7348 12 13V16ZM16 15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H14V13C14 12.2044 13.6839 11.4413 13.1213 10.8787C12.5587 10.3161 11.7956 10 11 10H7C6.20435 10 5.44129 10.3161 4.87868 10.8787C4.31607 11.4413 4 12.2044 4 13V16H3C2.73478 16 2.48043 15.8946 2.29289 15.7071C2.10536 15.5196 2 15.2652 2 15V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H4V5C4 5.26522 4.10536 5.51957 4.29289 5.70711C4.48043 5.89464 4.73478 6 5 6H11C11.2652 6 11.5196 5.89464 11.7071 5.70711C11.8946 5.51957 12 5.26522 12 5V3.41L16 7.41V15Z" fill="#002E5E"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "BaseLinkSave"
}
</script>

<style scoped>

</style>