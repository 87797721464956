import { render, staticRenderFns } from "./MspForsageOrdersItemDateContainer.vue?vue&type=template&id=2cbfbe6e&scoped=true&"
import script from "./MspForsageOrdersItemDateContainer.vue?vue&type=script&lang=js&"
export * from "./MspForsageOrdersItemDateContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cbfbe6e",
  null
  
)

export default component.exports