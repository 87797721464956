import ApiService from "../../services/api.service";
export default {
    state: {
        specializations: []
    },
    actions: {
        async getSpecializations(ctx: any) {
            const res = await ApiService.get('specializations')
            ctx.commit('setSpecializations', res.data)
        }
    },
    mutations: {
        setSpecializations(state: any, data: any) {
            state.specializations = data
        }
    },
    getters: {
        specializations(state: any) {
            return state.specializations
        }
    }
}
