<template>
  <div class="section__lk-order-status">
    <div :class="'status status_' + status"></div>
  </div>
</template>

<script>
export default {
  name: "MspForsageOrdersItemStatus",
  props: {
    status: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>