<template>
  <router-link to="/lk/recommend" class="d-block mt-5 recommend-banners">
    <img v-if="state === 1" src="/pic/recommend/banner1.svg"/>
    <img v-else-if="state" src="/pic/recommend/banner2.svg"/>
  </router-link>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RecommendBanner",
  data() {
    return {
      state: null
    }
  },
  async mounted() {
    await this.getRecommend()
    this.state = this.recommend.state
  },
  methods: {
    ...mapActions(['getRecommend'])
  },
  computed: {
    ...mapGetters(['recommend'])
  }
}
</script>

<style lang="scss">
.recommend-banners {
  img {
    max-width: 100%;
  }
}
</style>