<template>
  <div class="admin-comment" v-if="regionteam.region_team_invites.length">
    <div class="separator"></div>
    <h3>Сотрудники компании</h3>
    <region-team-member-list-item />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import RegionTeamMemberListItem from "@/components/profile/regionteam/RegionTeamMemberListItem.vue";

export default {
  name: "RegionTeamMemberList",
  components: {RegionTeamMemberListItem},
  computed: {
    ...mapGetters(['regionteam'])
  }
}
</script>