<template>
  <div class="mb-5" v-if="selection">
    <modal-seminar-download
        title="Шаблон договора"
        text="Пожалуйста, заполните все обязательные поля в заявке, чтобы мы сформировали для Вас шаблон договора на обучение." />
    <h3>Вы успешно сдали следующие профильные входные тесты:</h3>
    <div v-for="(test, index) in selection.tests" :key="index">
      <ul v-if="index && test.result.isSuccess">
        <li>{{ test.test.title }}</li>
      </ul>
    </div>
    <div class="mt-2">
      <p>После заполнения всех полей заявки для Вас будет сформирован проект договора для прохождения обучения по указанным выше курсам. Пожалуйста, ознакомьтесь с условиями договора перед отправкой заявки.</p>
      <p>В данный момент не нужно подписывать договор – сотрудники Школы экспорта РЭЦ пришлют Вам на электронную почту окончательную версию договора.</p>
    </div>
    <form-separator>
      <h3>Представителем какой организации Вы являетесь?</h3>
      <sweet-select
          id="type"
          v-validate.immediate="'required'"
          :class="{invalid: errors.has('type')}"
          name="type"
          v-model="model.type"
          item-title="name"
          item-value="id"
          :options="[{id: 'cpe', name: 'Центр поддержки экспорта'},{id: 'vuz', name: 'Высшее учебное заведение'}]">
      </sweet-select>
    </form-separator>
    <form-separator v-if="model.type === 'vuz'">
      <base-input v-model="model.address1" name="address1" label="Юридический адрес"/>
      <base-input v-model="model.address2" name="address2" label="Почтовый адрес"/>
      <base-input v-model="model.phone" name="phone" label="Телефон"/>
      <base-input v-model="model.bank" name="bank" label="Банк"/>
      <base-input v-model="model.rs" name="rs" label="Расчетный счет"/>
      <base-input v-model="model.ks" name="ks" label="Корр. счет"/>
      <base-input v-model="model.bik"  name="bik" label="БИК"/>
      <div class="separator"></div>
    </form-separator>
    <div class="mt-3 d-flex align-items-center">
      <img class="mr-2" src="/pic/icon-doc.svg" />
      <a href="#" @click.prevent="getTemplate()" class="docs-needed">
        Скачайте проект договора
      </a>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import SweetSelect from "@/components/form/SweetSelect.vue";
import FormSeparator from "@/components/form/FormSeparator.vue";
import $ from "jquery";
import ModalSeminarDownload from "@/components/ModalSeminarDownload.vue";
import {TokenService} from "../../services/token.service";
import BaseInput from "@/components/form/BaseInput.vue";
import vmMixin from "@/mixins/vmMixin";

export default {
  name: "TutorEventOrder",
  components: {BaseInput, ModalSeminarDownload, FormSeparator, SweetSelect},
  mixins: [vmMixin],
  data() {
    return {
      selection: null
    }
  },
  async mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    this.selection = (await ApiService.get('acceleratorselection')).data
  },
  methods: {
    async getTemplate() {
      if(this.errors.items.length) {
        $('#seminar-download-doc').modal('show');
      } else {
        await this.$parent.draft()
        window.open(
            ApiService.baseURL()
            + 'api/documents/'
            + this.$route.params.id
            + '/contract?access_token='
            + TokenService.getAccessToken(),
            '_blank')
      }
    }
  }
}
</script>

<style scoped>

</style>