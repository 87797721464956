import ApiService from "../../services/api.service";
export default {
    state: {
        acceleratorMembers: []
    },
    actions: {
        async getAcceleratorMembers(ctx: any, filters: any) {
            const res = await ApiService.get('companymembers', {
                params: filters
            })
            ctx.commit('setAcceleratorMembers', res.data)
        },
        async addAcceleratorMember(ctx: any, data: any) {
            return await ApiService.postJson('users/members', data)
        },
        async deleteAcceleratorMember(ctx: any, data: any) {
            if(confirm('Вы уверены, что хотите удалить сотрудника?')) {
                return await ApiService.delete('companymembers/' + data.id)
            }
        }
    },
    mutations: {
        setAcceleratorMembers(state: any, data: any) {
            state.acceleratorMembers = data
        }
    },
    getters: {
        acceleratorMembers(state: any) {
            return state.acceleratorMembers
        }
    }
}
