<template>
  <div>
    <base-modal v-model="showModal"
                id="msp-forsage-folder"
                :title="`Выберите папку или создайте новую`"
                modal-class="modal-lg"
                :icon="false">
      <msp-forsage-orders-form v-if="$parent.region" :region="$parent.region" />
    </base-modal>
    <div class="d-flex justify-content-end mt-2 border-bottom pb-2" :style="showButtonStyle">
      <button class="btn btn-primary p-1" @click="() => {showModal = true}">
        <icon-add-to-folder :fill="'#fff'" />
      </button>
    </div>
    <h2 class="orders-folders-separator">Папки</h2>
    <msp-forsage-folders-item :key="'folder-default'" :can-edit="false" v-model="defaultFolder.checked" :folder="defaultFolder"/>
    <msp-forsage-folders-item v-for="folder in mspForsageOrders['folders']" :key="'folder-' + folder.id" :folder="folder"/>
    <h2 class="orders-folders-separator mt-2">Заявки компаний</h2>
    <msp-forsage-orders-item v-for="order in ordersOutsideGroups" v-model="order.checked" :key="'order-' + order.id" :order="order" />
  </div>
</template>
<script>
import mspForsageOperatorMixin from "@/mixins/mspForsageOperatorMixin";
import {mapActions, mapGetters} from "vuex";
import MspForsageOrdersItem from "@/views/profile/mspForsageOperator/components/MspForsageOrdersItem.vue";
import BaseModal from "@/components/modal/BaseModal.vue";
import MspForsageOrdersForm from "@/views/profile/mspForsageOperator/components/MspForsageOrdersForm.vue";
import {eventBus} from "@/main";
import MspForsageFoldersItem from "@/views/profile/mspForsageOperator/components/MspForsageFoldersItem.vue";
import IconAddToFolder from "@/components/icons/IconAddToFolder.vue";

export default {
  name: "MspForsageOrders",
  components: {IconAddToFolder, MspForsageFoldersItem, MspForsageOrdersForm, BaseModal, MspForsageOrdersItem},
  mixins: [mspForsageOperatorMixin],
  data() {
    return {
      showModal: false,
      defaultFolder: {
        id: null,
        name: 'Компании в потоке',
        orders: []
      }
    }
  },
  mounted() {
    this.defaultFolder.orders = this.ordersInsideGroups
    eventBus.$on('folder:changed', () => {
      this.showModal = false
      this.getMspForsageOrders({
        program: this.currentProgram,
        region: this.$parent.region.id
      })
    })
  },
  computed: {
    ...mapGetters(['mspForsageOrders']),
    checkedOrders() {
      return this.mspForsageOrders?.orders?.filter(order => order.checked)
    },
    showButtonStyle() {
      return {
        visibility: this.checkedOrders?.length > 0 ? 'visible' : 'hidden'
      }
    },
    ordersInsideGroups() {
      if(!this.mspForsageOrders.orders) {
        return [];
      }
      return this.mspForsageOrders.orders.filter(order => order.has_group)
    },
    ordersOutsideGroups() {
      if(!this.mspForsageOrders.orders) {
        return [];
      }
      return this.mspForsageOrders.orders.filter(order => !order.has_group)
    }
  },
  methods: {
   ...mapActions(['getMspForsageOrders'])
  },
  watch: {
    mspForsageOrders: {
      handler(val) {
        if(val && val.orders) {
          this.defaultFolder.orders = this.ordersInsideGroups
        } else {
          this.defaultFolder.orders = []
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.orders-folders-separator {
  color: #1C75BC;
  font-size: 2.8rem;
  border-bottom: 2px solid #1C75BC;
  @media (max-width: 540px) {
    font-size: 28px;
  }
  margin-bottom: 20px;
}
</style>