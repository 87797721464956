<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Настройки / Данные о компании</div>
    <company-settings v-if="model" :show-label="false" v-model="model" />
    <div class="separator"></div>
    <buttons-footer type="company" :next="'/lk/subscribes'" :prev="'/lk/settings'" />
  </div>
</template>

<script>
import CompanySettings from "@/components/CompanySettings.vue";
import ButtonsFooter from "@/components/profile/ButtonsFooter.vue";
import {mapActions, mapGetters} from "vuex";
import {eventBus} from "@/main";

export default {
  name: "ProfileCompanySettings",
  components: {ButtonsFooter, CompanySettings},
  data() {
    return {
      model: null
    }
  },
  async mounted() {
    this.model = this.organization
    eventBus.$on('company.draft', async () => {
      await this.updateOrganization(this.model)
      this.model = this.organization
    })
  },
  methods: {
    ...mapActions(['updateOrganization'])
  },
  computed: {
    ...mapGetters(['organization'])
  }
}
</script>

<style lang="scss" scoped>
.upload-link {
  color: #002E5E;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
  text-decoration: underline;
  margin-top: 32px;
  display: block;
}
</style>