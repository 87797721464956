<template>
  <div>
    <h3 v-if="showHeader">Образование</h3>
    <base-label label="Образование">
      <sweet-select v-validate.immediate="'required'" :class="{invalid: errors.has('education')}" name="education" id="education" :options="educations" v-model="user.education_type" />
    </base-label>
    <base-input name="diploma_number" label="Номер диплома" v-model="user.diploma_number" />
    <base-input v-model="user.institute" name="institute" label="Наименование учебного заведения"/>
    <base-label label="Специализация">
      <sweet-select v-validate.immediate="'required'" :class="{invalid: errors.has('specialization')}" name="specialization" id="specialization" v-if="specializations.length" :options="specializations" item-title="name" item-value="id" v-model="user.specialization" />
    </base-label>
    <base-label label="Квалификация">
      <sweet-select v-validate.immediate="'required'" :class="{invalid: errors.has('qualification')}" name="qualification" id="qualification" :options="[{name: 'Бакалавр'},{name: 'Специалист'},{name: 'Магистр'}]" item-title="name" item-value="name" v-model="user.diploma_type" />
    </base-label>
    <base-label label="Год окончания учебного заведения<br/><small>(выберите из списка)</small>">
      <sweet-select v-validate.immediate="'required'" :class="{invalid: errors.has('years')}" name="years" id="year-finish" v-if="years" :options="years" item-title="name" item-value="name" v-model="user.education_date" />
    </base-label>
    <base-label label="Дата выдачи диплома">
      <sweet-date-picker :class="{'invalid': errors.has('diploma_date')}"  v-validate.immediate="'required'" name="diploma_date" :range="false" type="date" v-model="user.diploma_date" />
    </base-label>
  </div>
</template>

<script>


import SweetSelect from "@/components/form/SweetSelect.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import SweetDatePicker from "@/components/form/SweetDatePicker.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EducationSettings",
  components: {SweetDatePicker, BaseInput, BaseLabel, SweetSelect},
  inject: ['$validator'],
  props: {
    value: {
      type: Object,
      required: true
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      user: this.value,
      educations: [
        {id: 'Неоконченное высшее', name: 'Неоконченное высшее'},
        {id: 'Высшее', name: 'Высшее'},
        {id: 'Среднее профессиональное', name: 'Среднее профессиональное'},
      ],
      years: null
    }
  },
  async mounted() {
     if(!this.specializations.length) {
       await this.getSpecializations()
     }
    this.years = this.getEduYears()
  },
  methods: {
    getEduYears: function () {
      let i,
          years = [],
          currentYear = (new Date()).getFullYear(),
          eduYear = currentYear - 85;

      for( i = currentYear; i > eduYear; i--) {
        years[currentYear - i] = {
          name: i,
          id: i
        };
      }
      return years;
    },
    ...mapActions(['getSpecializations'])
  },
  computed: {
    ...mapGetters(['specializations'])
  },
  watch: {
    user: {
      handler: function(val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>