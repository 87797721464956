// @ts-nocheck
export default {
    data() {
        return {
            model: {}
        }
    },
    inject: ['$validator'],
    props: {
        value: {
            type: [Object, Boolean, String, Array]
        }
    },
    mounted() {
        this.model = this.value
    },
    watch: {
        model: {
            handler: async function(val) {
                this.$emit('input', val)
            },
            deep: true
        },
        value: {
            handler: async function(val) {
                this.model = val
            }
        }
    }
}