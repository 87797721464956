<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Мои документы / Онлайн курсы</div>
    <div class="section__lk-subtitle">Список документов</div>
    <div v-if="!certs.length" class="section__lk-empty">пока у вас нет ни одного документа</div>
    <template v-else>
      <a v-for="cert in certs" :key="cert.id" class="section__lk-course" target="_blank" :href="cert.file.file">
        <div class="section__lk-course-icon"><span></span></div>
        <div class="section__lk-course-title">
          <div class="label">{{ getCertType(cert.type) }}</div>
          {{ cert.course.title }}
        </div>
        <div class="section__lk-course-date">
          <div class="label">Дата выдачи:</div>
          {{ cert.date | formatDate('DD.MM.YYYY') }}
        </div>
        <span class="section__lk-course-link container-shadow"></span>
      </a>
    </template>
  </div>
</template>

<script>
import ApiService from "../../../../services/api.service";

export default {
  name: "OnlineDocs",
  data() {
    return {
      certs: []
    }
  },
  async mounted() {
    this.certs = (await ApiService.get('programparticipant/documents')).data
  },
  methods: {
    getCertType(type) {
      switch (type) {
        case 'certificate':
          type = 'Удостоверение';
          break;
        case 'education':
          type = 'Сертификат';
          break;
        default:
          type = 'Справка';
          break;
      }
      return type;
    }
  }
}
</script>

<style scoped>

</style>