import ApiService from "../../services/api.service";
export default {
    state: {
        organization: {}
    },
    actions: {
        async getOrganization(ctx: any) {
            const res = await ApiService.getMeOrganization()
            ctx.commit('setOrganization', res.data)
        },
        async updateOrganization(ctx: any, data: any) {
            const res = await ApiService.put('userorganization', data)
            if(res.status === 200) {
                ctx.commit('setOrganization', res.data)
            }
            return res.data
        },
    },
    mutations: {
        setOrganization(state: any, notes: any) {
            state.organization = notes
        }
    },
    getters: {
        organization(state: any) {
            return state.organization
        }
    }
}
