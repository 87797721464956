<template>
  <form @submit.prevent="validate()">
    <div class="mt-5">
      <base-input name="Название" label="Название папки" v-model="model.name" />
    </div>
    <div class="d-flex justify-content-end">
      <button :disabled="!model" class="mt-3 btn btn-primary">Сохранить</button>
    </div>
  </form>
</template>

<script>
import vmMixin from "@/mixins/vmMixin";
import validateMixin from "@/mixins/validateMixin";
import ApiService from "../../../../../services/api.service";
import {eventBus} from "@/main";
import BaseInput from "@/components/form/BaseInput.vue";

export default {
  name: "MspForsageFolderForm",
  components: {BaseInput},
  mixins: [vmMixin, validateMixin],
  mounted() {
    this.$on('on-submit', async () => {
      if(this.model) {
        await ApiService.put('msp-forsage/folders/' + this.model.id, {
          name: this.model.name
        })
        eventBus.$emit('folder:changed')
      }
    })
  }
}
</script>

<style>

</style>