import ApiService from "../../services/api.service";
export default {
    state: {
        acceleratorProfile: {
            countries: [
                {id: null, country_name_ru: 'Поставок не было'}
            ],
            aer_countries: [
                {id: null, country_name_ru: 'Поставок не было'}
            ]
        }
    },
    actions: {
        async getAcceleratorProfile(ctx: any, filters: any) {
            const res = await ApiService.get('acceleratorprofiles', {
                params: filters
            })
            if(res.status === 200) {
                ctx.commit('setAcceleratorProfile', res.data)
            }
            if(res.status === 204) {
                ctx.commit('setAcceleratorProfile', {})
            }
        },
        async updateAcceleratorProfile(ctx: any, data: any) {
            const res = await ApiService.postJson('acceleratorprofiles', data)
            if(res.status === 201) {
                ctx.commit('setAcceleratorProfile', res.data)
            }
        },
    },
    mutations: {
        setAcceleratorProfile(state: any, date: any) {
            state.acceleratorProfile = date
        }
    },
    getters: {
        acceleratorProfile(state: any) {
            return state.acceleratorProfile
        }
    }
}
