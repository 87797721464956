<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Мои заявки / Семинары</div>
    <div class="section__lk-subtitle">Список заявок</div>
    <template v-if="orders">
      <div v-if="!orders.length" class="section__lk-empty">у вас нет активных заявок</div>
      <div v-else class="section__lk-orders">
        <router-link v-for="order in orders" :key="order.id" :to="'/events/' + order.event.id" class="section__lk-order">
          <div class="section__lk-order-title">
            <div class="label">Заявка</div>
            {{ order.event.title }}
          </div>
          <div class="section__lk-order-date-and-status">
            <div class="section__lk-order-date">
              <div class="label">Дата подачи</div>
              {{ order.created_at | formatDate('DD.MM.YYYY') }}
            </div>
            <div class="section__lk-order-status"><div :class="'status status_' + order.status"></div></div>
          </div>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import ApiService from "../../../../services/api.service";

export default {
  name: "OfflineRequests.vue",
  data() {
    return {
      orders: null
    }
  },
  async mounted() {
    this.orders = (await ApiService.get('eventparticipants')).data
    if(!this.orders) {
      this.orders = []
    }
  }
}
</script>

<style scoped>

</style>