<template>
  <div class="orders-item">
    <div class="section__lk-content-item">
      <base-modal v-model="showModal"
                  :id="'msp-forsage-order-' + order.id"
                  :title="orderName"
                  modal-class="modal-xl"
                  :icon="false">
        <msp-forsage-order-detail v-if="data" :order="data"/>
      </base-modal>
      <msp-forsage-orders-item-name @click.native.self="show()">
        <template v-slot:icon>
          <base-checkbox :disabled="!canDelete" v-if="showChecked" v-model="model" />
        </template>
        <template v-slot:name>
          <span @click="show()">{{ order.name }}</span>
        </template>
        <template v-slot:author>
          <span @click="show()">{{ order.username }}</span>
        </template>
      </msp-forsage-orders-item-name>
      <msp-forsage-orders-item-date-container>
        <msp-forsage-orders-item-date @click.native="show()">
          <template v-slot:date>
            {{ order.date | formatDate('DD.MM.YYYY') }}
          </template>
          <template v-slot:revenue>
            {{ order.revenue }}
          </template>
        </msp-forsage-orders-item-date>
        <msp-forsage-orders-item-status :status="order.status" @click.native="show()" />
        <div v-if="order.has_group && !showDetail && canDelete" class="align-self-center">
          <icon-delete @click.native="deleteFromGroup()" fill="#ED1B2F" />
        </div>
      </msp-forsage-orders-item-date-container>
    </div>
  </div>
</template>

<script>
import MspForsageOrdersItemName from "@/views/profile/mspForsageOperator/components/MspForsageOrdersItemName.vue";
import MspForsageOrdersItemDate from "@/views/profile/mspForsageOperator/components/MspForsageOrdersItemDate.vue";
import MspForsageOrdersItemStatus from "@/views/profile/mspForsageOperator/components/MspForsageOrdersItemStatus.vue";
import BaseCheckbox from "@/components/form/BaseCheckbox.vue";
import BaseModal from "@/components/modal/BaseModal.vue";
import modalMixin from "@/mixins/modalMixin";
import ApiService from "../../../../../services/api.service";
import MspForsageOrderDetail from "@/views/profile/mspForsageOperator/components/MspForsageOrderDetail.vue";
import MspForsageOrdersItemDateContainer
  from "@/views/profile/mspForsageOperator/components/MspForsageOrdersItemDateContainer.vue";
import orderPropMixin from "@/mixins/orderPropMixin";
import {eventBus} from "@/main";
import {mapActions} from "vuex";
import vmMixin from "@/mixins/vmMixin";
import IconDelete from "@/components/icons/IconDelete.vue";

export default {
  name: "MspForsageOrdersItem",
  components: {
    IconDelete,
    MspForsageOrdersItemDateContainer,
    MspForsageOrderDetail,
    BaseModal, BaseCheckbox, MspForsageOrdersItemStatus, MspForsageOrdersItemDate, MspForsageOrdersItemName},
  mixins: [orderPropMixin, modalMixin, vmMixin],
  data() {
    return {
      data: null
    }
  },
  props: {
    showDetail: {
      type: Boolean,
      default() {
        return true
      }
    },
    canDelete: {
      type: Boolean,
      default() {
        return true
      }
    },
    showChecked: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  mounted() {
    eventBus.$on('folder:changed', () => {
      this.showModal = false
    })
    eventBus.$on('order:update', (order) => {
      this.data = order
    })
  },
  methods: {
    async deleteFromGroup() {
      await ApiService.put('/msp-forsage/order/' + this.order.id, {
        group: 'delete'
      })
      eventBus.$emit('msp-groups:update')
    },
    async show() {
      if(this.showDetail) {
        this.showModal = true
      }
    },
    ...mapActions(['getMspForsageOrders'])
  },
  computed: {
    orderName() {
      return this.data ? `Заявка на &laquo;` + this.data.enroll_program.name + '&raquo;' : '';
    }
  },
  watch: {
    showModal: {
      handler: async function(val) {
        if(val) {
          this.data = (await ApiService.get('msp-forsage/order/' + this.order.id)).data
        }
      }
    }
  }
}
</script>
<style lang="scss">
.orders-item {
  position: relative;
  .checkbox-wrapper {
    margin-top: 0!important;
    position: relative;
    bottom: -5px;
    @media (max-width: 540px) {
      top: -15px;
    }
  }
}
</style>