<template>
  <div class="rate-wrapper">
    <form @submit.prevent="submit()">
      <div class="d-flex justify-content-between text">
        <div>
          Оцените по шкале от <b>1 до 5</b>.<br/>
          Это поможет другим пользователям определиться с выбором.<br/>
          Для получения сертификата необходимо оставить отзыв:
        </div>
        <div class="rate-result">
          {{rate}}<sub>/5</sub>
        </div>
      </div>
      <div class="rate">
        <template v-for="i in [1,2,3,4,5, 6]">
          <div class="rate__index" :key="i" :class="{current: rate === i, 'more': rate >= i, first: i === 1, last: i === 6}">
            <template v-if="i !== 6">
              <div class="rate__index-number" @click="setRate(i)">
                <div>{{ i }}</div>
                <div class="rate__index-star"></div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <div class="text-container">
        <div class="header__user-authorized">
          <span :style="{backgroundImage: 'url(' + (user.avatar ? user.avatar['500x'] : '/pic/user-pic.jpeg') + ')'}"></span>
        </div>
        <base-textarea :label="false" placeholder="Напишите своё мнение" name="text" v-model="text"></base-textarea>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btn-with-icon mt-24">
          Отправить
          <img class="icon left" src="/pic/icon-chevron-right.svg" />
        </button>
      </div>
      <form-error v-if="error.message" :error="error" />
    </form>
  </div>
</template>

<script>
import {TokenService} from "../../services/token.service";
import BaseTextarea from "@/components/form/BaseTextarea.vue";
import ApiService from "../../services/api.service";
import FormError from "@/components/FormError.vue";
import {eventBus} from "@/main";

export default {
  name: "Review",
  components: {FormError, BaseTextarea},
  data() {
    return {
      rate: this.value,
      user: TokenService.getUser(),
      text: null,
      error: {}
    }
  },
  props: {
    value: {
      type: Number,
      default() {
        return 1
      }
    },
    event: {
      type: [Number,String, null],
      default() {
        return null
      }
    },
    test: {
      type: [Number,String, null],
      default() {
        return null
      }
    }
  },
  methods: {
    setRate(val) {
      this.rate = val
      this.$emit('input', val)
    },
    async submit() {
      const valid = await this.$validator.validateAll()
      if(valid) {
        let path = 'tests/' + this.test +  '/reviews';
        if(this.event) {
          path = 'events/' + this.event +  '/reviews';
        }

        const res = await ApiService.post(path, {
          rate: this.rate,
          text: this.text
        });

        if(res.status > 299) {
          this.error = {
            message: res.data.errors
          }
          return;
        } else if(this.event) {
          this.error = {}
          await this.$router.push('/lk').catch(() => {})
          return;
        }
        eventBus.$emit('review:send')
      }
    }
  }
}
</script>

<style lang="scss">
.rate-wrapper {
  font-size: 18px;
  font-style: normal;
  line-height: 32px;
  padding-top: 38px;
  .rate {
    display: flex;
    justify-content: space-around;
    text-align: right;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    line-height: 24px;
    &__index {
      color: #828282;
      border-bottom: 2px solid #828282;
      position: relative;
      display: flex;
      justify-content: flex-end;
      &-number {
        padding-bottom: 19px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
      &-star {
        height: 16px;
        width: 2px;
        background: #828282;
        position: absolute;
        bottom: -9px;
        right: 3px;
      }
      &.more {
        color: #1C75BC;
        border-bottom: 2px solid #1C75BC;
        .rate__index-star {
          background: #1C75BC;
        }
      }
      &.current {
        .rate__index-star {
          background: url("/pic/star.svg") #002E5E center center no-repeat;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          right: -12px;
          z-index: 1;
          bottom: -16px;
        }
      }
      flex-grow: 1;
      &.first, &.last {
        flex-grow: 0.1;
      }
    }
    &-result {
      color: #002E5E;
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 72px;
      text-transform: uppercase;
    }
  }
  .text {
    padding-bottom: 72px;
  }
  .text-container {
    padding-top: 81px;
    display: flex;
    .header__user-authorized {
      width: 9.6rem!important;
      height: 9.6rem!important;
      margin-right: 2.4rem;
      span {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 37%;
        left: 0;
        width: 9.6rem!important;
        height: 9.6rem!important;
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>