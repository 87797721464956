<template>
  <span>
    <div class="modal fade" id="add-question" tabindex="-1" role="dialog" aria-labelledby="add-question" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <form @submit.prevent="submit">
        <div class="modal-content">
        <div class="modal-header">
          <div class="title-with-icon">
            <div class="title-with-icon__icon title-with-icon__icon_question"></div>
            <div class="title-with-icon__title">Задать вопрос</div>
          </div>
          <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-item">
            <label for="email">Ваш e-mail</label>
            <input type="email" v-model="email" class="form-control" id="email" required placeholder="Введите ваш e-email" />
          </div>
          <div class="form-item">
            <label for="description">Ваш вопрос</label>
            <textarea v-model="question" class="form-control" required id="description"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="link-target"><span>отправить</span></button>
        </div>
      </div>
      </form>
      </div>
    </div>
  </span>
</template>

<script>
import $ from 'jquery';
import 'bootstrap'
window.jQuery = $
import ApiService from "../../services/api.service";
export default {
  name: "Modal",
  data() {
    return {
      loading: false,
      email: null,
      question: null
    }
  },
  methods: {
    submit() {
      this.loading = true
      ApiService.post('questions', {
        question: this.question,
        email: this.email
      }).then(() => {
        this.question = null
        this.email = null
        $('#add-question').modal('hide')
        this.loading = false
      }).catch(() => {
        this.question = null
        this.email = null
        $('#add-question').modal('hide')
        this.loading = false
      })
    }
  }
 }
</script>

<style scoped>

</style>