<template>
  <div class="mt-5">
    <form @submit.prevent="validate()" v-if="organization">
      <company-settings v-model="model" :show-post="false" />
      <div class="separator"></div>
      <accelerator-footer />
    </form>
  </div>
</template>

<script>
import CompanySettings from "@/components/CompanySettings.vue";
import {mapActions, mapGetters} from "vuex";
import AcceleratorFooter from "@/components/profile/accelerator/AcceleratorFooter.vue";
import validateMixin from "@/mixins/validateMixin";

export default {
  name: "AcceleratorCompany",
  components: {AcceleratorFooter, CompanySettings},
  mixins: [validateMixin],
  data() {
    return {
      model: {}
    }
  },
  async mounted() {
    this.$on('on-submit', async () => {
      await this.updateOrganization(this.model)
      this.$toastr.s('', "Черновик успешно сохранен");
    })
  },
  methods: {
    ...mapActions(['updateOrganization'])
  },
  computed: {
    ...mapGetters(['organization']),
  }
}
</script>

<style scoped>

</style>