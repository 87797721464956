<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0981 12.6338L13 11.4228V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V12C11 12.1756 11.0461 12.3481 11.1339 12.5001C11.2217 12.6522 11.3479 12.7784 11.5 12.8662L14.0981 14.3662C14.2119 14.432 14.3375 14.4746 14.4678 14.4918C14.598 14.509 14.7304 14.5004 14.8573 14.4664C14.9842 14.4324 15.1032 14.3737 15.2074 14.2938C15.3117 14.2138 15.3991 14.114 15.4648 14.0003C15.5305 13.8865 15.5731 13.7608 15.5902 13.6306C15.6073 13.5003 15.5986 13.368 15.5646 13.2411C15.5305 13.1142 15.4718 12.9952 15.3918 12.891C15.3117 12.7868 15.212 12.6994 15.0981 12.6338ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34874 20.9425 6.80691 19.0678 4.93219C17.1931 3.05746 14.6513 2.00295 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C19.9976 14.121 19.1539 16.1544 17.6542 17.6542C16.1544 19.1539 14.121 19.9976 12 20Z" fill="#1C75BC"/>
  </svg>
</template>

<script>
export default {
  name: "IconClock"
}
</script>

<style scoped>

</style>