// @ts-nocheck
export default {
    methods: {
        validate() {
            this.$validator.validateAll().then(isValid => {
                if(isValid) {
                    this.$emit('on-submit');
                }
            });
        },
        draft() {
            this.$emit('on-draft');
        }
    }
}