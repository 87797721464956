<template>
  <div class="form-item form-item_groupped ">
    <label for="email"><span>E-mail</span></label>
    <div>
      <div class="form-item_editable mb-32">
        <div class="position-relative">
          <input v-model="email" class="form-control mb-32" :disabled="true" placeholder="Введите значение"/>
          <span v-if="!showEmailChanged" @click="showEmailChanged = !showEmailChanged" class="content_red edit">Изменить email</span>
        </div>
        <div v-if="showEmailChanged">
          <form @submit.prevent="changeEmail">
            <input v-validate.immediate="'required|email'" :class="{invalid: errors.has('email')}" name="email" type="email" v-model="newEmail" class="form-control mb-32" id="form-email" placeholder="Введите e-mail"  required />
            <button  class="btn btn-primary">Изменить e-mail</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../services/api.service";

export default {
  name: "ChangeEmail",
  props: {
    email: {
      type: String,
      required: true
    }
  },
  data() {
   return {
     showEmailChanged: false,
     newEmail: null
   }
  },
  methods: {
    async changeEmail() {
      let result = await this.$validator.validateAll()
      if(result) {
        let res = await ApiService.post('users/change-email', {email: this.newEmail})
        if(res.status === 200) {
          this.$toastr.s('', "На старый почтовый ящик придет письмо с подтверждением");
        } else {
          this.$toastr.e('', "Невозможно сменить на этот адрес");
        }
      }
    }
  }
}
</script>

<style scoped>

</style>