<template>
  <div class="section__lk-content-item-right section__lk-content-item-data border-0 align-items-start justify-content-between">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MspForsageOrdersItemDateContainer"
}
</script>

<style scoped>

</style>