<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.8135 5.86016L6.9535 8.72683L5.8535 7.62683C5.79373 7.55704 5.72019 7.50036 5.63748 7.46035C5.55477 7.42033 5.46468 7.39784 5.37287 7.3943C5.28106 7.39075 5.1895 7.40622 5.10395 7.43974C5.0184 7.47325 4.9407 7.52409 4.87573 7.58906C4.81076 7.65403 4.75992 7.73173 4.72641 7.81728C4.69289 7.90283 4.67742 7.99439 4.68097 8.0862C4.68451 8.17801 4.707 8.2681 4.74702 8.35081C4.78703 8.43352 4.84371 8.50707 4.9135 8.56683L6.48017 10.1402C6.54246 10.202 6.61634 10.2508 6.69756 10.284C6.77879 10.3172 6.86576 10.334 6.9535 10.3335C7.12839 10.3328 7.29599 10.2633 7.42017 10.1402L10.7535 6.80683C10.816 6.74485 10.8656 6.67112 10.8994 6.58988C10.9333 6.50864 10.9507 6.4215 10.9507 6.3335C10.9507 6.24549 10.9333 6.15835 10.8994 6.07711C10.8656 5.99587 10.816 5.92214 10.7535 5.86016C10.6286 5.736 10.4596 5.6663 10.2835 5.6663C10.1074 5.6663 9.93841 5.736 9.8135 5.86016ZM8.00017 1.3335C6.68162 1.3335 5.39269 1.72449 4.29636 2.45703C3.20004 3.18957 2.34555 4.23077 1.84097 5.44894C1.33638 6.66711 1.20436 8.00756 1.4616 9.30077C1.71883 10.594 2.35377 11.7819 3.28612 12.7142C4.21847 13.6466 5.40636 14.2815 6.69956 14.5387C7.99277 14.796 9.33322 14.6639 10.5514 14.1594C11.7696 13.6548 12.8108 12.8003 13.5433 11.704C14.2758 10.6076 14.6668 9.31871 14.6668 8.00016C14.6668 7.12468 14.4944 6.25778 14.1594 5.44894C13.8243 4.6401 13.3333 3.90517 12.7142 3.28612C12.0952 2.66706 11.3602 2.176 10.5514 1.84097C9.74255 1.50593 8.87565 1.3335 8.00017 1.3335ZM8.00017 13.3335C6.94533 13.3335 5.91419 13.0207 5.03712 12.4347C4.16006 11.8486 3.47648 11.0157 3.07281 10.0411C2.66914 9.0666 2.56352 7.99425 2.76931 6.95968C2.9751 5.92512 3.48305 4.97481 4.22893 4.22893C4.97481 3.48305 5.92512 2.9751 6.95968 2.76931C7.99425 2.56352 9.06661 2.66914 10.0411 3.07281C11.0157 3.47647 11.8486 4.16006 12.4347 5.03712C13.0207 5.91418 13.3335 6.94533 13.3335 8.00016C13.3335 9.41465 12.7716 10.7712 11.7714 11.7714C10.7712 12.7716 9.41465 13.3335 8.00017 13.3335Z" fill="white"/>
  </svg>
</template>

<script>

export default {
  name: "IconReject"
}
</script>

<style scoped>

</style>