<template>
  <div>
    <div class="separator"></div>
    <div class="pt-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormSeparator",
  inject: ['$validator'],
}
</script>

<style scoped>

</style>