<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Настройки / Рассылки и подписки</div>
    <form v-if="user">
      <div class="form-item form-item_groupped form-item_groupped_required">
        <div class="checkbox-wrapper">
          <div class="checkbox">
            <input type="checkbox" v-model="user.is_email_subscribe" id="is_email_subscribe"/>
            <label for="is_email_subscribe" class="m-0">Получать информационные письма и актуальные новости на email-адрес</label>
          </div>
        </div>
      </div>
      <div class="form-item form-item_groupped form-item_groupped_required">
        <div class="checkbox-wrapper">
          <div class="checkbox">
            <input type="checkbox" v-model="user.is_sms_subscribe" id="is_sms_subscribe"/>
            <label for="is_sms_subscribe" class="m-0">Получать важные сообщения на номер мобильного телефона</label>
          </div>
        </div>
      </div>
      <div class="separator"></div>
      <buttons-footer type="subscribe" :next="'/lk/settings'" :prev="'/lk/company'" />
    </form>
  </div>
</template>

<script>
import {TokenService} from "../../../../services/token.service";
import ButtonsFooter from "@/components/profile/ButtonsFooter.vue";
import ApiService from "../../../../services/api.service";
import {eventBus} from "@/main";

export default {
  name: "SubscribeSettings",
  components: {ButtonsFooter},
  mounted() {
    eventBus.$on('subscribe.draft', () => {
      ApiService.put('user', {
        is_email_subscribe: this.user.is_email_subscribe,
        is_sms_subscribe: this.user.is_sms_subscribe,
      }).then((res) => {
        if(res.status < 300) {
          TokenService.setUser(res.data);
          this.$toastr.s('', "Изменения успешно сохранены");
        }
      })
    })
  },
  data() {
    return {
      user: JSON.parse(TokenService.getUser()),
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-link {
  color: #002E5E;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
  text-decoration: underline;
  margin-top: 32px;
  display: block;
}
</style>