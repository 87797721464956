// @ts-nocheck
export default {
    computed: {
        lkPath() {
            if(this.$hasRole('ROLE_SUPER_ADMIN')) {
                return '/profile/settings/personal';
            }else if(this.$hasRoles(['ROLE_COMPANY'])) {
                return '/lk/accelerator';
            } else if(this.$hasRoles(['ROLE_MSP', 'ROLE_FORSAGE'])) {
                if(this.$getUser().msp_forsage_order && this.$getUser().msp_forsage_order.enroll_program && this.$getUser().msp_forsage_order.enroll_program.id === 1) {
                    return '/lk/forsage/company';
                } else {
                    return '/lk/msp/company';
                }
            } else if(this.$hasRole('ROLE_REGION_TEAM')) {
                return '/lk/region-team';
            } else if(this.$hasRole('ROLE_OPERATOR_MSP_MBA')) {
                return this.$route.query.program ? '/lk/msp-forsage/orders?program=' + this.$route.query.program : '/lk/msp-forsage/orders';
            } else {
                return '/lk';
            }
        }
    }
}