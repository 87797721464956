import Vue from "vue"
import Vuex from "vuex"
import notes from "./notes"
import recommend from "./recommend"
import organization from "./organization"
import countries from "./countries"
import acceleratorEnroll from "./acceleratorenroll"
import acceleratorMembers from "./companymember"
import acceleratorProfile from "./acceleratorprofile"
import acMenu from "./acmenu"
import specializations from "./specializations"
import enrollGroup from "./enrollGroup"
import regionteam from "./regionteam"
import mspForsageOrder from "./mspForsageOrder"
import mspMenu from "./mspmenu"
import mspForsageOperator from "./mspForsageOperator"
import mspForsageGroups from "./mspForsageGroups"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        recommend,
        notes,
        organization,
        countries,
        acceleratorEnroll,
        acceleratorMembers,
        acMenu,
        acceleratorProfile,
        specializations,
        enrollGroup,
        regionteam,
        mspForsageOrder,
        mspMenu,
        mspForsageOperator,
        mspForsageGroups,
    }
})