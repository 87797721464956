<template>
  <div class="big-search">
    <div class="section section__search">
      <head-menu />
      <div class="section__wrapper">
        <div class="title-with-icon mb-2">
          <div class="title-with-icon__icon title-with-icon__icon_search"></div>
          <div class="title-with-icon__title">Поиск по сайту</div>
        </div>
        <form class="section__search-form">
          <span class="section__search-form-clean"></span>
          <input v-model="query" type="text" class="section__search-text" placeholder="Свежая новость" />
          <button @click="search" class="link-target section__search-submit"><span>Искать</span></button>
        </form>
        <div class="section__search-data">
          <div v-if="false" class="section__search-popular">
            <div class="title-with-icon mb-2 mb-md-4">
              <div class="title-with-icon__icon title-with-icon__icon_searchplus"></div>
              <div class="title-with-icon__title">Популярные запросы</div>
            </div>
            <ul class="section__search-popular-list">
              <li><a href="#">Свежие новости</a></li>
              <li><a href="#">Акселерационные программы </a></li>
              <li><a href="#">Новости</a></li>
              <li><a href="#">Учебные пособия</a></li>
              <li><a href="#">Библиотека</a></li>
              <li><a href="#">Новинки</a></li>
              <li><a href="#">Дистанционные курсы и пособия</a></li>
              <li><a href="/acceleration#export">Экспортный форсаж</a></li>
              <li><a href="/education#export">Экспортные семинары</a></li>
              <li><a href="/highschool">Программы с ВУЗами</a></li>
              <li><a href="#">Плеханова</a></li>
              <li><a href="#">Программы Школы экспорта</a></li>
              <li><a href="#">Личный кабинет</a></li>
              <li><a href="#">Новинки</a></li>
              <li><a href="#">Экспортное наставничество</a></li>
            </ul>
          </div>
          <div class="section__search-results">
            <div v-for="(post, index) in news" class="section__search-item row" :key="index">
              <div class="col-auto section__search-item-image">
                <img src="/pic/icon-news-red.svg" />
              </div>
              <div class="col section__search-item-info">
                <div class="section__search-item-title"><a :href="`/news/${post.id}`">{{ post.title }}</a></div>
                <div class="section__search-item-path">{{ post.rubric }}</div>
                <div class="section__search-item-teaser">{{ post.short_text }}</div>
                <div class="section__search-item-address"><a :href="'https://exportedu.ru/news/' + post.id">https://exportedu.ru/news/{{post.id}}</a></div>
              </div>
              <div class="col-auto section__search-item-date">{{ post.date | formatDate('DD.MM.YYYY') }}</div>
            </div>
            <div v-if="!news.length">
              <div class="col section__search-item-info">
                <div class="section__search-item-title">Ничего не найдено</div>
              </div>
            </div>
            <div v-if="false" class="pager">
              <div class="pager-items">
                <a href="#" class="pager-prev"></a>
                <a href="#" class="pager-item">01</a>
                <a href="#" class="pager-item">02</a>
                <a href="#" class="pager-item pager-current">03</a>
                <a href="#" class="pager-item">...</a>
                <a href="#" class="pager-item">25</a>
                <a href="#" class="pager-next"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import HeadMenu from "@/components/HeadMenu";

export default {
  name: "Search",
  components: {HeadMenu},
  data() {
    return {
      query: null,
      news: []
    }
  },
  methods: {
    search() {
      ApiService.get('posts', {
        params: {
          limit: 10,
          offset: 0,
          order_by: 'date',
          order_direction: 'desc',
          search: this.query
        }
      }).then(res => {
        this.news = res.data.posts
        //this.$parent.init()
      })
    }
  }
}
</script>

<style scoped>

</style>