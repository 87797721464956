<template>
  <div class="form-item form-item_groupped">
    <label for="email"><span>Пароль</span></label>
    <div>
      <div class="form-item_editable mb-32">
        <div class="position-relative" v-if="!showChanged">
          <input value="*******" class="form-control mb-32" :disabled="true" placeholder="Введите значение"/>
          <span @click="showChanged = !showChanged" class="content_red edit">Изменить пароль</span>
        </div>
        <div v-else>
          <form @submit.prevent="changePassword">
            <div class="mb-32">
              <div>
                <input v-model="form.current_password" :class="{'invalid': errors.has('oldPassword')}" name="oldPassword" v-validate.immediate="'required|min:6'" type="password" ref="oldPassword" class="form-control" id="form-pass" placeholder="Введите старый пароль" required />
              </div>
            </div>
            <div class="mb-32">
              <div>
                <input v-model="form.new.first" :class="{'invalid': errors.has('password')}" name="password" v-validate.immediate="'required|min:6'" type="password" ref="password" class="form-control" id="form-pass-1" placeholder="Введите новый пароль" required />
              </div>
            </div>
            <div class="mb-32">
              <div>
                <input v-model="form.new.second" :class="{'invalid': errors.has('password_confirmation')}" name="password_confirmation" v-validate.immediate="'required|min:6|confirmed:password'" type="password" class="form-control" id="form-pass-2" placeholder="Повторите новый пароль" required/>
              </div>
            </div>

            <div class="container-grid__item grid-descr">
              <div class="form-description">Пароль должен содержать латинские буквы и цифры, а длина пароля — <strong class="content_700">не менее
                6 знаков</strong>.
              </div>
            </div>
            <button  class="btn btn-primary">Изменить пароль</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../services/api.service";

export default {
  name: "ChangePassword",
  data() {
   return {
     showChanged: false,
     form: {
       current_password: null,
       new: {}
     }
   }
  },
  methods: {
    async changePassword() {
      let result = await this.$validator.validateAll()
      if(result) {
        let res = await ApiService.postJson('users/change-password', this.form)
        if(res.status === 200) {
          this.$toastr.s('', "Пароль был успешно изменен");
        } else {
          this.$toastr.e('', "Вы неправильно ввели Ваш текущий пароль");
        }
      }
    }
  }
}
</script>

<style scoped>

</style>