import ApiService from "../../services/api.service";
export default {
  state: {
    notes: [],
    allNotes: {
      total: 0,
      limit: 10,
      offset: 0,
      messages: []
    }
  },
  actions: {
    async getNotes(ctx: any) {
      const res = await ApiService.get('notification/unread')
      ctx.commit('setNotes', res.data)
    },
    async getAllNotes(ctx: any, filters: {}) {
      const res = await ApiService.get('notifications', {
        params: {
          ...filters
        }
      })
      ctx.commit('setAllNotes', res.data)
    }
  },
  mutations: {
    setNotes(state: any, notes: any) {
      state.notes = notes
    },
    setAllNotes(state: any, notes: any) {
      state.allNotes.messages = state.allNotes.messages.concat(notes.messages)
      state.allNotes.total = notes.total
      state.allNotes.offset = notes.offset
      state.allNotes.limit = notes.limit
    },
  },
  getters: {
    notes(state: any) {
      return state.notes
    },
    allNotes(state: any) {
      return state.allNotes
    },

  }
}
