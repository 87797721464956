<template>
  <div class="modal fade" id="avatar-upload" tabindex="-1" role="dialog" aria-labelledby="add-question" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header modal-header-blue-bg">
            <div class="title-with-icon">
              <div class="title-with-icon__icon title-with-icon__icon-white  title-with-icon__icon_upload"></div>
              <div class="title-with-icon__title">Загрузить фотографию</div>
            </div>
            <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <image-upload />
          </div>
          <div class="modal-footer">
            <button @click="crop" type="button" class="btn btn-outline-primary btn-with-icon">
              <div class="icon">
                <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.6665 8.66667H9.99984V2.66667C9.99984 2.48986 9.9296 2.32029 9.80458 2.19526C9.67955 2.07024 9.50998 2 9.33317 2H3.33317V1.33333C3.33317 1.15652 3.26293 0.986954 3.13791 0.86193C3.01288 0.736906 2.84331 0.666668 2.6665 0.666668C2.48969 0.666668 2.32012 0.736906 2.1951 0.86193C2.07008 0.986954 1.99984 1.15652 1.99984 1.33333V2H1.33317C1.15636 2 0.98679 2.07024 0.861766 2.19526C0.736742 2.32029 0.666504 2.48986 0.666504 2.66667C0.666504 2.84348 0.736742 3.01305 0.861766 3.13807C0.98679 3.2631 1.15636 3.33333 1.33317 3.33333H1.99984V9.33333C1.99984 9.51015 2.07008 9.67971 2.1951 9.80474C2.32012 9.92976 2.48969 10 2.6665 10H8.6665V10.6667C8.6665 10.8435 8.73674 11.013 8.86177 11.1381C8.98679 11.2631 9.15636 11.3333 9.33317 11.3333C9.50998 11.3333 9.67955 11.2631 9.80458 11.1381C9.9296 11.013 9.99984 10.8435 9.99984 10.6667V10H10.6665C10.8433 10 11.0129 9.92976 11.1379 9.80474C11.2629 9.67971 11.3332 9.51015 11.3332 9.33333C11.3332 9.15652 11.2629 8.98695 11.1379 8.86193C11.0129 8.7369 10.8433 8.66667 10.6665 8.66667ZM8.6665 8.66667H3.33317V3.33333H8.6665V8.66667Z"/>
                </svg>
              </div>
              <div class="text">Обрезать</div>
            </button>
            <button @click="save" type="button" class="btn btn-primary btn-with-icon">
              <div class="icon">
                <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.8067 4.19333L7.80667 0.193333C7.74523 0.133366 7.6728 0.0858287 7.59333 0.0533333C7.51068 0.0197446 7.42254 0.00166539 7.33333 0H2C1.46957 0 0.960859 0.210714 0.585787 0.585787C0.210714 0.960859 0 1.46957 0 2V10C0 10.5304 0.210714 11.0391 0.585787 11.4142C0.960859 11.7893 1.46957 12 2 12H10C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10V4.66667C12.0005 4.57893 11.9837 4.49195 11.9505 4.41073C11.9173 4.3295 11.8685 4.25563 11.8067 4.19333ZM4 1.33333H6.66667V2.66667H4V1.33333ZM8 10.6667H4V8.66667C4 8.48986 4.07024 8.32029 4.19526 8.19526C4.32029 8.07024 4.48986 8 4.66667 8H7.33333C7.51014 8 7.67971 8.07024 7.80474 8.19526C7.92976 8.32029 8 8.48986 8 8.66667V10.6667ZM10.6667 10C10.6667 10.1768 10.5964 10.3464 10.4714 10.4714C10.3464 10.5964 10.1768 10.6667 10 10.6667H9.33333V8.66667C9.33333 8.13623 9.12262 7.62753 8.74755 7.25245C8.37247 6.87738 7.86377 6.66667 7.33333 6.66667H4.66667C4.13623 6.66667 3.62753 6.87738 3.25245 7.25245C2.87738 7.62753 2.66667 8.13623 2.66667 8.66667V10.6667H2C1.82319 10.6667 1.65362 10.5964 1.5286 10.4714C1.40357 10.3464 1.33333 10.1768 1.33333 10V2C1.33333 1.82319 1.40357 1.65362 1.5286 1.5286C1.65362 1.40357 1.82319 1.33333 2 1.33333H2.66667V3.33333C2.66667 3.51014 2.7369 3.67971 2.86193 3.80474C2.98695 3.92976 3.15652 4 3.33333 4H7.33333C7.51014 4 7.67971 3.92976 7.80474 3.80474C7.92976 3.67971 8 3.51014 8 3.33333V2.27333L10.6667 4.94V10Z"/>
                </svg>
              </div>
              <div class="text">Сохранить</div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import 'bootstrap'
import ImageUpload from "@/components/ImageUpload.vue";
import {eventBus} from "@/main";
window.jQuery = $
export default {
  name: "ModalAvatarUpload",
  components: {
    ImageUpload,
    //Cropper
  },
  data() {
    return {
      img: null,
      userForm: null
    }
  },
  mounted() {
    let self = this
    $('#avatar-upload').on('hide.bs.modal', function () {
      self.img = null
    })
  },
  methods: {
    crop() {
      eventBus.$emit('image:crop')
      return false
    },
    save() {
      eventBus.$emit('image:upload')
      return false
    },
  }
}
</script>

<style lang="scss" scoped>
  .modal-dialog {
    max-width: 840px;
  }
  .modal-footer {
    padding-top: 2rem;
    button {
      &:first-child {
        margin-right: 3.2rem;
      }
    }
  }
</style>