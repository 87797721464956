<template>
  <div class="form-item" :class="{'form-item_groupped_required': validate, 'form-item_groupped': groupped}">
    <label :for="name"><span v-html="label"></span></label>
    <input :class="{invalid: errors.has(name)}" v-imask="mask" v-validate.immediate="validate" class="form-control" type="text" v-model="model" :name="name" :id="'id_' + name +'_'+ (Date.now())"/>
  </div>
</template>

<script>
import {IMaskDirective} from "vue-imask";

export default {
  name: "BaseInput",
  directives: {
    imask: IMaskDirective
  },
  inject: ['$validator'],
  props: {
    label: {
      type: String,
      required: true
    },
    validate: {
      type: [String, Boolean, Object],
      default() {
        return {
          required: true
        }
      }
    },
    groupped: {
      type: [Boolean],
      default() {
        return true
      }
    },
    value: {
      type: [String, Number, Object],
      description: 'Input value'
    },
    name: {
      type: String,
      required: true,
      default: 'base-input'
    },
    //{mask: '00000000000', lazy: true}
    mask: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      model: this.value
    }
  },
  watch: {
    model: {
      handler: function(val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    },
    value: {
      handler: function(val) {
        this.model = val
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>