import ApiService from "../../services/api.service";
export default {
    state: {
        acceleratorEnroll: {}
    },
    actions: {
        async getAcceleratorEnroll(ctx: any) {
            const res = await ApiService.get('acceleratorenroll')
            ctx.commit('setAcceleratorEnroll', res.data)
        },
        async updateAcceleratorEnroll(ctx: any, data: any) {
            const res = await ApiService.postJson('acceleratorenrolls', data)
            ctx.commit('setAcceleratorEnroll', res.data)
        }
    },
    mutations: {
        setAcceleratorEnroll(state: any, notes: any) {
            state.acceleratorEnroll = notes
        }
    },
    getters: {
        acceleratorEnroll(state: any) {
            return state.acceleratorEnroll
        }
    }
}
