<template>
  <form @submit.prevent="validate()">
    <label class="mt-2 mb-2">Выберите папку или введите название новой</label>
    <v-select
        taggable
        id="region"
        label="name"
        name="folder"
        :options="mspForsageFolders"
        v-model="model">
    </v-select>
    <div class="d-flex justify-content-end">
      <button :disabled="!model" class="mt-3 btn btn-primary">Сохранить</button>
    </div>
  </form>
</template>

<script>
import vmMixin from "@/mixins/vmMixin";
import validateMixin from "@/mixins/validateMixin";
import ApiService from "../../../../../services/api.service";
import {mapGetters} from "vuex";
import mspForsageOperatorMixin from "@/mixins/mspForsageOperatorMixin";
import {eventBus} from "@/main";
export default {
  name: "MspForsageOrdersForm",
  mixins: [vmMixin, validateMixin, mspForsageOperatorMixin],
  data() {
    return {
      options: []
    }
  },
  props: {
    region: {
      type: Object,
      required: true
    }
  },
  computed: {
    mspForsageFolders() {
      return this.mspForsageOrders.folders && this.mspForsageOrders.folders.length ? this.mspForsageOrders.folders : [{name: 'Новая папка'}]
    },
    ...mapGetters(['mspForsageOrders']),
    orders() {
      return this.mspForsageOrders
          .orders
          .filter(order => order.checked)
          .map(order => {
            return order.id
          })
    }
  },
  mounted() {
    this.model = null
    this.$on('on-submit', async () => {
      if(this.model) {
        let data = {
          ...this.model,
          orders: this.orders,
          enrollProgram: this.currentProgram,
          region: this.region.id
        }
        if(this.model.id) {
          await ApiService.put('msp-forsage/folders/' + this.model.id, data)
        } else {
          await ApiService.post('msp-forsage/folders', data)
        }
        eventBus.$emit('folder:changed')
      }
    })
  }
}
</script>

<style>

</style>