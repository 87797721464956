<template>
  <form @submit.prevent="validate()" class="mt-5">
    <base-input name="group" v-validate.immediate="'required'" v-model="model.title" label="Название потока"/>
    <base-label label="Даты обучения">
      <sweet-date-picker placeholder="Выберите даты" :class="{'invalid': !model.range}" name="range" :range="true" type="date" v-model="model.range" />
    </base-label>
    <div class="d-flex justify-content-between align-items-center mt-3">
      <div>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Отмена</button>
      </div>
      <button type="submit" class="btn btn-primary" :disabled="errors.has('group') || !model.range">
        Сохранить
      </button>
    </div>
  </form>
</template>

<script>
import vmMixin from "@/mixins/vmMixin";
import validateMixin from "@/mixins/validateMixin";
import BaseLabel from "@/components/form/BaseLabel.vue";
import SweetDatePicker from "@/components/form/SweetDatePicker.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import ApiService from "../../../../../services/api.service";
import mspForsageOperatorMixin from "@/mixins/mspForsageOperatorMixin";
import {eventBus} from "@/main";

export default {
  name: "MspForsageGroupForm",
  components: {BaseInput, SweetDatePicker, BaseLabel},
  mixins: [validateMixin, vmMixin, mspForsageOperatorMixin],
  inject: ['$validator'],
  props: {
    region: {
      type: Number,
      required: true
    }
  },
  mounted() {

    if(!this.model.range && this.model.start_date && this.model.end_date) {
      this.model.range = [
        this.$formatDate(this.model.start_date, 'DD.MM.YYYY'),
        this.$formatDate(this.model.end_date, 'DD.MM.YYYY')
      ]
    }

    this.$on('on-submit', async () => {
      let data = {
        title: this.model.title,
        startDate: this.model.range[0],
        endDate: this.model.range[1],
        region: this.region,
        enrollProgram: this.currentProgram
      }

      if(this.model.id) {
        await ApiService.put('msp-forsage/groups/' + this.model.id, data)
      } else {
        await ApiService.post('msp-forsage/groups', data)
        this.model = {}
      }
      eventBus.$emit('msp-groups:update')
    });
  }
}
</script>

<style scoped>

</style>