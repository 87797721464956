<template>
  <div v-if="organization">
    <h3 v-if="showLabel">Данные об организации</h3>
    <base-input label="ОГРН/ОГРНИП организации" :mask="{mask: '000000000000000', lazy: true}" name="ogrn" v-model="organization.ogrn"></base-input>
    <base-input label="Наименование организации" name="name" v-model="organization.name"></base-input>
    <base-input label="Сфера деятельности" name="company_activity_field" v-model="organization.company_activity_field"></base-input>
    <base-input label="ИНН организации" name="tin" v-model="organization.tin"></base-input>
    <base-input label="Организационно-правовая форма организации" name="type" v-model="organization.type"></base-input>
    <base-input v-if="showPost" label="Должность" name="post" v-model="organization.position"></base-input>
  </div>
</template>

<script>
import BaseInput from "@/components/form/BaseInput.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "CompanySettings",
  components: {BaseInput},
  inject: ['$validator'],
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    showPost: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object
    },
  },
  methods: {
    ...mapActions(['updateOrganization']),
    ...mapMutations(['setOrganization'])
  },
  watch: {
    ogrn: {
      handler: async function(val) {
        if(val === '' || val === undefined) {
          return;
        }
        await this.updateOrganization({ogrn: val})
      }
    },
    organization: {
      handler: async function(val) {
        this.$emit('input', val)
      },
      deep: true
    }
  },
  computed: {
    ...mapState({orgFromStore: 'organization'}),
    organization: {
      get () {
        return this.orgFromStore.organization
      },
      set (value) {
        this.setOrganization(value)
      }
    },
    ogrn() {
      return this.organization.ogrn
    }
  }
}
</script>

<style scoped>

</style>