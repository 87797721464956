<template>
  <div class="section__lk-content-item d-block pr-0 msp-folder orders-item" :class="{'can-edit': canEdit}">
    <base-modal title="Удаление папки"
                content="Вы уверены, что хотите удалить выбранную папку?"
                header-class="modal-header-danger-bg"
                modal-class="modal-xs"
                :id="'delete-folder-' + folder.id"
                :confirm-mode="true"
                v-model="deleteModal">
      <button @click="deleteFolder(folder)" type="button" class="btn btn-md btn-primary">Да</button>
    </base-modal>
    <base-modal title="Редактировать папку"
                modal-class="modal-lg"
                :id="'edit-folder-' + folder.id"
                v-model="editModal">
      <msp-forsage-folder-form v-model="folder" />
    </base-modal>
    <div class="section__lk-content-item-data">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="msp-folder__header">
          <icon-folder-opend v-if="folder.open" />
          <icon-folder-closed v-else />
          <span @click.prevent="openFolder()">
            <span class="msp-folder__header__name">&laquo;{{ folder.name }}&raquo;</span>
          </span>
        </h3>
        <div class="edit-buttons-block" v-if="canEdit">
          <span>
            <button @click.prevent="() => editModal = true" type="button" class="btn btn-primary p-1">
              <icon-edit fill="#fff" />
            </button>
          </span>
          <span>
            <button @click.prevent="() => deleteModal = true" type="button" class="btn btn-danger p-1">
              <icon-trash fill="#fff" />
            </button>
          </span>
          <span>
            <button @click.prevent="deleteFromFolder()" v-if="onDeleteOrders.length > 0" class="btn btn-primary p-1">
              <icon-delete-from-folder fill="#fff" />
            </button>
          </span>
        </div>
      </div>
      <div class="m-0 d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-start mb-1">
            <div class="mr-1">
              <icon-people />
            </div>
            <div>
              <div class="section__lk-content-item-subheader">
                Компаний
              </div>
              <div class="text-dark">{{ folder.orders.length }}</div>
            </div>
          </div>
      </div>
    </div>
    <div class="collapse pt-2" :class="{show: folder.open}">
      <template v-if="folder.orders.length">
        <msp-forsage-orders-item :show-checked="canEdit" v-for="order in folder.orders" v-model="order.checked" :key="order.id" :order="order" />
      </template>
      <span v-else>Папка пуста</span>
    </div>
  </div>
</template>

<script>
import vmMixin from "@/mixins/vmMixin";
import MspForsageOrdersItem from "@/views/profile/mspForsageOperator/components/MspForsageOrdersItem.vue";
import Vue from "vue";
import IconFolderOpend from "@/components/icons/IconFolderOpend.vue";
import IconFolderClosed from "@/components/icons/IconFolderClosed.vue";
import IconPeople from "@/components/icons/IconPeople.vue";
import ApiService from "../../../../../services/api.service";
import {mapActions} from "vuex";
import mspProgramMixin from "@/mixins/mspProgramMixin";
import {eventBus} from "@/main";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconDeleteFromFolder from "@/components/icons/IconDeleteFromFolder.vue";
import BaseModal from "@/components/modal/BaseModal.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import MspForsageFolderForm from "@/views/profile/mspForsageOperator/components/MspForsageFolderForm.vue";

export default {
  name: "MspForsageFoldersItem",
  components: {
    MspForsageFolderForm,
    IconEdit,
    BaseModal,
    IconDeleteFromFolder, IconTrash, IconPeople, IconFolderClosed, IconFolderOpend, MspForsageOrdersItem},
  mixins: [vmMixin, mspProgramMixin],
  data() {
    return {
      deleteModal: false,
      editModal: false
    }
  },
  props: {
    folder: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  methods: {
    openFolder() {
      Vue.set(this.folder, 'open', !this.folder.open)
    },
    async deleteFromFolder() {
      let promiseArr = this.onDeleteOrders.map(function (order) {
        return ApiService.put('/msp-forsage/order/' + order.id, {
          folder: 'delete'
        })
      });
      Promise.all(promiseArr).then(async () => {
        eventBus.$emit('folder:changed')
      }).catch(function(err){
        console.log(err)
      })
    },
    async deleteFolder(folder) {
      console.log(folder)
      await ApiService.delete('msp-forsage/folders/' + folder.id)
      eventBus.$emit('folder:changed')
      this.deleteModal = false
    },
    ...mapActions(['getMspForsageOrders'])
  },
  computed: {
    onDeleteOrders() {
      return this.folder.orders.filter(order => order.checked)
    }
  },
  mounted() {
    eventBus.$on('folder:changed', () => {
      this.editModal = false
    })
  }
}
</script>
<style lang="scss">
.section__lk-content-item-right {
  width: auto;
  @media (max-width: 576px){
    width: 100%;
  }
}
.msp-folder {
  &__header {
    align-items: center;
    margin-bottom: 20px!important;
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
      color: #323232;
    }
    &__name {
      color: #1C75BC;
      margin: 0 1rem;
    }
  }
  .orders-item {
    border-left: 1px solid #F2F2F2;
    margin-left: -2.4rem;
  }
  .section__lk-content-item-data {
    cursor: pointer;
  }
  &.can-edit {
    @media (max-width: 576px) {
      padding-top: 46px;
    }
  }
}
.collapse {
  .section__lk-content-item {
    background: none!important;
  }
}
</style>