import { render, staticRenderFns } from "./IconFolderOpend.vue?vue&type=template&id=12fcac2f&scoped=true&"
import script from "./IconFolderOpend.vue?vue&type=script&lang=js&"
export * from "./IconFolderOpend.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fcac2f",
  null
  
)

export default component.exports