<template>
  <li class="notification-bell" v-if="notes">
    <span @click="toggleList()">
      <notification-bell
        :size="20"
        :count="notes.unread_count"
        :upperLimit="9"
        :iconColor="'#1C75BC'"
        :counterBackgroundColor="'#dc3545'"
        counterStyle="round"
      />
    </span>
    <div class="bell-wrapper container-shadow" v-show="showMessages && notes.unread_count">
      <div class="bell-wrapper__header">
        <div class="bell-wrapper__title">
          <h3>Уведомления</h3>
        </div>
        <a @click.prevent="clearMessages()" href="#">Очистить уведомления</a>
      </div>
      <ul class="bell-list">
        <li v-for="message in notes.messages" :key="message.id" class="pt-1 pb-1">
          <div class="d-flex justify-content-around">
            <div class="bell-list__title">
              <a @click.prevent="goTo(message)" href="#">
                {{message.title}}
              </a>
            </div>
            <a href="#" class="bell-list-clear d-block" @click.prevent="readNotify(message)">
              <svg width="11" height="11" fill="#dc3545" stroke="">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close">
                  <svg viewBox="0 0 50 50" id="close">
                    <path d="M30.4 25L48.9 6.6c1.5-1.5 1.5-3.9 0-5.4-1.5-1.5-3.9-1.5-5.4 0L25 19.6 6.6 1.1C5.1-.4 2.7-.4 1.2 1.1-.3 2.6-.3 5 1.2 6.5L19.6 25 1.1 43.4c-1.5 1.5-1.5 3.9 0 5.4 1.5 1.5 3.9 1.5 5.4 0L25 30.4l18.4 18.4c1.5 1.5 3.9 1.5 5.4 0 1.5-1.5 1.5-3.9 0-5.4L30.4 25z"></path>
                  </svg>
                </use>
              </svg>
            </a>
          </div>
        </li>
      </ul>
      <div class="text-center mt-2">
        <router-link tag="button" to="/lk/notifications" class="link-target">
          <span>Смотреть все</span>
        </router-link>
      </div>
    </div>
  </li>
</template>

<script>
import NotificationBell from 'vue-notification-bell'
import {mapActions, mapGetters} from "vuex";
import ApiService from "../../services/api.service";
export default {
  name: "Notification",
  components: {
    NotificationBell // Registering Component
  },
  data() {
    return {
      showMessages: false
    }
  },
  computed: {
    ...mapGetters(['notes'])
  },
  methods: {
    ...mapActions(['getNotes']),
    toggleList() {
      this.showMessages = !this.showMessages;
      if(!this.notes.length) {
        this.$router.push('/lk/notifications').catch(() => {})
      }
    },
    async clearMessages() {
      await ApiService.post('notifications/reads')
      this.toggleList()
      await this.getNotes()
    },
    async readNotify(message) {
      await ApiService.put('notifications/' + message.id, {
        isViewed: true
      })
      await this.getNotes()
    },
    async goTo(message) {
      await ApiService.put('notifications/' + message.id, {
        isViewed: true
      })
      await this.getNotes()
      window.location.href = message.link
    }
  },
  watch: {
    '$route': {
      handler: function() {
        this.showMessages = false
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.notification-bell {
  position: relative;
  .notificationBell {
    position: absolute;
    top: -30px;
    cursor: pointer;
  }
}
.notification-counter {
  width: 15px!important;
  height: 15px!important;
  margin-top: -5px!important;
  font-size: 10px;
}
.bell-wrapper {
  position: absolute;
  width: 368px;
  right: 0;
  top: 0;
  background-color: #fff;
  padding: 20px 14px 14px;
  z-index: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bell-wrapper__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  a {
    text-align: right;
  }
}
.bell-list {
  margin: 0;
  padding: 0;
  max-height: 300px;
  overflow: auto;
}
.bell-list li {
  background-color: #f5f5f5;
  margin-bottom: 10px;
}
.bell-list-clear {
  display: block;
  line-height: 0;
  border: 0;
  margin-right: 10px;
  cursor: pointer;
}
.bell-wrapper__title {
  line-height: 15px;
  font-weight: 600;
  color: #2f2d2d;
}
</style>