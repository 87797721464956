import ApiService from "../../services/api.service";
export default {
    state: {
        recommend: {
            state: null
        }
    },
    actions: {
        async getRecommend(ctx: any) {
            const res = await ApiService.get('test/recommend')
            ctx.commit('setRecommend', res.data)
        },
    },
    mutations: {
        setRecommend(state: any, notes: any) {
            state.recommend = notes
        }
    },
    getters: {
        recommend(state: any) {
            return state.recommend
        }
    }
}
