<template>
  <div class="admin-comment">
    <div class="fill-grey d-flex admin-comment__item" v-for="(comment, index) in  comments" :key="index" :class="{'mt-24': index }">
      <div class="admin-comment__avatar mr-24"></div>
      <div>
        <div class="admin-comment__name">Администратор</div>
        <div class="admin-comment__date">{{ comment.created_at | formatDate('DD.MM.YYYY') }}</div>
        <div class="admin-comment__comment" v-if="comment.tutor_answer" v-html="comment.tutor_answer"></div>
        <div class="admin-comment__comment" v-else-if="comment.comment" v-html="comment.comment"></div>
        <div class="admin-comment__comment" v-else>Администратор не оставил комментарий</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminComments",
  props: {
    comments: {
      type: [Object, Array],
      required: true
    }
  }
}
</script>

<style lang="scss">
.admin-comment {
  &__item {
    background: #fafafa;
    padding: 3.6rem 4.8rem;
  }
  &__avatar {
    width: 9.6rem;
    height: 9.6rem;
    background: url('/pic/user.svg') center center no-repeat, #BDBDBD;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 16px 24px 0 rgba(0, 0, 0, 0.10);
    border-radius: 40%;
    flex: 9.6rem 0 0;
  }
  &__name {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.4rem;
    margin-bottom: 0.4rem;
  }
  &__date {
    color: #BDBDBD;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    margin-bottom: 0.4rem;
  }
  &__comment {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
  }
}

</style>