<template>
  <div class="form-item form-item_groupped" :class="{'form-item_groupped_required': required}">
    <label><span v-html="label"></span></label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseLabel",
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style scoped>

</style>