// @ts-nocheck
import {mapGetters} from "vuex";
import mspProgramMixin from "@/mixins/mspProgramMixin";
import {eventBus} from "@/main";

export default {
    mixins: [mspProgramMixin],
    computed: {
        ...mapGetters(['mspMenu']),
        currentIndex() {
            return this.routeItems.indexOf(this.$route.name)
        },
        routeItems() {
            return this.mspMenu[this.programPath].map((menu) => {
                return menu.route
            })
        },
        lastIndex() {
            return this.mspMenu[this.programPath].length - 1;
        }
    },
    methods: {
        goBack() {
            if(this.mspMenu[this.programPath][this.currentIndex - 1]) {
                if(this.currentIndex !== 1) {
                    this.$parent.$emit('on-submit')
                }
                this.$router.push(this.mspMenu[this.programPath][this.currentIndex - 1].link).catch(() => {})
            }
        },
        goForward() {
            if(this.mspMenu[this.programPath][this.currentIndex + 1]) {
                if(this.currentIndex !== 1) {
                    this.$parent.$emit('on-submit')
                }
                this.$router.push(this.mspMenu[this.programPath][this.currentIndex + 1].link).catch(() => {})
            }
        }
    }
}