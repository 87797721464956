// @ts-nocheck

import {mapGetters} from "vuex";


export default  {
    computed: {
        ...mapGetters(['mspForsageOperator']),
        currentProgram() {
            return this.$route.query.program
        }
    }
}