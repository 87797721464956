<template>
  <div class="mt-5">
    <msp-forsage-member-list />
    <div class="separator"></div>
    <h3>Личные данные</h3>
    <form @submit.prevent="validate()" v-if="settings">
      <base-input :validate="'required|email'" name="email" type="email" v-model="email" label="Email" />
      <user-settings
          :show-address="hasFull"
          :show-snils="false"
          :show-phone="true"
          :show-header="false"
          :member-mode="hasFull"
          v-model="settings" />
      <div class="separator"></div>
      <base-upload :required="true" id="forward" name="agreement" v-model="docs.agreement" title="Согласие на обработку персональных данных" :sample="{
         link: programId === 2 ? 'https://exporteduru.servicecdn.ru/media/default/0003/28/f43b7b4382a719434832b599c751c4ee5e2cd64b.docx'
         : 'https://exporteduru.servicecdn.ru/media/default/0003/27/b4ba76b73b74e758813c3fc5d02f91c5aafc97c2.docx',
         title: 'Шаблон согласия'
      }" />
      <div class="separator"></div>
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="m-0">Зарегистрировать сотрудника</h3>
        <button type="submit" class="btn btn-primary btn-with-icon">
          Зарегистрировать
        </button>
      </div>
      <div class="separator"></div>
      <msp-forsage-footer />
    </form>
  </div>
</template>

<script>
import UserSettings from "@/components/profile/UserSettings.vue";
import MspForsageFooter from "@/components/profile/mspForsage/MspForsageFooter.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import {mapActions, mapGetters} from "vuex";
import MspForsageMemberList from "@/components/profile/mspForsage/MspForsageMember.vue";
import validateMixin from "@/mixins/validateMixin";
import mspProgramMixin from "@/mixins/mspProgramMixin";
import BaseUpload from "@/components/form/BaseUpload.vue";
import moment from "moment/moment";

export default {
  name: "MspForsageMember",
  components: {
    BaseUpload,
    MspForsageMemberList,
    BaseInput, MspForsageFooter, UserSettings},
  mixins: [validateMixin, mspProgramMixin],
  data() {
    return {
      settings: null,
      email: null,
      education: {},
      passport: {},
      docs: {}
    }
  },
  mounted() {
    this.settings = {}
    this.$on('on-submit', async () => {
      let res = await this.addAcceleratorMember(this.member)
      if(res.status === 200) {
        await this.getAcceleratorMembers({order: this.mspForsageOrder.id})
        this.settings = null
        this.email = null
        this.education = {}
        this.passport = {}
        this.docs = {}
        await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.$toastr.s('', "Сотрудник успешно добавлен");
        setTimeout(() => {
          this.settings = {}
        }, 500)
      } else {
        this.$toastr.e('', res.data.message ? res.data.message : "Проверьте правильность заполнения полей");
      }
    })
  },
  methods: {
    ... mapActions(['addAcceleratorMember', 'getAcceleratorMembers'])
  },
  computed: {
    member() {
      return {
        ...{email: this.email},
        ...this.settings,
        ...this.education,
        passport: {
          ...this.passport,
          issue_date: moment(this.education.issue_date).format('DD.MM.YYYY'),
          date_of_birth: moment(this.education.date_of_birth).format('DD.MM.YYYY'),
        },
        order: this.mspForsageOrder.id,
        documents: this.docs
      }
    },
    hasFull() {
      return this.programId === 2
    },
    ...mapGetters(['mspForsageOrder'])
  }
}
</script>

<style lang="scss">
.block-member {
  .form-item_groupped > label + * {
    max-width: calc(100% - 18.4rem);
    flex: 0 0 calc(100% - 18.4rem);
  }
  &-wide {
    .form-item_groupped > label + * {
      max-width: calc(100% - 17rem);
      flex: 0 0 calc(100% - 17rem);
    }
    .sweet-calendar {
      width: 100%!important;
    }
  }
}
</style>