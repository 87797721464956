import ApiService from "../../services/api.service";

export default {
    state: {
        mspForsageOperator: null
    },
    actions: {
        async getMspForsageOperator(ctx: any) {
            return new Promise((resolve, reject) => {
                ApiService.get('msp-forsage/operator')
                    .then(res => {
                        ctx.commit('setMspForsageOperator', res.data)
                        resolve(res.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    mutations: {
        setMspForsageOperator(state: any, notes: any) {
            state.mspForsageOperator = notes
        }
    },
    getters: {
        mspForsageOperator(state: any) {
            return state.mspForsageOperator
        }
    }
}
