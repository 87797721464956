<template>
  <div :class="'status status_' + status"></div>
</template>

<script>
export default {
  name: "Status",
  props: {
    status: {
      type: String,
      default() {
        return 'draft'
      }
    }
  }
}
</script>

<style scoped>

</style>