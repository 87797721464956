<template>
  <div class="mb-5">
    <div class="d-block d-lg-flex justify-content-between align-items-center mb-2">
      <h3 class="m-lg-0"><member-counter /></h3>
      <router-link to="/lk/region-team/members" tag="button" class="btn btn-primary btn-md">Перейти к приглашениям</router-link>
    </div>
    <member-notes text="Необходимо пригласить от 7 до 10 сотрудников." />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MemberCounter from "@/components/profile/regionteam/MemberCounter.vue";
import MemberNotes from "@/components/profile/regionteam/MemberNotes.vue";

export default {
  name: "RegionTeamMemberCounter",
  components: {MemberNotes, MemberCounter},
  computed: {
    ...mapGetters(['regionteam'])
  }
}
</script>

<style scoped>

</style>