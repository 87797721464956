export default {
    state: {
        menu: [
            {name: 'Данные компании', link: '/lk/accelerator/company', route: 'AcceleratorCompany', success: false},
            {name: 'Регистрация сотрудников', link: '/lk/accelerator/members', route: 'AcceleratorMember', success: false},
            {name: 'Профиль компании', link: '/lk/accelerator/profile', route: 'AcceleratorProfile', success: false},
            {name: 'Документы компании', link: '/lk/accelerator/docs', route: 'AcceleratorDocs', success: false},
        ]
    },
    actions: {
        async updateAcMenu(ctx: any, data: any) {
            ctx.commit('setAcMenu', data)
        }
    },
    mutations: {
        setAcMenu(state: any, item: any) {
            state.menu = state.menu.map((menuItem: any) => {
                if(menuItem.route === item.route) {
                    menuItem.success = item.success
                }
                return menuItem;
            })
        }
    },
    getters: {
        acMenu(state: any) {
            return state.menu
        },
        acMenuSuccess(state: any) {
            return state.menu.filter((item: any) => item.success).length === 4
        }
    }
}
