<template>
  <div @click="openModal" class="upload-wrapper">
    <a href="javascript:void(0)" class="upload-image">
      <div v-if="user.avatar" class="section__lk-content__right__avatar" :style="{backgroundImage: 'url(' + user.avatar['500x'] + ')'}"></div>
      <img v-else src="/pic/profile/upload.svg"/>
    </a>
    <div class="upload-title">
      <h2 class="d-md-none d-sm-block">Фотограция профиля</h2>
      <a class="upload-link" href="#">Изменить фотографию</a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import 'bootstrap'
import {TokenService} from "../../../services/token.service";
import {eventBus} from "@/main";

export default {
  name: "UploadLink",
  data() {
    return {
      user: TokenService.getUserObj()
    }
  },
  mounted() {
    let self = this
    eventBus.$on('user:upd', () => {
      self.$nextTick(() => {
        self.user = JSON.parse(TokenService.getUser())
      })
    })
  },
  methods: {
    openModal() {
      $('#avatar-upload').modal('show')
    }
  }
}
</script>

<style lang="scss">
.upload-wrapper {
  .upload-link {
    color: #002E5E;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1;
    text-decoration: underline;
    margin-top: 32px;
    display: block;
  }
  @media (max-width: 768px) {
    display: flex;
    .upload-image {
      width: 170px;
    }
    .section__lk-content__right__avatar {
      width: 96px;
      height: 96px;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
    }
    .upload-link {
      font-size: 16px;
      margin-top: 12px;
    }
    margin: 16px 0 56px;
  }
  @media (max-width: 540px) {
    flex-direction: column;
    .upload-image {
      width: 96px;
    }
    .section__lk-content__right__avatar {
      width: 72px;
      height: 72px;
    }
    h2 {
      margin-top: 20px;
    }
  }
}

</style>