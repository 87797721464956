<template>
  <div class="mt-5">
    <accelerator-member-list />
    <div class="separator"></div>
    <h3>Личные данные</h3>
    <form @submit.prevent="validate()">
      <base-input :validate="'required|email'" name="email" v-model="email" label="Email" />
      <user-settings :member-mode="true" :show-phone="true" :show-header="false" v-model="settings" />
      <div class="separator"></div>
      <education-settings v-model="education"/>
      <div class="separator"></div>
      <passport-settings :ignore-payload="true" v-model="passport" />
      <div class="separator"></div>
      <div class="d-flex justify-content-between align-items-center mb-32">
        <h3 class="m-0">Документы для загрузки</h3>
        <a href="#" target="_blank" class="docs-needed">
          Требования к документам
        </a>
      </div>
      <base-upload :required="true" id="scan_passport" name="scan_passport" title="Паспорт" description="Копия паспорта (одним файлом/архивом - развороты страниц с фотографией и регистрацией)" v-model="docs.scan_passport" />
      <base-upload :required="true" id="scan_diploma" name="scan_diploma" title="Диплом" description="Копия документа государственного образца об образовании (высшее, средне профессиональное). Для иностранных обучающихся – перевод документов, оформленный в установленном порядке" v-model="docs.scan_diploma" />
      <base-upload :required="false" id="scan_change_surname" name="scan_change_surname" title="Свидетельство о смене фамилии" description="Копия свидетельства о смене фамилии (при необходимости)" v-model="docs.scan_change_surname" />
      <div class="separator"></div>
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="m-0">Зарегистрировать сотрудника</h3>
        <button @click="addMember()" class="btn btn-primary btn-with-icon">
          Зарегистрировать
        </button>
      </div>
      <div class="separator"></div>
      <accelerator-footer />
    </form>
  </div>
</template>

<script>
import UserSettings from "@/components/profile/UserSettings.vue";
import EducationSettings from "@/components/EducationSettings.vue";
import PassportSettings from "@/components/PassportSettings.vue";
import BaseUpload from "@/components/form/BaseUpload.vue";
import AcceleratorFooter from "@/components/profile/accelerator/AcceleratorFooter.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import {mapActions} from "vuex";
import moment from "moment";
import AcceleratorMemberList from "@/components/profile/accelerator/AcceleratorMember.vue";
import validateMixin from "@/mixins/validateMixin";

export default {
  name: "AcceleratorMember",
  components: {
    AcceleratorMemberList,
    BaseInput, AcceleratorFooter, BaseUpload, PassportSettings, EducationSettings, UserSettings},
  mixins: [validateMixin],
  data() {
    return {
      settings: {},
      email: null,
      education: {},
      passport: {},
      docs: {}
    }
  },
  mounted() {

  },
  methods: {
    async addMember() {
      let res = await this.addAcceleratorMember(this.member)
      if(res.status === 200) {
        await this.getAcceleratorMembers()
        this.settings = {}
        this.email = null
        this.education = {}
        this.passport = {}
        this.docs = {}
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.$toastr.s('', "Сотрудник успешно добавлен");
      }
    },
    ... mapActions(['addAcceleratorMember', 'getAcceleratorMembers'])
  },
  computed: {
    member() {
      return {
        ...{email: this.email},
        ...this.settings,
        ...this.education,
        passport: {
          ...this.passport,
          issue_date: moment(this.passport.issue_date).format('DD.MM.YYYY'),
          date_of_birth: moment(this.passport.date_of_birth).format('DD.MM.YYYY'),
        },
        documents: this.docs,
        date_of_birth: moment(this.passport.date_of_birth).format('DD.MM.YYYY'),
      }
    }
  }
}
</script>

<style lang="scss">
.block-member {
  .form-item_groupped > label + * {
    max-width: calc(100% - 18.4rem);
    flex: 0 0 calc(100% - 18.4rem);
  }
  &-wide {
    .form-item_groupped > label + * {
      max-width: calc(100% - 17rem);
      flex: 0 0 calc(100% - 17rem);
    }
    .sweet-calendar {
      width: 100%!important;
    }
  }
}
</style>