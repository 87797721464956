<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Мои документы / Семинары</div>
    <div class="section__lk-subtitle">Список документов</div>
    <div v-if="!docs.length && !certs.length" class="section__lk-empty">пока у вас нет ни одного документа</div>
    <template v-else>
      <a v-for="doc in docs" :key="doc.id" class="section__lk-course" target="_blank" :href="doc.file.file">
        <div class="section__lk-course-icon"><span></span></div>
        <div class="section__lk-course-title">
          <div class="label">{{ doc.type === 'certificate' ? 'Удостоверение' : 'Справка' }}</div>
          {{ doc.event.course.title }}
        </div>
        <div class="section__lk-course-date">
          <div class="label">Дата выдачи:</div>
          {{ doc.date | formatDate('DD.MM.YYYY') }}
        </div>
        <span class="section__lk-course-link container-shadow"></span>
      </a>
      <a v-for="cert in certs" :key="cert.id" class="section__lk-course" target="_blank" :href="cert.file ? cert.file.file : cert.pdf">
        <div class="section__lk-course-icon"><span></span></div>
        <div class="section__lk-course-title">
          <div class="label">Сертификат</div>
          {{ cert.course.title }}
        </div>
        <div class="section__lk-course-date">
          <div class="label">Дата выдачи:</div>
          {{ cert.issue_date | formatDate('DD.MM.YYYY') }}
        </div>
        <span class="section__lk-course-link container-shadow"></span>
      </a>
    </template>
  </div>
</template>

<script>
import ApiService from "../../../../services/api.service";

export default {
  name: "OnlineDocs",
  data() {
    return {
      docs: [],
      certs: [],
    }
  },
  async mounted() {
    this.docs = (await ApiService.get('eventparticipant/documents')).data
    this.certs = (await ApiService.get('certificate/my')).data
  }
}
</script>

<style scoped>
.section__lk-course {
  cursor: pointer;
}
</style>