<template>
  <vue-autosuggest
      ref="autocomplete"
      v-model="query"
      :suggestions="suggestions"
      :inputProps="inputProps"
      :name="name"
      v-validate.immediate="validate"
      :sectionConfigs="sectionConfigs"
      :renderSuggestion="renderSuggestion"
      :getSuggestionValue="getSuggestionValue"
      @input="fetchResults"
  />
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "Autocomplete",
  inject: ['$validator'],
  props: {
    path: {
      type: String,
      default: 'cities?filters[country]=1&filters[cityNameRu]='
    },
    name: {
      type: String,
      default: 'city_name_ru'
    },
    valueName: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Выберите город'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validate: {
      type: [String, Boolean, Object],
      default() {
        return 'required'
      }
    },
    placeholder: {
      type: String,
      default: 'Город'
    },
    value: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      query: "",
      results: [],
      timeout: null,
      selected: this.value,
      debounceMilliseconds: 250,
      url: process.env.VUE_APP_ROOT_API,
      inputProps: {
        id: "autosuggest__input",
        placeholder: this.placeholder,
        class: "form-control",
        name: this.name
      },
      suggestions: [],
      sectionConfigs: {
        defaults: {
          limit: 6,
          label: this.label,
          onSelected: selected => {
            this.selected = selected.item;
            this.$emit('input', this.selected)
          }
        }
      }
    };
  },
  mounted() {
    this.query = this.value ? this.value[this.nameValue] : this.value
    this.inputProps.disabled = this.disabled
  },
  methods: {
    fetchResults() {
      const query = this.query;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const promise = ApiService.get(this.url + this.path + query);

        Promise.all([promise]).then(values => {
          this.suggestions = [];
          this.selected = null;
          const data = this.filterResults(values[0].data, query, this.nameValue);
          typeof data === 'object' &&
          data.length &&
          this.suggestions.push({name: "defaults", data: data });
        });
      }, this.debounceMilliseconds);
    },
    filterResults(data, text, field) {
      if(data && data !== 'string' && data.length) {
        return data
            .filter(item => {
              if (item[field] && item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
                return item[field];
              }
            })
            .sort();
      }
    },
    renderSuggestion(suggestion) {
      let description = this.description ? this.description.split('.').reduce((o,i)=> o[i], suggestion.item) : null
      return (
          !description ? suggestion.item[this.nameValue] :
          <div>{suggestion.item[this.nameValue]}<br/><strong><small>{description}</small></strong></div>
      );
    },
    getSuggestionValue(suggestion) {
      let { item } = suggestion;
      return item[this.nameValue];
    }
  },
  watch: {
    selected: {
      handler: function(val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    nameValue() {
      if(!this.valueName) {
        return this.name
      }
      return this.valueName
    }
  }
};
</script>

<style lang="scss">
@import "../assets/style/variables";
#autosuggest__input {
  outline: none;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.invalid
{
  #autosuggest__input {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7099 1.2101C14.617 1.11638 14.5064 1.04198 14.3845 0.991213C14.2627 0.940445 14.132 0.914307 13.9999 0.914307C13.8679 0.914307 13.7372 0.940445 13.6154 0.991213C13.4935 1.04198 13.3829 1.11638 13.29 1.2101L5.83995 8.6701L2.70995 5.5301C2.61343 5.43687 2.49949 5.36355 2.37463 5.31435C2.24978 5.26514 2.11645 5.24101 1.98227 5.24334C1.84809 5.24566 1.71568 5.27438 1.5926 5.32788C1.46953 5.38137 1.35819 5.45858 1.26495 5.55511C1.17171 5.65163 1.0984 5.76557 1.04919 5.89042C0.999989 6.01528 0.975859 6.1486 0.97818 6.28278C0.980502 6.41696 1.00923 6.54937 1.06272 6.67245C1.11622 6.79553 1.19343 6.90687 1.28995 7.0001L5.12995 10.8401C5.22291 10.9338 5.33351 11.0082 5.45537 11.059C5.57723 11.1098 5.70794 11.1359 5.83995 11.1359C5.97196 11.1359 6.10267 11.1098 6.22453 11.059C6.34639 11.0082 6.45699 10.9338 6.54995 10.8401L14.7099 2.68011C14.8115 2.58646 14.8925 2.47281 14.9479 2.34631C15.0033 2.21981 15.0319 2.08321 15.0319 1.94511C15.0319 1.807 15.0033 1.6704 14.9479 1.5439C14.8925 1.4174 14.8115 1.30375 14.7099 1.2101Z' fill='%23#{str-slice('#{$red}', 2)}'/%3E%3C/svg%3E") !important;
    box-shadow: 0 0 0 1px $red inset!important;
    background-repeat: no-repeat!important;
    background-position: 95% center!important;
  }
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow-y: scroll;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results-before {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
  background-color: #f6f6f6;
}
</style>