<template>
  <div class="msp-order-detail">
    <h3>Сведения о компании</h3>
    <div class="text" v-if="order.user_organization">
      <base-label label="Компания" :required="false">
        {{ order.user_organization.name }}
      </base-label>
      <base-label label="ИНН" :required="false">
        {{ order.user_organization.tin }}
      </base-label>
      <base-label label="ОГРН" :required="false">
        {{ order.user_organization.ogrn }}
      </base-label>
      <base-label label="ОКВЭД" :required="false">
        {{ order.user_organization.okved }}
      </base-label>
      <base-label label="Выручка, млн. руб." :required="false">
        {{ order.revenue }}
      </base-label>
      <base-label label="Информация о банкротстве" :required="false">
        <span v-if="!order.user_organization.is_bankrot" class="text-success">
          Не числится в Едином федеральном реестре сведений о банкротстве
        </span>
        <span v-else class="text-danger">
          ВНИМАНИЕ! Компания числится в Едином федеральном реестре сведений о банкротстве!
        </span>
      </base-label>
      <base-label label="Email аккаунта" :required="false">
        {{ order.username }}
      </base-label>
      <base-label label="Иные заявки" :required="false">
        {{ order.other_order_cause }}
      </base-label>
    </div>
    <separator />
    <h3>Сведения о сотрудниках компании</h3>
    <el-table :data="order.company_members">
      <el-table-column prop="user.fullname" label="ФИО" />
      <el-table-column prop="user.email" label="Email" />
      <el-table-column prop="user.phone" label="Телефон" />
    </el-table>
    <separator />
    <h3>Финансовая диагностика</h3>
    <finance-diagnostic-form v-model="order"/>
    <separator />
    <template v-if="documents.length">
      <h3>Прикрепленные документы</h3>
      <document-list :grid="2" :documents="documents" />
      <separator />
    </template>
    <h3 v-if="!disabled">Ваш ответ</h3>
    <h3 v-else>Ваш ответ: &laquo;заявка {{ order.status === 'approved' ? 'утверждена' : 'отклонена' }}&raquo;</h3>
    <textarea :disabled="disabled" class="form-control" v-model="comment" :class="{invalid: status === 'rejected' && !comment}" name="comment" id="name-comment" placeholder="Ваш комментарий"></textarea>
    <div class="d-flex align-items-center justify-content-between mt-5">
      <button :disabled="disabled" @click="submit('rejected')" type="button" class="btn btn-outline-primary btn-outline-primary-with-icon">
        <icon-accept />
        Отклонить
      </button>
      <button :disabled="disabled" @click="submit('approved')" type="button" class="btn btn-primary btn-with-icon mr-2">
        <icon-reject/>
        &nbsp;
        Одобрить
      </button>
    </div>
  </div>
</template>

<script>
import Separator from "@/components/Separator.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import FinanceDiagnosticForm from "@/components/FinanceDiagnosticForm.vue";
import DocumentList from "@/components/DocumentList.vue";
import IconAccept from "@/components/icons/IconAccept.vue";
import IconReject from "@/components/icons/IconReject.vue";
import ApiService from "../../../../../services/api.service";
import orderPropMixin from "@/mixins/orderPropMixin";
import {eventBus} from "@/main";
import {TokenService} from "../../../../../services/token.service";

export default {
  name: "MspForsageOrderDetail",
  components: {IconReject, IconAccept, DocumentList, FinanceDiagnosticForm, BaseLabel, Separator},
  mixins: [orderPropMixin],
  computed: {
    documents() {
      let docs = []
      if(this.order) {
        if(this.order.forward) {
          docs.push({
            title: 'Направление на обучение',
            link: this.order.forward.file
          })
        }
        if(this.order.attorney) {
          docs.push({
            title: 'Доверенность',
            link: this.order.attorney.file
          })
        }
        if(this.order.accelerator_profiles) {
          docs.push({
            title: 'Профиль компании',
            link: ApiService.baseURL()
                + 'api/acceleratorprofiles/'
                + this.order.accelerator_profiles[0].id
                + '/download?access_token='
                + this.token
          })
        }
      }

      return docs
    },
    validateAll() {
      return this.status === 'rejected' && this.comment.length > 3 || this.status === 'approved';
    },
    disabled() {
      return ['approved', 'rejected'].indexOf(this.order.status) > -1
    }
  },
  data() {
    return {
      status: 'approved',
      comment: '',
      token: TokenService
          .getToken()
          .replaceAll('"', '')
    }
  },
  mounted() {
    this.comment = this.order.comment
  },
  methods: {
    async submit(status) {
      this.status = status
      if(!this.validateAll) {
        return;
      }
      await ApiService.post('msp-forsage/order/' + this.order.id + '/accept', {
        status: this.status,
        comment: this.comment
      })
      eventBus.$emit('folder:changed')
    }
  }
}
</script>]]]

<style lang="scss">
.msp-order-detail {
  padding-top: 4.8rem;
  font-size: 2.2rem;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

}
</style>