<template>
    <form @submit.prevent="validate()">
      <base-upload v-for="doc in docs" :title="doc.title" v-model="regionteam[doc.key]" :key="doc.key" :required="true" :id="doc.key" :name="doc.key" />
      <separator />
      <region-team-member-counter />
      <separator />
      <div class="d-block d-lg-flex justify-content-between align-items-center pb-5">
        <base-button-send :disabled="disabled" text="Отправить сведения" />
        <base-link-save @click.native="draft()" />
      </div>
    </form>
</template>

<script>
import BaseUpload from "@/components/form/BaseUpload.vue";
import RegionTeamMemberCounter from "@/components/profile/regionteam/RegionTeamMemberCounter.vue";
import Separator from "@/components/Separator.vue";
import BaseButtonSend from "@/components/form/BaseButtonSend.vue";
import BaseLinkSave from "@/components/form/BaseLinkSave.vue";
import validateMixin from "@/mixins/validateMixin";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "RegionTeamForm",
  components: {BaseLinkSave, BaseButtonSend, Separator, RegionTeamMemberCounter, BaseUpload},
  mixins: [validateMixin],
  data() {
    return {
      docs: [
        {
          title: 'Скан-копия нормативно-правового акта субъекта Российской Федерации, которым утвержден состав региональной управленческой команды',
          key: 'npa_file'
        },
        {
          title: 'Меморандум о сотрудничестве по обучению региональной управленческой команды',
          key: 'memorandum_file'
        },
        {
          title: 'Список участников региональной управленческой команды субъекта Российской Федерации по форме №3 к Меморандуму',
          key: 'request_file'
        }
      ],
      form: {
        npa_file: null,
        memorandum_file: null,
        request_file: null,
      }
    }
  },
  mounted() {
    this.$on('on-draft', async () => {
      if(await this.submit('draft')) {
        this.$toastr.s('', "Черновик успешно сохранен");
      }
    })
    this.$on('on-submit', () => {
      if(this.submit('send')) {
        this.$toastr.s('', "Сведения успешно отправлены");
      }
    })
  },
  methods: {
    async submit(status) {
      return await this.putRegionteam({
        id: this.regionteam.id,
        requestFile: this.regionteam.request_file ? this.regionteam.request_file.id : this.regionteam.request_file,
        memorandumFile: this.regionteam.memorandum_file ? this.regionteam.memorandum_file.id : this.regionteam.request_file,
        npaFile: this.regionteam.npa_file ? this.regionteam.npa_file.id : this.regionteam.npa_file,
        status: status
      })
    },
    ...mapActions(['putRegionteam']),
    ...mapMutations(['setRegionteam'])
  },
  computed: {
    ...mapState({store: 'regionteam'}),
    regionteam: {
      get () {
        return this.store.regionteam
      },
      set (value) {
        this.setRegionteam(value)
      }
    },
    disabled() {
      return this.errors.items.length > 0 || this.regionteam.region_team_invites.length < 7
    },
  }
}
</script>

<style scoped>

</style>