import ApiService from "../../services/api.service";

export default {
    state: {
        mspForsageOrder: null,
        mspForsageOrderRequest: null,
        mspForsageOrders: []
    },
    actions: {
        async getMspForsageOrders(ctx: any, filters: any) {
            return new Promise((resolve, reject) => {
                ApiService.get('msp-forsage/orders', {
                    params: filters
                })
                    .then(res => {
                        ctx.commit('setMspForsageOrders', res.data)
                        resolve(res.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        async updateMspForsageOrder(ctx: any, order: any) {
            return new Promise((resolve, reject) => {
                ApiService.put('msp-forsage/order/' + order.id, order)
                    .then(res => {
                        ctx.commit('setMspForsageOrder', res.data)
                        resolve(res.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
    mutations: {
        setMspForsageOrder(state: any, order: any) {
            state.mspForsageOrder = order
        },
        setMspForsageOrderRequest(state: any, order: any) {
            state.mspForsageOrderRequest = order
        },
        setMspForsageOrders(state: any, orders: any) {
            state.mspForsageOrders = orders
        },
    },
    getters: {
        mspForsageOrder(state: any) {
            return state.mspForsageOrder
        },
        mspForsageOrderRequest(state: any) {
            return state.mspForsageOrderRequest
        },
        mspForsageOrders(state: any) {
            return state.mspForsageOrders
        }
    }
}
