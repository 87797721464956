<template>
  <div class="mb-5">
    <div class="d-none d-lg-block d-md-block d-sm-block">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex">
          <router-link :to="prev" class="btn btn-primary btn-with-icon">
            <img class="icon rotate-180" src="/pic/icon-chevron-right.svg" />
            Назад
          </router-link>
          <router-link :to="next" class="btn btn-primary btn-with-icon ml-24">
            Продолжить
            <img class="icon left" src="/pic/icon-chevron-right.svg" />
          </router-link>
        </div>
        <div @click="draft()">
          <span class="form-save" style="text-decoration: underline; cursor: pointer;">Сохранить</span>&nbsp;
          <img src="/pic/icon-save.svg" />
        </div>
      </div>
    </div>
    <div class="d-block d-sm-none btn-footer">
      <router-link :to="prev" class="btn btn-primary btn-with-icon d-flex w-100">
        <img class="icon rotate-180" src="/pic/icon-chevron-right.svg" />
        Назад
      </router-link>
      <router-link :to="next" class="btn btn-primary btn-with-icon d-flex w-100">
        Продолжить
        <img class="icon left" src="/pic/icon-chevron-right.svg" />
      </router-link>
      <div @click="draft()" class="mt-5 mb-5 text-right">
        <span class="form-save" style="text-decoration: underline; cursor: pointer;">Сохранить</span>&nbsp;
        <img src="/pic/icon-save.svg" />
      </div>
    </div>
  </div>
</template>

<script>

import {eventBus} from "@/main";

export default {
  name: "ButtonsFooter",
  methods: {
    draft() {
      eventBus.$emit( this.type + '.' + 'draft')
    }
  },
  props: {
    next: {
      type: String,
      default() {
        return '/lk/company'
      }
    },
    prev: {
      type: String,
      default() {
        return '/lk/subscribes'
      }
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>