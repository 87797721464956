<template>
  <span>Приглашения сотрудников ({{ regionteam.region_team_invites.length }} из 10)</span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MemberCounter",
  computed: {
    ...mapGetters(['regionteam'])
  }
}
</script>

<style scoped>

</style>