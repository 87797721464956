<template>
  <div class="section__lk-order-form container-shadow fill-white">
    <div class="justify-content-between d-none d-md-flex d-lg-flex">
      <h2>Ваш отзыв о пройденном обучении</h2>
      <router-link to="/lk" class="test__footer-link">
        <span class="mr-2">Вернуться в личный кабинет</span>
        <img src="/pic/icon-arrow-circle-right.svg" />
      </router-link>
    </div>
    <div class="section__lk-orders">
      <review :event="$route.params.id" v-model="rate" />
    </div>
  </div>
</template>

<script>
import Review from "@/components/Review.vue";
export default {
  name: "ReviewSeminar",
  components: {
    Review
  },
  data() {
    return {
      rate: 5
    }
  }
}
</script>

<style scoped>

</style>