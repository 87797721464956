import ApiService from "../../services/api.service";
export default {
    state: {
        enrollGroups: []
    },
    actions: {
        async getEnrollGroups(ctx: any) {
            const res = await ApiService.get('acceleratorenrollgroup/member')
            ctx.commit('setEnrollGroups', res.data)
        }
    },
    mutations: {
        setEnrollGroups(state: any, data: any) {
            state.enrollGroups = data
        }
    },
    getters: {
        enrollGroups(state: any) {
            return state.enrollGroups
        }
    }
}
