<template>
  <div :class="'container-grid grid-md-1 grid-' + grid" v-if="documents.length">
    <document v-for="(document,index) in documents" :key="index" :title="document.title" :link="document.link" />
  </div>
</template>

<script>
import Document from "@/components/Document.vue";

export default {
  name: "DocumentList",
  components: {Document},
  props: {
    documents: {
      type: Array,
      default() {
        return []
      }
    },
    grid: {
      type: Number,
      default: 3
    }
  }
}
</script>

<style scoped>

</style>