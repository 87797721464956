import ApiService from "../../services/api.service";
export default {
    state: {
        countries: []
    },
    actions: {
        async getCountries(ctx: any) {
            const res = await ApiService.get('countries', {
                params: {
                    'filters[all]': true
                }
            })
            ctx.commit('setCountries', res.data)
        }
    },
    mutations: {
        setCountries(state: any, notes: any) {
            state.countries = notes
        }
    },
    getters: {
        countries(state: any) {
            return state.countries
        }
    }
}
