export default {
    state: {
        menu: {
            msp: [
                {name: 'Данные компании', link: '/lk/msp/company', route: 'MspCompany', success: false},
                {name: 'Регистрация сотрудников', link: '/lk/msp/members', route: 'MspMember', success: false},
                {name: 'Профиль компании', link: '/lk/msp/profile', route: 'MspProfile', success: false},
                {name: 'Документы компании', link: '/lk/msp/docs', route: 'MspDocs', success: false},
            ],
            forsage:  [
                {name: 'Данные компании', link: '/lk/forsage/company', route: 'ForsageCompany', success: false},
                {name: 'Регистрация сотрудников', link: '/lk/forsage/members', route: 'ForsageMember', success: false},
                {name: 'Профиль компании', link: '/lk/forsage/profile', route: 'ForsageProfile', success: false},
                {name: 'Документы компании', link: '/lk/forsage/docs', route: 'ForsageDocs', success: false},
            ]
        }
    },
    actions: {
        async updateMspMenu(ctx: any, data: any) {
            ctx.commit('setMspMenu', data)
        }
    },
    mutations: {
        setMspMenu(state: any, item: any) {
            state.menu[item.program] = state.menu[item.program].map((menuItem: any) => {
                if(menuItem.route === item.route) {
                    menuItem.success = item.success
                }
                return menuItem;
            })
        }
    },
    getters: {
        mspMenu(state: any) {
            return state.menu
        },
        mspMenuSuccess(state: any) {
            return state.menu.msp.filter((item: any) => item.success).length === 4
        },
        forsageMenuSuccess(state: any) {
            return state.menu.forsage.filter((item: any) => item.success).length === 4
        }
    }
}
