<template>
  <div class="pb-5">
    <div class="section__lk-title">
      <member-counter />
    </div>
    <div class="section__lk-notes">
      <member-notes title="Необходимо пригласить от 7 до 10 сотрудников" />
    </div>
    <region-team-member-list />
    <template v-if="regionteam.region_team_invites.length < 10">
      <separator/>
      <h3>Личные данные</h3>
      <form @submit.prevent="validate()">
        <base-input :validate="'required|email'" name="email" v-model="model.email" label="Email" />
        <base-input :validate="'required'" name="lastname" v-model="model.lastname" label="Фамилия" />
        <base-input :validate="'required'" name="firstname" v-model="model.firstname" label="Имя" />
        <base-input :validate="'required'" name="middlename" v-model="model.middlename" label="Отчество" />
        <separator/>
        <company-settings-form v-model="model.organization"/>
        <separator />
        <base-button-send text="Пригласить" />
      </form>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import validateMixin from "@/mixins/validateMixin";
import Separator from "@/components/Separator.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import ApiService from "../../../../services/api.service";
import BaseButtonSend from "@/components/form/BaseButtonSend.vue";
import MemberNotes from "@/components/profile/regionteam/MemberNotes.vue";
import CompanySettingsForm from "@/components/form/CompanySettingsForm.vue";
import MemberCounter from "@/components/profile/regionteam/MemberCounter.vue";
import RegionTeamMemberList from "@/components/profile/regionteam/RegionTeamMemberList.vue";

export default {
  name: "RegionTeamMembers",
  components: {
    RegionTeamMemberList,
    CompanySettingsForm, BaseButtonSend, Separator, BaseInput, MemberNotes, MemberCounter},
  data() {
    return {
      model: {
        organization: {}
      }
    }
  },
  methods: {
    ...mapMutations(['setRegionteam'])
  },
  mounted() {
    this.$on('on-submit', async () => {
      let res = await  ApiService.postJson('regionteaminvites', {
        ...this.model,
        organization: this.model.organization ? this.model.organization.id : null
      })
      if(res.status < 300) {
        this.$toastr.s('', "Сотрудник успешно приглашен");
        this.model = {
          organization: {}
        }
        this.setRegionteam(res.data)
      } else {
        this.$toastr.s('Ошибка!', "Не удалось отправить приглашение");
      }
    })
  },
  mixins: [validateMixin],
  computed: {
    ...mapGetters(['regionteam'])
  }
}
</script>

<style scoped>

</style>