<template>
  <button type="submit" :disabled="disabled" class="btn btn-primary btn-with-icon btn-md">
    {{text}}
    <img class="icon left" src="/pic/icon-chevron-right.svg" />
  </button>
</template>

<script>
export default {
  name: "BaseButtonSend",
  props: {
    text: {
      type: String,
      default() {
        return 'Отправить'
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>