<template>
  <div class="checkbox-wrapper">
    <div class="checkbox">
      <input :disabled="disabled" type="checkbox" v-model="model" :id="id"/>
      <label :for="id" class="checkbox">{{label}}</label>
    </div>
  </div>
</template>

<script>
import vmMixin from "@/mixins/vmMixin";

export default {
  name: "BaseCheckbox",
  mixins: [vmMixin],
  mounted() {
    this.model = false
  },
  props: {
    label: {
      type: String,
      default() {
        return '';
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      id: 'checkbox_' +this.randomString(6)
    }
  }
}
</script>

<style scoped>

</style>