<template>
  <aside class="aside">
    <div class="flex-grow-1 d-block d-md-none">
      <a class="header__logo" href="/">
        <svg width="297" height="96" viewBox="0 0 297 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M46.2711 51.1739C42.6599 51.1674 39.0554 50.8675 35.4933 50.2773C37.0039 52.1599 38.6317 53.9464 40.3674 55.6262C43.6588 54.8506 46.714 53.2986 49.2747 51.1012C48.3426 51.1496 47.3434 51.1799 46.2772 51.1799" fill="#3777BC"></path>
          <path d="M29.017 55.2992C31.0661 55.9242 33.1977 56.2407 35.341 56.2381C36.2374 56.2392 37.1328 56.1825 38.0218 56.0685C36.245 54.2535 34.5749 52.3382 33.0198 50.332C31.8082 52.0737 30.4811 53.7331 29.0474 55.2992" fill="#002E5E"></path>
          <path d="M23.4727 26.8407C25.3612 34.1066 28.7287 40.9102 33.367 46.8306C36.7042 40.762 38.86 34.1219 39.7215 27.2587C35.703 26.538 31.6144 26.2799 27.5364 26.4894C26.1778 26.556 24.8131 26.6711 23.4605 26.8286L23.4727 26.8407ZM33.0258 42.663C30.1013 38.3854 27.8305 33.7005 26.2875 28.761C26.7444 28.7246 27.2013 28.7004 27.6522 28.6762C30.8543 28.5148 34.0646 28.6486 37.2419 29.076C36.4658 33.7748 35.0474 38.3462 33.0258 42.663Z" fill="#3777BC"></path>
          <path d="M15.3696 42.748C16.4305 45.5028 18.0692 48.0014 20.1767 50.0777C22.1524 51.9511 24.4515 53.4552 26.9638 54.5179C28.4638 52.889 29.8558 51.1651 31.1311 49.3568C25.5598 47.9934 20.2404 45.763 15.3696 42.748ZM26.4154 51.8768C24.696 50.9763 23.1093 49.8451 21.6998 48.5148C23.5867 49.3277 25.5169 50.0375 27.4816 50.641C27.1283 51.0772 26.781 51.4951 26.4337 51.901" fill="#ED1D24"></path>
          <path d="M22.0349 26.5684C19.7258 26.8228 17.5446 27.2044 15.3696 27.586C13.9113 31.4765 13.6213 35.7044 14.5349 39.7556C19.4792 43.1293 24.9601 45.6502 30.7472 47.2125C26.4768 40.9834 23.5136 33.9621 22.0349 26.5684ZM17.0207 29.5063C18.1052 29.3185 19.2018 29.1368 20.3229 28.9793C21.5071 33.9607 23.3509 38.7639 25.8062 43.263C22.5472 41.9858 19.4312 40.3738 16.5089 38.4533C15.9709 35.4723 16.1462 32.4073 17.0207 29.5063Z" fill="#002E5E"></path>
          <path d="M34.4027 48.0421C38.3249 48.8022 42.3116 49.1856 46.3076 49.187C48.1354 49.187 49.7438 49.1083 51.1146 48.9932C51.1322 48.9861 51.1519 48.9861 51.1694 48.9932C51.2121 48.9932 51.2608 49.0356 51.3035 49.084C51.8783 48.415 52.4133 47.7131 52.9058 46.9821C50.5345 40.2103 46.8034 33.9884 41.9392 28.6943C40.6784 35.5741 38.1125 42.1524 34.3784 48.0785L34.4027 48.0421ZM50.4749 46.6429C50.414 46.7277 50.347 46.8064 50.2799 46.8912C48.9883 46.9821 47.654 47.0245 46.3198 47.0245C43.5023 47.02 40.6886 46.8176 37.8999 46.4187C40.1685 42.3234 41.9202 37.9654 43.1151 33.4435C46.2232 37.4495 48.7045 41.8995 50.4749 46.6429Z" fill="#ED1D24"></path>
          <path d="M28.0787 24.2302C31.5781 24.2213 35.0698 24.5561 38.5031 25.2297C34.5659 21.6522 30.1694 18.6091 25.4284 16.1797C24.6608 16.6946 23.7652 17.2034 22.9305 17.7728C22.9732 19.9935 23.1768 22.2082 23.5397 24.3998C25.0568 24.2907 26.586 24.2302 28.0482 24.2302H28.0787ZM25.4894 18.7057C27.4166 19.746 29.2839 20.8926 31.0823 22.1403C30.0771 22.0858 29.0657 22.0616 28.036 22.0616C27.1648 22.0616 26.2875 22.0616 25.398 22.1161C25.27 21.1045 25.1786 20.0444 25.1238 18.9298L25.4772 18.7117" fill="#ED1D24"></path>
          <path d="M21.0113 19.4326C20.7232 19.6055 20.4625 19.8199 20.2375 20.0687C18.6426 21.6459 17.3228 23.4761 16.3322 25.4841C18.0625 25.1631 19.8537 24.9086 21.584 24.7209C21.2966 22.9698 21.1055 21.2044 21.0113 19.4326Z" fill="#3777BC"></path>
          <path d="M56.4456 32.1056C55.7852 27.5713 53.6722 23.3696 50.4201 20.1237C48.3306 18.0357 45.8203 16.411 43.0542 15.3564C43.1802 18.9789 42.964 22.6051 42.4084 26.1873C47.3387 27.4901 52.0571 29.485 56.4213 32.1116L56.4456 32.1056ZM45.2658 18.8456C46.5773 19.642 47.7882 20.5918 48.8726 21.6745C50.6421 23.4194 52.0479 25.4944 53.0094 27.7805C50.3793 26.5086 47.6513 25.4472 44.8515 24.6063C45.1135 22.3832 45.2353 20.4387 45.2841 18.8456" fill="#3777BC"></path>
          <path d="M56.5736 34.5278C52.9356 32.1606 48.9898 30.2979 44.8454 28.9912C48.9264 33.668 52.118 39.0437 54.2645 44.8559C55.9012 41.674 56.6959 38.13 56.5736 34.5581V34.5278ZM54.0452 38.8529C53.2172 37.191 52.3021 35.5734 51.3035 34.0069C52.3453 34.5278 53.375 35.1336 54.3924 35.7393C54.3652 36.7871 54.249 37.8306 54.0452 38.859" fill="#002E5E"></path>
          <path d="M41.001 14.7202C36.6747 13.5017 32.0686 13.7013 27.8654 15.2897C32.5234 17.7706 36.8145 20.8792 40.6171 24.5274C41.0017 21.2733 41.1301 17.9942 41.001 14.7202ZM38.8016 19.9903C37.1468 18.6296 35.4202 17.3577 33.629 16.1801C34.1834 16.1317 34.7378 16.1074 35.2983 16.1074C36.4885 16.1077 37.6758 16.2213 38.8442 16.4466C38.8442 17.5431 38.8442 18.7182 38.8016 19.9903Z" fill="#002E5E"></path>
          <path d="M58.2734 67.1055L43.4989 74.4533L36.1147 67.1115L58.2734 67.1055Z" fill="#3777BC"></path>
          <path d="M58.2734 67.1055L50.8892 81.8011L43.499 74.4533L58.2734 67.1055Z" fill="#ED1D24"></path>
          <path d="M65.6637 67.1357H68.6003C70.3428 67.1357 71.4394 68.1231 71.4394 69.6375C71.4394 71.3033 70.0991 72.1696 68.4541 72.1696H66.9736V74.5563H65.6637V67.1357ZM68.4967 70.9944C69.4898 70.9944 70.1052 70.4432 70.1052 69.6496C70.1052 68.8561 69.4959 68.323 68.4967 68.323H66.9736V70.9944H68.4967Z" fill="#002E5E"></path>
          <path d="M72.9626 70.8735C72.9541 70.1033 73.1763 69.348 73.6008 68.7036C74.0252 68.0592 74.6329 67.555 75.3464 67.2549C76.06 66.9549 76.8471 66.8726 77.6078 67.0186C78.3685 67.1646 79.0683 67.5322 79.6183 68.0748C80.1683 68.6173 80.5435 69.3102 80.6963 70.0653C80.8491 70.8205 80.7725 71.6037 80.4764 72.3155C80.1802 73.0273 79.6778 73.6354 79.0331 74.0625C78.3884 74.4896 77.6304 74.7164 76.8557 74.714C76.3446 74.7229 75.8368 74.6299 75.3624 74.4405C74.888 74.251 74.4566 73.9689 74.0937 73.6109C73.7308 73.2529 73.4437 72.8262 73.2494 72.3561C73.0551 71.8859 72.9576 71.3818 72.9626 70.8735ZM79.3902 70.8735C79.4033 70.5337 79.3475 70.1947 79.2263 69.8767C79.105 69.5587 78.9207 69.2681 78.6842 69.0223C78.4478 68.7765 78.1641 68.5805 77.85 68.4458C77.5359 68.3112 77.1978 68.2407 76.8557 68.2385C76.5134 68.238 76.1746 68.3069 75.86 68.4408C75.5453 68.5747 75.2613 68.7709 75.0254 69.0174C74.7894 69.2639 74.6064 69.5556 74.4874 69.8747C74.3684 70.1938 74.316 70.5336 74.3334 70.8735C74.3203 71.2137 74.3761 71.5529 74.4973 71.8713C74.6185 72.1896 74.8027 72.4805 75.0391 72.7268C75.2754 72.9731 75.559 73.1697 75.8732 73.305C76.1873 73.4403 76.5256 73.5116 76.8679 73.5146C77.2105 73.5142 77.5494 73.4446 77.8641 73.31C78.1788 73.1755 78.4627 72.9787 78.6986 72.7317C78.9345 72.4847 79.1174 72.1927 79.2363 71.8732C79.3552 71.5538 79.4076 71.2137 79.3902 70.8735Z" fill="#002E5E"></path>
          <path d="M82.534 70.8732C82.5232 70.3672 82.6154 69.8643 82.805 69.3946C82.9946 68.925 83.2778 68.4983 83.6374 68.1401C83.997 67.782 84.4258 67.4998 84.8978 67.3106C85.3699 67.1213 85.8756 67.0288 86.3845 67.0387C86.9246 67.019 87.4629 67.1111 87.9653 67.3093C88.4677 67.5074 88.9232 67.8072 89.3028 68.1897L88.462 69.1589C88.1922 68.8757 87.8686 68.6485 87.5099 68.4904C87.1512 68.3324 86.7646 68.2466 86.3723 68.2381C85.7066 68.2931 85.086 68.5949 84.6337 69.0835C84.1813 69.5722 83.9303 70.2121 83.9303 70.8762C83.9303 71.5403 84.1813 72.1802 84.6337 72.6689C85.086 73.1575 85.7066 73.4593 86.3723 73.5143C86.7773 73.5098 87.177 73.4219 87.5461 73.2561C87.9152 73.0903 88.2458 72.8503 88.5169 72.5511L89.3577 73.3992C88.981 73.8307 88.5118 74.1728 87.9846 74.4003C87.4574 74.6278 86.8857 74.7349 86.3114 74.7137C85.8076 74.7154 85.3086 74.6167 84.8438 74.4233C84.3791 74.2298 83.9582 73.9456 83.606 73.5875C83.2537 73.2294 82.9774 72.8046 82.7932 72.3384C82.6091 71.8721 82.5209 71.3739 82.534 70.8732Z" fill="#002E5E"></path>
          <path d="M90.9112 70.873C90.9005 70.367 90.9927 69.8641 91.1823 69.3945C91.3719 68.9248 91.655 68.4981 92.0147 68.14C92.3743 67.7818 92.803 67.4996 93.2751 67.3104C93.7472 67.1211 94.2528 67.0287 94.7617 67.0386C95.3018 67.0196 95.8399 67.112 96.3421 67.3101C96.8444 67.5082 97.3 67.8076 97.6801 68.1895L96.8393 69.1587C96.5695 68.8755 96.2459 68.6483 95.8872 68.4902C95.5285 68.3322 95.1418 68.2464 94.7496 68.238C94.0839 68.2929 93.4633 68.5947 93.011 69.0834C92.5586 69.572 92.3075 70.2119 92.3075 70.876C92.3075 71.5401 92.5586 72.18 93.011 72.6687C93.4633 73.1573 94.0839 73.4591 94.7496 73.5141C95.1538 73.5036 95.5515 73.41 95.9175 73.239C96.2834 73.068 96.6098 72.8234 96.8759 72.5206L97.7166 73.3687C97.3394 73.7996 96.8701 74.1412 96.3431 74.3686C95.816 74.5961 95.2445 74.7035 94.6704 74.6832C94.1707 74.6825 93.6762 74.5829 93.2157 74.3902C92.7552 74.1976 92.3378 73.9157 91.9879 73.5611C91.6381 73.2065 91.3627 72.7862 91.1778 72.3247C90.993 71.8631 90.9024 71.3697 90.9112 70.873Z" fill="#002E5E"></path>
          <path d="M99.6236 67.1357H100.909V72.5512L104.687 67.1357H105.911V74.5563H104.62V69.1469L100.86 74.5563H99.6236V67.1357Z" fill="#002E5E"></path>
          <path d="M108.354 67.136H109.67V72.5514L113.448 67.136H114.666V74.5565H113.326V69.1471L109.561 74.5565H108.342L108.354 67.136ZM109.853 65.0704L110.651 64.9189C110.69 65.1247 110.799 65.3106 110.961 65.4444C111.123 65.5781 111.327 65.6514 111.538 65.6514C111.748 65.6514 111.952 65.5781 112.114 65.4444C112.276 65.3106 112.386 65.1247 112.424 64.9189L113.21 65.0704C113.176 65.4833 112.984 65.8674 112.673 66.1435C112.362 66.4196 111.957 66.5667 111.541 66.5545C111.123 66.5656 110.716 66.4186 110.403 66.1431C110.089 65.8676 109.893 65.4843 109.853 65.0704Z" fill="#002E5E"></path>
          <path d="M116.75 70.8732C116.739 70.3672 116.831 69.8643 117.021 69.3946C117.21 68.925 117.494 68.4983 117.853 68.1401C118.213 67.782 118.642 67.4998 119.114 67.3106C119.586 67.1213 120.091 67.0288 120.6 67.0387C121.14 67.019 121.679 67.1111 122.181 67.3093C122.684 67.5074 123.139 67.8072 123.519 68.1897L122.678 69.1589C122.408 68.8757 122.084 68.6485 121.726 68.4904C121.367 68.3324 120.98 68.2466 120.588 68.2381C119.922 68.2931 119.302 68.5949 118.85 69.0835C118.397 69.5722 118.146 70.2121 118.146 70.8762C118.146 71.5403 118.397 72.1802 118.85 72.6689C119.302 73.1575 119.922 73.4593 120.588 73.5143C120.993 73.5098 121.393 73.4219 121.762 73.2561C122.131 73.0903 122.462 72.8503 122.733 72.5511L123.573 73.3992C123.196 73.83 122.727 74.1717 122.2 74.3991C121.673 74.6265 121.101 74.734 120.527 74.7137C120.023 74.7154 119.524 74.6167 119.06 74.4233C118.595 74.2298 118.174 73.9456 117.822 73.5875C117.47 73.2294 117.193 72.8046 117.009 72.3384C116.825 71.8721 116.737 71.3739 116.75 70.8732Z" fill="#002E5E"></path>
          <path d="M125.456 67.1357H126.772V70.8672L130.318 67.1357H131.926L128.825 70.3281L132.066 74.5563H130.482L127.936 71.2125L126.772 72.3998V74.5563H125.456V67.1357Z" fill="#002E5E"></path>
          <path d="M133.699 67.1357H134.991V72.5512L138.762 67.1357H139.987V74.5563H138.695V69.1469L134.936 74.5563H133.699V67.1357Z" fill="#002E5E"></path>
          <path d="M142.43 67.136H143.715V72.5514L147.493 67.136H148.711V74.5565H147.444V69.1471L143.679 74.5565H142.46L142.43 67.136ZM143.929 65.0704L144.733 64.9189C144.767 65.1253 144.875 65.3124 145.038 65.445C145.2 65.5777 145.406 65.647 145.616 65.6398C145.826 65.6444 146.03 65.5742 146.192 65.442C146.354 65.3098 146.463 65.1242 146.5 64.9189L147.286 65.0704C147.251 65.4833 147.059 65.8674 146.749 66.1435C146.438 66.4196 146.033 66.5667 145.616 66.5545C145.198 66.5656 144.792 66.4186 144.478 66.1431C144.165 65.8676 143.969 65.4843 143.929 65.0704Z" fill="#002E5E"></path>
          <path d="M154.249 73.4117L155.09 72.5515C155.369 72.8524 155.707 73.0932 156.083 73.2589C156.459 73.4246 156.866 73.5116 157.277 73.5146C157.877 73.5188 158.457 73.3029 158.907 72.9084C159.356 72.5139 159.644 71.9684 159.714 71.3763H156.364V70.2678H159.702C159.618 69.6867 159.325 69.1557 158.876 68.7736C158.428 68.3914 157.856 68.1841 157.265 68.19C156.477 68.1996 155.722 68.5097 155.157 69.0563L154.408 68.1658C154.779 67.7879 155.224 67.4897 155.715 67.2896C156.207 67.0896 156.734 66.9919 157.265 67.0028C158.263 67.0345 159.21 67.4491 159.907 68.1596C160.604 68.8702 160.997 69.8215 161.004 70.814C161.011 71.8066 160.632 72.7633 159.945 73.4836C159.258 74.2039 158.317 74.6319 157.32 74.6777C156.749 74.7016 156.181 74.5999 155.654 74.3801C155.127 74.1602 154.656 73.8274 154.274 73.4056" fill="#002E5E"></path>
          <path d="M163.206 67.1357H164.509V70.8672L168.061 67.1357H169.67L166.569 70.3281L169.816 74.5563H168.22L165.673 71.2125L164.509 72.3998V74.5563H163.206V67.1357Z" fill="#002E5E"></path>
          <path d="M170.791 70.873C170.78 70.367 170.872 69.8641 171.062 69.3945C171.251 68.9248 171.535 68.4981 171.894 68.14C172.254 67.7818 172.683 67.4996 173.155 67.3104C173.627 67.1211 174.132 67.0287 174.641 67.0386C175.181 67.0196 175.719 67.112 176.222 67.3101C176.724 67.5082 177.179 67.8076 177.56 68.1895L176.719 69.1587C176.449 68.8755 176.125 68.6483 175.767 68.4902C175.408 68.3322 175.021 68.2464 174.629 68.238C173.963 68.2929 173.343 68.5947 172.89 69.0834C172.438 69.572 172.187 70.2119 172.187 70.876C172.187 71.5402 172.438 72.18 172.89 72.6687C173.343 73.1574 173.963 73.4591 174.629 73.5141C175.034 73.5096 175.434 73.4217 175.803 73.2559C176.172 73.0902 176.503 72.8501 176.774 72.5509L177.614 73.399C177.237 73.8299 176.768 74.1715 176.241 74.3989C175.714 74.6264 175.142 74.7338 174.568 74.7135C174.064 74.7153 173.565 74.6165 173.101 74.4231C172.636 74.2296 172.215 73.9454 171.863 73.5873C171.51 73.2292 171.234 72.8044 171.05 72.3382C170.866 71.872 170.778 71.3737 170.791 70.873Z" fill="#002E5E"></path>
          <path d="M179.503 67.1357H185.584V74.5563H184.274V68.3109H180.813V74.5563H179.503V67.1357Z" fill="#002E5E"></path>
          <path d="M187.685 70.8735C187.677 70.1033 187.899 69.348 188.324 68.7036C188.748 68.0592 189.356 67.555 190.069 67.2549C190.783 66.9549 191.57 66.8726 192.331 67.0186C193.091 67.1646 193.791 67.5322 194.341 68.0748C194.891 68.6173 195.266 69.3102 195.419 70.0653C195.572 70.8205 195.495 71.6037 195.199 72.3155C194.903 73.0273 194.401 73.6354 193.756 74.0625C193.111 74.4896 192.353 74.7164 191.579 74.714C191.067 74.7229 190.56 74.6299 190.085 74.4405C189.611 74.251 189.179 73.9689 188.817 73.6109C188.454 73.2529 188.167 72.8262 187.972 72.3561C187.778 71.8859 187.68 71.3818 187.685 70.8735ZM194.113 70.8735C194.126 70.5337 194.07 70.1947 193.949 69.8767C193.828 69.5587 193.644 69.2681 193.407 69.0223C193.171 68.7765 192.887 68.5805 192.573 68.4458C192.259 68.3112 191.921 68.2407 191.579 68.2385C191.237 68.2389 190.899 68.3083 190.585 68.4426C190.271 68.5769 189.988 68.7732 189.752 69.0196C189.517 69.266 189.334 69.5574 189.216 69.8761C189.097 70.1948 189.045 70.5341 189.062 70.8735C189.049 71.2137 189.105 71.5529 189.226 71.8713C189.347 72.1896 189.532 72.4805 189.768 72.7268C190.004 72.9731 190.288 73.1697 190.602 73.305C190.916 73.4403 191.255 73.5116 191.597 73.5146C191.939 73.5134 192.277 73.4432 192.591 73.3083C192.905 73.1733 193.188 72.9764 193.424 72.7295C193.659 72.4826 193.841 72.1908 193.96 71.8718C194.078 71.5528 194.13 71.2132 194.113 70.8735Z" fill="#002E5E"></path>
          <path d="M197.592 67.1357H200.529C202.271 67.1357 203.368 68.1231 203.368 69.6375C203.368 71.3033 202.021 72.1696 200.382 72.1696H198.902V74.5563H197.592V67.1357ZM200.449 70.9944C201.436 70.9944 202.058 70.4432 202.058 69.6496C202.058 68.8561 201.449 68.323 200.449 68.323H198.926V70.9944H200.449Z" fill="#002E5E"></path>
          <path d="M206.84 68.3412H204.477V67.1357H210.526V68.3412H208.163V74.5563H206.84V68.3412Z" fill="#002E5E"></path>
          <path d="M212.427 67.1357H213.737V70.219H217.301V67.1357H218.611V74.5563H217.301V71.4305H213.737V74.5563H212.427V67.1357Z" fill="#002E5E"></path>
          <path d="M221.036 67.1357H222.334V69.8071H224.052C225.727 69.8071 226.861 70.631 226.861 72.1272C226.861 73.6234 225.77 74.5502 224.046 74.5502H221.036V67.1357ZM224.028 73.3871C224.996 73.3871 225.557 72.951 225.557 72.1151C225.557 71.2791 224.996 70.9399 224.003 70.9399H222.334V73.3629L224.028 73.3871ZM227.744 67.1357H229.048V74.5563H227.744V67.1357Z" fill="#002E5E"></path>
          <path d="M231.473 67.136H232.74V72.5514L236.511 67.136H237.73V74.5565H236.444V69.1471L232.74 74.5565H231.521L231.473 67.136ZM232.978 65.0704L233.776 64.9189C233.81 65.1253 233.918 65.3124 234.081 65.445C234.243 65.5777 234.449 65.647 234.659 65.6398C234.869 65.6446 235.074 65.5747 235.238 65.4425C235.401 65.3104 235.511 65.1247 235.549 64.9189L236.335 65.0704C236.3 65.4843 236.107 65.8693 235.795 66.1456C235.483 66.4219 235.077 66.5683 234.659 66.5545C234.242 66.5655 233.836 66.4183 233.523 66.1427C233.211 65.8671 233.016 65.4837 232.978 65.0704Z" fill="#002E5E"></path>
          <path d="M249.683 74.5562H243.914V67.1357H245.224V73.375H248.721V67.1357H250.024V73.375H250.951L250.768 76.1433H249.683V74.5562Z" fill="#002E5E"></path>
          <path d="M252.632 67.1357H258.164V68.2988H253.948V70.2312H257.683V71.3942H253.948V73.3871H258.219V74.5563H252.632V67.1357Z" fill="#002E5E"></path>
          <path d="M260.284 67.1357H261.6V70.219H265.158V67.1357H266.468V74.5563H265.158V71.4305H261.6V74.5563H260.284V67.1357Z" fill="#002E5E"></path>
          <path d="M270.733 68.3412H268.369V67.1357H274.419V68.3412H272.055V74.5563H270.733V68.3412Z" fill="#002E5E"></path>
          <path d="M276.314 67.1357H279.263C280.999 67.1357 282.096 68.1231 282.096 69.6375C282.096 71.3033 280.749 72.1696 279.11 72.1696H277.63V74.5563H276.314V67.1357ZM279.153 70.9944C280.14 70.9944 280.761 70.4432 280.761 69.6496C280.761 68.8561 280.152 68.323 279.153 68.323H277.63V70.9944H279.153Z" fill="#002E5E"></path>
          <path d="M65.4504 27.623H68.8196V38.7992H72.8102V27.623H76.1794V38.7992H80.164V27.623H83.5331V41.8462H65.4504V27.623Z" fill="#002E5E"></path>
          <path d="M86.22 31.1904H89.0896V35.3944H90.1192L93.3544 31.1904H96.9124L92.7573 36.194L97.1439 41.8457H93.5432L90.369 37.6054H89.0896V41.8457H86.22V31.1904Z" fill="#002E5E"></path>
          <path d="M103.383 31.0146C106.941 31.0146 109.475 33.1469 109.475 36.5391C109.475 39.9314 106.916 42.0454 103.383 42.0454C99.849 42.0454 97.3328 39.9132 97.3328 36.5391C97.3328 33.1651 99.9282 31.0146 103.383 31.0146ZM103.383 39.4952C105.119 39.4952 106.204 38.5018 106.204 36.527C106.204 34.5523 105.04 33.5406 103.383 33.5406C101.89 33.5406 100.604 34.5159 100.604 36.527C100.604 38.3443 101.75 39.4952 103.383 39.4952Z" fill="#002E5E"></path>
          <path d="M118.2 33.7164H114.91V34.1465C114.91 39.2227 113.643 42.0213 110.803 42.0213C110.453 42.0215 110.102 41.9951 109.755 41.9425V39.2045C111.248 39.3438 112.071 38.7381 112.071 33.6134V31.2812H121.246V41.8456H118.2V33.7164Z" fill="#002E5E"></path>
          <path d="M130.891 40.5856H130.787C130.416 41.0617 129.933 41.4403 129.381 41.6884C128.829 41.9365 128.224 42.0466 127.619 42.0091C124.963 42.0091 122.745 40.1191 122.745 36.5573C122.745 33.2862 125.091 31.0328 128.058 31.0328C128.563 30.9921 129.071 31.0615 129.547 31.2362C130.023 31.4109 130.454 31.6868 130.811 32.0444H130.915L130.811 31.2569H133.858V41.8455H130.811L130.891 40.5856ZM130.787 38.2655V34.528C130.541 34.2381 130.229 34.0098 129.878 33.8614C129.527 33.713 129.145 33.6488 128.764 33.6739C128.387 33.6526 128.01 33.7121 127.657 33.8485C127.305 33.9848 126.987 34.1948 126.723 34.4642C126.46 34.7335 126.258 35.0561 126.131 35.4099C126.003 35.7637 125.954 36.1405 125.986 36.5149C125.986 38.2837 127.113 39.3377 128.667 39.3377C129.085 39.3514 129.5 39.2577 129.871 39.0656C130.243 38.8736 130.558 38.5897 130.787 38.2413" fill="#002E5E"></path>
          <path d="M144.075 35.443H148.303C148.059 34.0437 146.707 33.4924 145.403 33.4924C144.801 33.4874 144.205 33.604 143.65 33.8352C143.095 34.0663 142.593 34.4072 142.174 34.8372L140.663 33.02C141.317 32.357 142.104 31.8389 142.974 31.4996C143.843 31.1603 144.775 31.0075 145.708 31.0512C149.18 31.0512 151.453 33.1653 151.453 36.5333C151.453 39.6469 149.552 41.9851 145.708 41.9851C144.735 42.0338 143.765 41.8654 142.866 41.4923C141.968 41.1191 141.165 40.5506 140.517 39.8286L142.211 38.1628C142.615 38.6165 143.114 38.9768 143.672 39.2185C144.231 39.4602 144.836 39.5775 145.446 39.5621C147.054 39.5621 148.181 38.8291 148.364 37.4722H144.099L144.075 35.443Z" fill="#002E5E"></path>
          <path d="M153.25 31.1904H156.12V35.3944H157.156L160.391 31.1904H163.949L159.794 36.194L164.174 41.8457H160.58L157.405 37.6054H156.12V41.8457H153.25V31.1904Z" fill="#002E5E"></path>
          <path d="M174.958 40.2409C174.366 40.8572 173.646 41.3387 172.849 41.6524C172.052 41.9661 171.196 42.1045 170.34 42.0581C167.068 42.0581 164.369 40.0289 164.369 36.5518C164.369 33.2202 166.861 31.0273 170.194 31.0273C172.686 31.0273 174.038 31.8391 174.794 32.5902L173.38 34.5831C172.558 33.9811 171.562 33.6601 170.541 33.6684C170.151 33.6342 169.759 33.6852 169.392 33.818C169.024 33.9508 168.69 34.1621 168.414 34.437C168.137 34.7119 167.925 35.0437 167.791 35.4091C167.658 35.7745 167.606 36.1646 167.641 36.5518C167.632 36.9446 167.706 37.3348 167.858 37.6974C168.01 38.0599 168.237 38.3867 168.524 38.6567C168.811 38.9267 169.152 39.1338 169.525 39.2648C169.897 39.3958 170.293 39.4476 170.687 39.417C171.735 39.4467 172.754 39.0685 173.526 38.363L174.958 40.2409Z" fill="#002E5E"></path>
          <path d="M183.908 33.7164H179.497V41.8456H176.426V31.2812H186.973V41.8456H183.908V33.7164Z" fill="#002E5E"></path>
          <path d="M194.899 31.0146C198.457 31.0146 200.992 33.1469 200.992 36.5391C200.992 39.9314 198.439 42.0454 194.899 42.0454C191.451 42.0454 188.855 39.9132 188.855 36.5391C188.855 33.1651 191.451 31.0146 194.899 31.0146ZM194.899 39.4952C196.642 39.4952 197.72 38.5018 197.72 36.527C197.72 34.5523 196.556 33.5406 194.899 33.5406C193.406 33.5406 192.121 34.5159 192.121 36.527C192.121 38.3443 193.266 39.4952 194.899 39.4952Z" fill="#002E5E"></path>
          <path d="M202.886 31.2809H205.933V31.705C205.937 31.9779 205.908 32.2504 205.847 32.5167H205.933C206.292 32.0361 206.766 31.6514 207.311 31.3967C207.856 31.142 208.457 31.0252 209.058 31.0568C212.348 31.0568 214.042 33.2678 214.042 36.3996C214.042 39.5313 212.104 42.0089 208.589 42.0089C207.654 42.0709 206.724 41.8354 205.933 41.3365V45.9463H202.886V31.2809ZM205.933 34.7519V38.7136C206.519 39.2253 207.28 39.4942 208.059 39.4647C209.71 39.4647 210.776 38.5924 210.776 36.4359C210.776 34.5823 209.771 33.6918 208.284 33.6918C207.833 33.6473 207.378 33.7218 206.965 33.9079C206.552 34.094 206.196 34.3852 205.933 34.7519Z" fill="#002E5E"></path>
          <path d="M214.377 31.2812H224.801V33.9223H221.103V41.8456H218.039V33.9223H214.377V31.2812Z" fill="#002E5E"></path>
          <path d="M232.935 40.5858H232.831C232.46 41.0619 231.977 41.4405 231.425 41.6886C230.873 41.9367 230.268 42.0468 229.663 42.0093C227.007 42.0093 224.759 40.1194 224.759 36.5575C224.759 33.2865 227.11 31.0331 230.071 31.0331C230.578 30.9918 231.087 31.061 231.564 31.2357C232.04 31.4105 232.473 31.6865 232.831 32.0447H232.935L232.831 31.2572H235.878V41.8458H232.831L232.935 40.5858ZM232.831 38.2658V34.5283C232.585 34.2383 232.274 34.01 231.922 33.8616C231.571 33.7133 231.189 33.6491 230.809 33.6741C230.431 33.6528 230.054 33.7124 229.702 33.8487C229.35 33.9851 229.031 34.195 228.768 34.4644C228.504 34.7338 228.302 35.0563 228.175 35.4101C228.048 35.7639 227.998 36.1408 228.03 36.5151C228.03 38.2839 229.158 39.338 230.705 39.338C231.124 39.3527 231.54 39.2595 231.913 39.0674C232.286 38.8753 232.602 38.5908 232.831 38.2415" fill="#002E5E"></path>
          <path d="M242.902 41.8335V27.6225C243.7 27.6225 246.168 27.5801 247.271 27.5801C251.621 27.5801 253.046 29.8335 253.046 32.2928C253.046 34.7522 251.066 37.0601 247.758 37.0601C247.227 37.0665 246.697 37.0463 246.168 36.9996V41.8456L242.902 41.8335ZM246.144 30.6694V33.9223C246.639 33.9848 247.137 34.0171 247.636 34.0193C248.495 34.0193 249.555 33.6558 249.555 32.2747C249.555 31.0208 248.739 30.6331 247.575 30.6331C247.143 30.6331 246.82 30.6331 246.144 30.6755" fill="#002E5E"></path>
          <path d="M257.189 33.0618H262.807C262.197 31.3354 260.516 30.4207 258.353 30.4207C257.245 30.4357 256.171 30.7982 255.282 31.4565L254.033 29.0335C255.501 27.9001 257.325 27.3187 259.182 27.3919C263.325 27.3919 266.389 30.3116 266.389 34.7397C266.389 39.3071 263.428 42.0875 258.755 42.0875C256.69 42.0875 254.789 41.3969 253.936 40.6034L255.282 37.8533C256.238 38.6154 257.427 39.03 258.652 39.0285C260.546 39.0285 262.362 38.1986 262.916 36.1087H257.177L257.189 33.0618Z" fill="#002E5E"></path>
          <path d="M269.009 27.623H272.275V38.7871H277.179V27.623H280.445V38.7871H282.382V44.2692H279.318V41.8341H269.009V27.623Z" fill="#002E5E"></path>
        </svg>
      </a>
    </div>
    <nav class="aside__hamburger">
      <ul>
        <li v-show="hash !== '#footer'">
          <a href="#" :disabled="openSearch || hash === '#footer'" :style="{cursor: openSearch ? 'unset' : 'pointer', opacity: openSearch ? 0 : 9}" class="toggle-mainmenu" @click="toggle()">
            <svg class="toggle-mainmenu__close" width="20" height="12" viewBox="0 0 20 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
                  fill="#002E5E"/>
            </svg>
            <svg class="toggle-mainmenu__show" width="18" height="18" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10.41 9.00019L16.71 2.71019C16.8983 2.52188 17.0041 2.26649 17.0041 2.00019C17.0041 1.73388 16.8983 1.47849 16.71 1.29019C16.5217 1.10188 16.2663 0.996094 16 0.996094C15.7337 0.996094 15.4783 1.10188 15.29 1.29019L9 7.59019L2.71 1.29019C2.5217 1.10188 2.2663 0.996094 2 0.996094C1.7337 0.996094 1.4783 1.10188 1.29 1.29019C1.1017 1.47849 0.995909 1.73388 0.995908 2.00019C0.995908 2.26649 1.1017 2.52188 1.29 2.71019L7.59 9.00019L1.29 15.2902C1.19627 15.3831 1.12188 15.4937 1.07111 15.6156C1.02034 15.7375 0.994202 15.8682 0.994202 16.0002C0.994202 16.1322 1.02034 16.2629 1.07111 16.3848C1.12188 16.5066 1.19627 16.6172 1.29 16.7102C1.38296 16.8039 1.49356 16.8783 1.61542 16.9291C1.73728 16.9798 1.86799 17.006 2 17.006C2.13201 17.006 2.26272 16.9798 2.38458 16.9291C2.50644 16.8783 2.61704 16.8039 2.71 16.7102L9 10.4102L15.29 16.7102C15.383 16.8039 15.4936 16.8783 15.6154 16.9291C15.7373 16.9798 15.868 17.006 16 17.006C16.132 17.006 16.2627 16.9798 16.3846 16.9291C16.5064 16.8783 16.617 16.8039 16.71 16.7102C16.8037 16.6172 16.8781 16.5066 16.9289 16.3848C16.9797 16.2629 17.0058 16.1322 17.0058 16.0002C17.0058 15.8682 16.9797 15.7375 16.9289 15.6156C16.8781 15.4937 16.8037 15.3831 16.71 15.2902L10.41 9.00019Z"
                  fill="#002E5E"/>
            </svg>
          </a>
        </li>
      </ul>
    </nav>

    <nav class="aside__tools">
      <ul>
        <li class="aside__tools-vk">
          <div class="btn-socials">
            <a class="container-shadow" href="http://vk.com/exportedu">
              <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M13.0729 0H6.9375C1.33331 0 0 1.33331 0 6.92706V13.0625C0 18.6666 1.32294 20 6.92706 20H13.0625C18.6667 20 20 18.6771 20 13.0729V6.9375C20 1.33331 18.6771 0 13.0729 0ZM16.1458 14.2708H14.6875C14.1354 14.2708 13.9688 13.8229 12.9792 12.8333C12.1146 12 11.75 11.8958 11.5312 11.8958C11.2292 11.8958 11.1458 11.9791 11.1458 12.3958V13.7083C11.1458 14.0625 11.0312 14.2708 10.1042 14.2708C9.20458 14.2104 8.33219 13.9371 7.55888 13.4735C6.78557 13.0098 6.13346 12.3691 5.65625 11.6041C4.52336 10.194 3.73511 8.53906 3.35419 6.77081C3.35419 6.55206 3.4375 6.35413 3.85419 6.35413H5.3125C5.6875 6.35413 5.82294 6.52081 5.96875 6.90625C6.67706 8.98956 7.88544 10.8021 8.375 10.8021C8.5625 10.8021 8.64581 10.7188 8.64581 10.25V8.10413C8.58331 7.125 8.0625 7.04163 8.0625 6.6875C8.06936 6.59407 8.11236 6.50699 8.18237 6.44475C8.25239 6.3825 8.34391 6.35 8.4375 6.35413H10.7292C11.0417 6.35413 11.1458 6.51038 11.1458 6.88538V9.78125C11.1458 10.0938 11.2812 10.1979 11.375 10.1979C11.5625 10.1979 11.7083 10.0938 12.0521 9.75C12.7907 8.84918 13.3943 7.84559 13.8438 6.77081C13.8896 6.64149 13.9766 6.53074 14.0913 6.4555C14.2061 6.38025 14.3423 6.34465 14.4792 6.35413H15.9375C16.375 6.35413 16.4688 6.57288 16.375 6.88538C15.8444 8.07369 15.1878 9.20168 14.4167 10.25C14.2604 10.4896 14.1979 10.6146 14.4167 10.8958C14.5625 11.1146 15.0729 11.5416 15.4167 11.9479C15.9167 12.4466 16.3318 13.0237 16.6458 13.6562C16.7708 14.0625 16.5625 14.2708 16.1458 14.2708Z' fill='#002E5E'/>
              </svg>
            </a>
          </div>
        </li>
        <li v-show="hash !== '#footer'" class="aside__tools-search">
          <a href="#" :style="{cursor: openMenu ? 'unset' : 'pointer', opacity: openMenu ? 0 : 9}" :disabled="openMenu" @click="toggleSearch()" class="toggle-search">
            <svg class="toggle-search__close" width="21" height="21" viewBox="0 0 21 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M20.71 19.2904L17 15.6104C18.4401 13.8148 19.1375 11.5357 18.9488 9.24169C18.7601 6.94769 17.6997 4.81318 15.9855 3.27704C14.2714 1.7409 12.0338 0.919903 9.73295 0.982863C7.43207 1.04582 5.24275 1.98795 3.61517 3.61553C1.98759 5.24311 1.04546 7.43243 0.982497 9.73332C0.919537 12.0342 1.74053 14.2718 3.27667 15.9859C4.81281 17.7001 6.94733 18.7605 9.24133 18.9492C11.5353 19.1379 13.8144 18.4405 15.61 17.0004L19.29 20.6804C19.383 20.7741 19.4936 20.8485 19.6154 20.8993C19.7373 20.9501 19.868 20.9762 20 20.9762C20.132 20.9762 20.2627 20.9501 20.3846 20.8993C20.5065 20.8485 20.6171 20.7741 20.71 20.6804C20.8903 20.4939 20.991 20.2447 20.991 19.9854C20.991 19.7261 20.8903 19.4769 20.71 19.2904ZM10 17.0004C8.61556 17.0004 7.26218 16.5899 6.11103 15.8207C4.95989 15.0515 4.06268 13.9583 3.53287 12.6792C3.00306 11.4001 2.86443 9.99263 3.13453 8.63476C3.40463 7.27689 4.07131 6.02961 5.05028 5.05065C6.02925 4.07168 7.27653 3.40499 8.63439 3.1349C9.99226 2.8648 11.3997 3.00342 12.6788 3.53324C13.9579 4.06305 15.0511 4.96026 15.8203 6.1114C16.5895 7.26255 17 8.61592 17 10.0004C17 11.8569 16.2625 13.6374 14.9498 14.9501C13.637 16.2629 11.8565 17.0004 10 17.0004Z"
                  fill="#002E5E"/>
            </svg>
            <svg class="toggle-search__show" width="18" height="18" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10.41 9.00019L16.71 2.71019C16.8983 2.52188 17.0041 2.26649 17.0041 2.00019C17.0041 1.73388 16.8983 1.47849 16.71 1.29019C16.5217 1.10188 16.2663 0.996094 16 0.996094C15.7337 0.996094 15.4783 1.10188 15.29 1.29019L9 7.59019L2.71 1.29019C2.5217 1.10188 2.2663 0.996094 2 0.996094C1.7337 0.996094 1.4783 1.10188 1.29 1.29019C1.1017 1.47849 0.995909 1.73388 0.995908 2.00019C0.995908 2.26649 1.1017 2.52188 1.29 2.71019L7.59 9.00019L1.29 15.2902C1.19627 15.3831 1.12188 15.4937 1.07111 15.6156C1.02034 15.7375 0.994202 15.8682 0.994202 16.0002C0.994202 16.1322 1.02034 16.2629 1.07111 16.3848C1.12188 16.5066 1.19627 16.6172 1.29 16.7102C1.38296 16.8039 1.49356 16.8783 1.61542 16.9291C1.73728 16.9798 1.86799 17.006 2 17.006C2.13201 17.006 2.26272 16.9798 2.38458 16.9291C2.50644 16.8783 2.61704 16.8039 2.71 16.7102L9 10.4102L15.29 16.7102C15.383 16.8039 15.4936 16.8783 15.6154 16.9291C15.7373 16.9798 15.868 17.006 16 17.006C16.132 17.006 16.2627 16.9798 16.3846 16.9291C16.5064 16.8783 16.617 16.8039 16.71 16.7102C16.8037 16.6172 16.8781 16.5066 16.9289 16.3848C16.9797 16.2629 17.0058 16.1322 17.0058 16.0002C17.0058 15.8682 16.9797 15.7375 16.9289 15.6156C16.8781 15.4937 16.8037 15.3831 16.71 15.2902L10.41 9.00019Z"
                  fill="#002E5E"/>
            </svg>

          </a>
        </li>
        <li class="aside__tools-calendar">
          <a href="/calendar">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M17 2H15V1C15 0.734784 14.8946 0.48043 14.7071 0.292893C14.5196 0.105357 14.2652 0 14 0C13.7348 0 13.4804 0.105357 13.2929 0.292893C13.1054 0.48043 13 0.734784 13 1V2H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V2H3C2.20435 2 1.44129 2.31607 0.87868 2.87868C0.316071 3.44129 0 4.20435 0 5V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H17C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17V5C20 4.20435 19.6839 3.44129 19.1213 2.87868C18.5587 2.31607 17.7956 2 17 2ZM18 17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10H18V17ZM18 8H2V5C2 4.73478 2.10536 4.48043 2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4H5V5C5 5.26522 5.10536 5.51957 5.29289 5.70711C5.48043 5.89464 5.73478 6 6 6C6.26522 6 6.51957 5.89464 6.70711 5.70711C6.89464 5.51957 7 5.26522 7 5V4H13V5C13 5.26522 13.1054 5.51957 13.2929 5.70711C13.4804 5.89464 13.7348 6 14 6C14.2652 6 14.5196 5.89464 14.7071 5.70711C14.8946 5.51957 15 5.26522 15 5V4H17C17.2652 4 17.5196 4.10536 17.7071 4.29289C17.8946 4.48043 18 4.73478 18 5V8Z"
                  fill="#002E5E"/>
            </svg>
          </a>
        </li>
      </ul>
    </nav>

    <nav class="aside__socials d-none d-md-flex">
      <ul>
        <li><a href="https://vk.com/exportedu">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.0729 0H6.9375C1.33331 0 0 1.33331 0 6.92706V13.0625C0 18.6666 1.32294 20 6.92706 20H13.0625C18.6667 20 20 18.6771 20 13.0729V6.9375C20 1.33331 18.6771 0 13.0729 0ZM16.1458 14.2708H14.6875C14.1354 14.2708 13.9688 13.8229 12.9792 12.8333C12.1146 12 11.75 11.8958 11.5312 11.8958C11.2292 11.8958 11.1458 11.9791 11.1458 12.3958V13.7083C11.1458 14.0625 11.0312 14.2708 10.1042 14.2708C9.20458 14.2104 8.33219 13.9371 7.55888 13.4735C6.78557 13.0098 6.13346 12.3691 5.65625 11.6041C4.52336 10.194 3.73511 8.53906 3.35419 6.77081C3.35419 6.55206 3.4375 6.35413 3.85419 6.35413H5.3125C5.6875 6.35413 5.82294 6.52081 5.96875 6.90625C6.67706 8.98956 7.88544 10.8021 8.375 10.8021C8.5625 10.8021 8.64581 10.7188 8.64581 10.25V8.10413C8.58331 7.125 8.0625 7.04163 8.0625 6.6875C8.06936 6.59407 8.11236 6.50699 8.18237 6.44475C8.25239 6.3825 8.34391 6.35 8.4375 6.35413H10.7292C11.0417 6.35413 11.1458 6.51038 11.1458 6.88538V9.78125C11.1458 10.0938 11.2812 10.1979 11.375 10.1979C11.5625 10.1979 11.7083 10.0938 12.0521 9.75C12.7907 8.84918 13.3943 7.84559 13.8438 6.77081C13.8896 6.64149 13.9766 6.53074 14.0913 6.4555C14.2061 6.38025 14.3423 6.34465 14.4792 6.35413H15.9375C16.375 6.35413 16.4688 6.57288 16.375 6.88538C15.8444 8.07369 15.1878 9.20168 14.4167 10.25C14.2604 10.4896 14.1979 10.6146 14.4167 10.8958C14.5625 11.1146 15.0729 11.5416 15.4167 11.9479C15.9167 12.4466 16.3318 13.0237 16.6458 13.6562C16.7708 14.0625 16.5625 14.2708 16.1458 14.2708Z"
                fill="#002E5E"/>
          </svg>
        </a>
        </li>
      </ul>
    </nav>

    <nav class="aside__accessibility d-none d-md-flex">
      <ul>
        <li><a href="/accessibility">
          <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.92 7.6C18.9 2.91 15.1 0 11 0C6.90001 0 3.10001 2.91 1.08001 7.6C1.02494 7.72617 0.996521 7.86234 0.996521 8C0.996521 8.13766 1.02494 8.27383 1.08001 8.4C3.10001 13.09 6.90001 16 11 16C15.1 16 18.9 13.09 20.92 8.4C20.9751 8.27383 21.0035 8.13766 21.0035 8C21.0035 7.86234 20.9751 7.72617 20.92 7.6ZM11 14C7.83001 14 4.83001 11.71 3.10001 8C4.83001 4.29 7.83001 2 11 2C14.17 2 17.17 4.29 18.9 8C17.17 11.71 14.17 14 11 14ZM11 4C10.2089 4 9.43552 4.2346 8.77773 4.67412C8.11993 5.11365 7.60724 5.73836 7.30449 6.46927C7.00174 7.20017 6.92252 8.00444 7.07686 8.78036C7.2312 9.55628 7.61217 10.269 8.17158 10.8284C8.73099 11.3878 9.44372 11.7688 10.2196 11.9231C10.9956 12.0775 11.7998 11.9983 12.5307 11.6955C13.2616 11.3928 13.8864 10.8801 14.3259 10.2223C14.7654 9.56448 15 8.79113 15 8C15 6.93913 14.5786 5.92172 13.8284 5.17157C13.0783 4.42143 12.0609 4 11 4ZM11 10C10.6044 10 10.2178 9.8827 9.88887 9.66294C9.55997 9.44318 9.30362 9.13082 9.15225 8.76537C9.00087 8.39991 8.96126 7.99778 9.03843 7.60982C9.11561 7.22186 9.30609 6.86549 9.58579 6.58579C9.8655 6.30608 10.2219 6.1156 10.6098 6.03843C10.9978 5.96126 11.3999 6.00087 11.7654 6.15224C12.1308 6.30362 12.4432 6.55996 12.6629 6.88886C12.8827 7.21776 13 7.60444 13 8C13 8.53043 12.7893 9.03914 12.4142 9.41421C12.0391 9.78929 11.5304 10 11 10Z"
                fill="#002E5E"/>
            <path
                d="M11 10C10.6044 10 10.2178 9.8827 9.88887 9.66294C9.55997 9.44318 9.30362 9.13082 9.15225 8.76537C9.00087 8.39991 8.96126 7.99778 9.03843 7.60982C9.11561 7.22186 9.30609 6.86549 9.58579 6.58579C9.8655 6.30608 10.2219 6.1156 10.6098 6.03843C10.9978 5.96126 11.3999 6.00087 11.7654 6.15224C12.1308 6.30362 12.4432 6.55996 12.6629 6.88886C12.8827 7.21776 13 7.60444 13 8C13 8.53043 12.7893 9.03914 12.4142 9.41421C12.0391 9.78929 11.5304 10 11 10Z"
                fill="#002E5E"/>
          </svg>
        </a>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      openMenu: false,
      openSearch: false,
      hash: window.location.hash
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => this.hash = window.location.hash)
  },
  methods: {
    toggle() {
      this.openMenu = !this.openMenu
    },
    toggleSearch() {
      this.openSearch = !this.openSearch
    }
  }
}
</script>

<style scoped>

</style>