// @ts-nocheck
import {mapGetters} from "vuex";
import {eventBus} from "@/main";

export default {
    computed: {
        ...mapGetters(['acMenu']),
        currentIndex() {
            return this.routeItems.indexOf(this.$route.name)
        },
        routeItems() {
            return this.acMenu.map((menu) => {
                return menu.route
            })
        },
        lastIndex() {
            return this.acMenu.length - 1;
        }
    },
    methods: {
        goBack() {
            this.$router.push(this.acMenu[this.currentIndex - 1].link).catch(() => {})
        },
        goForward() {
            this.$router.push(this.acMenu[this.currentIndex + 1].link).catch(() => {})
        },
        send() {
            eventBus.$emit('on-send')
        }
    }
}