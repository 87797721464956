<template>
  <div class="orders-item d-block">
    <template v-if="model">
      <base-modal title="Отправка потока"
                  content="Отправить выбранный поток на проверку?"
                  modal-class="modal-xs"
                  :id="'send-group-' + model.id"
                  :confirm-mode="true"
                  v-model="sendModal">
        <button @click="sendGroup()" type="button" class="btn btn-md btn-primary">Да</button>
      </base-modal>
      <base-modal title="Удаление потока"
                  content="Вы уверены, что хотите удалить выбранный поток?"
                  header-class="modal-header-danger-bg"
                  modal-class="modal-xs"
                  :id="'delete-group-' + model.id"
                  :confirm-mode="true"
                  v-model="deleteModal">
        <button @click="deleteGroup()" type="button" class="btn btn-md btn-primary">Да</button>
      </base-modal>
      <base-modal v-if="getOrders.length" v-model="showModal" :id="`addToGroup-${model.id}`" :title="`Добавить компании в поток`"
                  modal-class="modal-xl"
                  body-class="p-0"
                  :icon="false">
        <div class="d-flex align-self-end mt-1">
          <button @click="addToGroup()" :disabled="!checkedOrders.length" class="btn btn-primary mr-3">
            Добавить в поток
          </button>
          <hr/>
        </div>
        <template v-if="getOrders.length">
          <msp-forsage-orders-item v-for="order in getOrders" v-model="order.checked" :key="'order-' + order.id" :order="order" :show-detail="false" />
        </template>
        <div v-else>Компаний в потоке пока нет</div>
      </base-modal>
      <base-modal v-model="editModal" :id="`editModal-${model.id}`" :title="`Редактировать поток`" modal-class="modal-lg" :icon="false">
        <msp-forsage-group-form v-if="model" v-model="model" :region="region" />
      </base-modal>
    </template>
    <div class="section__lk-content-item pt-5">
      <div class="section__lk-content-item-left">
        <div class="section__lk-content-item-data">
          <div class="section__lk-content-item-data-content">
            <div class="d-flex align-items-start mb-1">
              <div class="mr-1">
                <icon-group />
              </div>
              <div class="m-0" @click.prevent="openGroup()">
                <div class="section__lk-content-item-subheader">
                  Поток
                </div>
                <div class="text-dark">
                  <slot name="title"></slot>
                </div>
              </div>
            </div>
          </div>
          <div class="section__lk-content-item-data-content">
            <div class="d-flex align-items-start">
              <div class="mr-1">
                <icon-calendar/>
              </div>
              <div class="m-0">
                <div class="section__lk-content-item-subheader">
                  Даты проведения
                </div>
                <div class="text-dark">
                  <slot name="range"></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__lk-content-item-right section__lk-content-item-data border-0 align-items-start justify-content-between folders-item">
        <div class="edit-buttons-block" v-if="isEditable">
          <button v-if="model.companies.length >= 7" class="btn btn-info bg-primary pt-0 pb-0" @click="() => sendModal = true">
            На проверку
          </button>
            <span>
              <button class="btn btn-info btn-circle p-1" @click="editGroup()">
                <icon-edit fill="#fff" />
              </button>
            </span>
            <span>
              <button @click="show()" class="btn btn-info btn-circle p-1">
                <icon-focus-add fill="#fff" />
              </button>
            </span>
            <span>
              <button @click.prevent="() => deleteModal = true" type="button" class="btn btn-danger p-1">
                <icon-trash fill="#fff" />
              </button>
            </span>
        </div>
        <div>
          <div class="d-flex mb-1">
            <div class="mr-1">
              <icon-clock/>
            </div>
            <div>
              <div class="section__lk-content-item-subheader">
                <slot name="status"></slot>
              </div>
              <div class="text-dark">
                <slot name="date"></slot>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="mr-1">
              <icons-building />
            </div>
            <div>
              <div class="section__lk-content-item-subheader">
                Всего компаний
              </div>
              <div class="text-dark">
                <slot name="companies"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="admin-comment collapse" :class="{show: group.open}" v-if="model && model.status === 'rejected'">
      <admin-comments-item index="0">
        <template v-slot:date>
          {{ model.modified_at | formatDate('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:comment>
          <div v-if="model.comment" v-html="model.comment"></div>
          <div v-else>Администратор не оставил комментарий</div>
        </template>
      </admin-comments-item>
    </div>
    <div class="collapse pt-2 pl-5 pb-2" :class="{show: group.open}">
      <template v-if="group.companies.length">
        <msp-forsage-orders-item v-for="order in group.companies" v-model="order.checked" :key="order.id" :can-delete="isEditable" :show-detail="false" :order="order" />
      </template>
      <span v-else>В потоке нет компаний</span>
    </div>
  </div>
</template>

<script>
import IconGroup from "@/components/icons/IconGroup.vue";
import IconClock from "@/components/icons/IconClock.vue";
import IconsBuilding from "@/components/icons/IconsBuilding.vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import {eventBus} from "@/main";
import modalMixin from "@/mixins/modalMixin";
import BaseModal from "@/components/modal/BaseModal.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import MspForsageGroupForm from "@/views/profile/mspForsageOperator/components/MspForsageGroupForm.vue";
import {mapGetters} from "vuex";
import MspForsageOrdersItem from "@/views/profile/mspForsageOperator/components/MspForsageOrdersItem.vue";
import ApiService from "../../../../../services/api.service";
import Vue from "vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconFocusAdd from "@/components/icons/IconFocusAdd.vue";
import AdminCommentsItem from "@/components/AdminCommentsItem.vue";

export default {
  name: "MspForsageGroupItem",
  components: {
    AdminCommentsItem,
    IconFocusAdd,
    IconTrash,
    MspForsageOrdersItem,
    MspForsageGroupForm,
    IconEdit,
    BaseModal,
    IconCalendar,
    IconsBuilding,
    IconClock,
    IconGroup
  },
  mixins: [modalMixin],
  data() {
    return {
      model: null,
      editModal: false,
      sendModal: false,
      deleteModal: false
    }
  },
  props: {
    status: {
      type: String,
      default() {
        return 'init'
      }
    },
    group: {
      type: Object,
      required: true
    }
  },
  methods: {
    async editGroup() {
      this.editModal = true
    },
    async sendGroup() {
      await ApiService.put('msp-forsage/groups/' + this.model.id, {
        status: 'send'
      })
      eventBus.$emit('msp-groups:update')
      this.sendModal = false
    },
    async deleteGroup() {
      await ApiService.delete('msp-forsage/groups/' + this.model.id)
      eventBus.$emit('msp-groups:update')
      this.deleteModal = false
    },
    async addToGroup() {
      await ApiService.put('msp-forsage/groups/' + this.group.id, {
        orders: this.checkedOrders.map(order => order.id),
        title: this.group.title
      })
      this.uncheckOrders()
      eventBus.$emit('msp-groups:update')
    },
    openGroup() {
      Vue.set(this.group, 'open', !this.group.open)
    },
    uncheckOrders() {
      this.getOrders.forEach((order) => {
        delete order.checked
      })
    },
  },
  mounted() {
    eventBus.$on('msp-groups:update', () => {
      this.editModal = false
      this.showModal = false
    })
    this.model = this.group
    this.model.range = [
      this.$formatDate(this.model.start_date, 'DD.MM.YYYY'),
      this.$formatDate(this.model.end_date, 'DD.MM.YYYY'),
    ]
  },
  computed: {
    isEditable() {
      return this.model && this.model.status !== 'send' && this.model.status !== 'approved' && this.model.status !== 'crm'
    },
    region() {
      return this.$parent.$parent.region ? this.$parent.$parent.region.id : null
    },
    ...mapGetters(['mspForsageOrders'])    ,
    checkedOrders() {
      return this.getOrders.filter(order => order.checked)
    },
    getOrders() {
      let orders = [];

      if(this.mspForsageOrders.orders) {
        orders = this.mspForsageOrders.orders.filter((order) => {
          return order.status === 'approved' && !order.has_group && !order.has_folder
        })
      }

      if(this.mspForsageOrders.folders) {
        this.mspForsageOrders.folders.forEach((folder) => {
          folder.orders.forEach((order) => {
            if(order.status === 'approved' && !order.has_group && !order.has_folder) {
              orders.push(order)
            }
          })
        })
      }

      return orders;
    }
  },
  watch: {
    group(val) {
      this.model = val
    }
  }
}
</script>

<style lang="scss">
.section__lk-content-item-right:not(.folders-item) {
  width: 30rem;
  @media (max-width: 576px){
    width: 100%;
  }
}
.collapse {
  .section__lk-content-item {
    background: none!important;
  }
}
</style>