<template>
  <div>
    <base-input label="ОГРН/ОГРНИП организации" :mask="{mask: '000000000000000', lazy: true}" name="ogrn" v-model="model.ogrn"></base-input>
    <base-input label="Наименование организации" name="name" v-model="model.name"></base-input>
    <base-input label="Сфера деятельности" name="company_activity_field" v-model="model.company_activity_field"></base-input>
    <base-input label="ИНН организации" name="tin" v-model="model.tin"></base-input>
    <base-input label="Организационно-правовая форма организации" name="type" v-model="model.type"></base-input>
    <base-input v-if="showPost" label="Должность" name="post" v-model="model.position"></base-input>
  </div>
</template>

<script>
import BaseInput from "@/components/form/BaseInput.vue";
import vmMixin from "@/mixins/vmMixin";
import ApiService from "../../../services/api.service";
export default {
  name: "CompanySettingsForm",
  components: {
    BaseInput
  },
  inject:['$validator'],
  mixins: [vmMixin],
  props: {
    showPost: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  computed: {
    ogrn() {
      return this.model.ogrn
    }
  },
  watch: {
    ogrn: {
      handler: async function(val) {
        if(val === '' || val === undefined) {
          return;
        }
        const res = await ApiService.get('userorganization/spark', {
          params: {
            ogrn: val
          }
        })

        if (res.status < 300) {
          this.model = res.data
        }
      }
    },
  }
}
</script>

<style scoped>

</style>