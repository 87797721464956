<template>
  <form-separator>
    <h3>Документы для загрузки</h3>
    <base-upload :required="true" id="scan_passport" name="scan_passport" title="Скан паспорта" v-model="docs.scan_passport"/>
    <base-upload :required="true" id="scan_diploma" name="scan_diploma" title="Скан диплома о высшем образовании" v-model="docs.scan_diploma"/>
    <base-upload :required="true" id="scan_snils" name="scan_snils" title="Скан СНИЛС" v-model="docs.scan_snils"/>
  </form-separator>
</template>

<script>
import FormSeparator from "@/components/form/FormSeparator.vue";
import BaseUpload from "@/components/form/BaseUpload.vue";
import ApiService from "../../services/api.service";

export default {
  name: "LectureOrderDocs",
  components: {BaseUpload, FormSeparator},
  props: ['value'],
  data() {
    return {
      firstLoad: true,
      docs: {
        scan_passport: null,
        scan_diploma: null,
        scan_snils: null
      }
    }
  },
  async mounted() {
    this.docs = (await ApiService.get('userdocs/' + this.$route.params.id + '/event')).data
    this.firstLoad = false
  },
  methods: {
    getMedia(val, field) {
      return val[field] ? val[field].id : null
    },
  },
  watch: {
    docs: {
      handler: function(val) {
        if(val.id && !this.firstLoad) {
          this.$emit('input', val)
          ApiService.put('userdocs/' + val.id, {
            scan_snils: this.getMedia(val, 'scan_snils'),
            scan_diploma: this.getMedia(val, 'scan_diploma'),
            scan_passport: this.getMedia(val, 'scan_passport'),
          })
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>