// @ts-nocheck
export default {
    props: {
        fill: {
            type: String,
            default() {
                return '#1C75BC'
            }
        }
    }
}