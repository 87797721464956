<template>
  <div>
    <form @submit.prevent="submit()">
      <div v-if="statusText" class="badge" :class="'badge-' + this.status">{{ statusText }}</div>
      <el-table
          :data="tableData"
          :cell-class-name="cellClassName"
          :row-class-name="rowClassName"
          :span-method="spanMethod"
          style="width: 100%!important;">
      >
        <el-table-column prop="label" />
        <el-table-column prop="year1" :label="year1">
          <template v-slot="{row}">
            <input class="form-control" :disabled="statusText" :class="{invalid: !validate(row.year1)}" v-model.number="row.year1"  />
          </template>
        </el-table-column>
        <el-table-column prop="year2" :label="year2">
          <template v-slot="{row}">
            <input class="form-control" :disabled="statusText" :class="{invalid: !validate(row.year2)}" v-model.number="row.year2"  />
          </template>
        </el-table-column>
        <el-table-column prop="year3" :label="year3">
          <template v-slot="{row}">
            <input class="form-control" :disabled="statusText" :class="{invalid: !validate(row.year3)}" v-model.number="row.year3"  />
          </template>
        </el-table-column>
        <el-table-column prop="points" label="Баллы">
          <template v-slot="{row}">
            {{ row.points }}
          </template>
        </el-table-column>
      </el-table>
      <div v-if="!statusText" class="d-flex justify-content-end mt-5">
        <button :disabled="!validateAll" type="submit" class="btn btn-primary">Рассчитать</button>
      </div>
    </form>
    <div v-if="statusText" class="d-flex justify-content-between mt-5">
      <span>Общий балл: {{ total }}</span>
      <button @click.prevent="reset()" type="button" class="btn btn-warning text-white">Пересчитать</button>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import vmMixin from "@/mixins/vmMixin";
import {eventBus} from "@/main";

const CURRENT_DATE = new Date();
const CURRENT_YEAR = CURRENT_DATE.getMonth() >= 4 ? CURRENT_DATE.getFullYear() - 1 : CURRENT_DATE.getFullYear() - 2;
const STATUS = {
  'success': 'Хорошее финансовое положение компании',
  'danger': 'Плохое финансовое положение компании',
  'warning': 'Среднее финансовое положение компании',
  'init': false
}
export default {
  name: "FinanceDiagnosticForm",
  data() {
    return {
      year1: (CURRENT_YEAR - 2).toString(),
      year2: (CURRENT_YEAR - 1).toString(),
      year3: (CURRENT_YEAR).toString(),
      status: 'init',
      total: 0,
      tableData: [
        {
          label: 'Динамика выручки',
        },
        {
          label: 'Динамика выручки',
          year1: 0,
          year2: 0,
          year3: 0,
          points: 0,
        },
        {
          label: 'Динамика прибыли от продаж',
        },
        {
          label: 'Динамика прибыли от продаж',
          year1: 0,
          year2: 0,
          year3: 0,
          points: 0,
        },
        {
          label: 'Чистые активы'
        },
        {
          label: 'Чистые активы',
          year1: 0,
          year2: 0,
          year3: 0,
          points: 0,
        }
      ]
    }
  },
  mixins: [vmMixin],
  methods: {
    cellClassName({columnIndex}) {
      if(!columnIndex) {
        return 'hidden-sm-only';
      }
    },
    rowClassName({rowIndex}) {
      if(rowIndex % 2 === 0) {
        return 'label-row';
      }
    },
    spanMethod({rowIndex, columnIndex}) {
      if (rowIndex % 2 === 0) {
        if(!columnIndex) {
          return {
            rowspan: 1,
            colspan: 4
          };
        } else {
          return {
            rowspan: 1,
            colspan: 0
          };
        }
      }
    },
    validate(year) {
      return typeof year === 'number'
    },
    async submit() {
      if(!this.validateAll) {
        return;
      }

      let result = await ApiService.post('/msp-forsage/finance', {
        data: this.tableData.filter((data, index) => {
          return index % 2 !== 0
        }),
        order: this.model.id,
        years: [this.year1,this.year2,this.year3]
      })

      this.tableData = this.tableData.map((data, index) => {
        if(index % 2 !== 0) {
          return result.data.params[(index - 1) / 2]
        }
        return data
      })
      this.status = result.data.status
      this.total = result.data.total
      eventBus.$emit('order:update', {
        ...this.model,
        finance: result.data
      })
    },
    reset() {
      this.status = 'init'
    }
  },
  mounted() {
    if(Object.values(this.model.finance).length) {
      this.tableData = this.tableData.map((data, index) => {
        if(index % 2 !== 0) {
          return this.model.finance.params[(index - 1) / 2]
        }
        return data
      })
      this.status = this.model.finance.status
      this.total = this.model.finance.total
    }
  },
  computed: {
    statusText() {
      return STATUS[this.status]
    },
    validateAll() {
      let valid = true
      if(typeof this.tableData === "undefined") {
        return false
      }
      this.tableData.forEach((data, index) => {
        if(index % 2 !== 0) {
          if(data.year1 === "" || data.year2 === "" || data.year3 === "") {
            valid = false
          }
        }
      })
      return valid;
    },
  }
}
</script>

<style lang="scss">
.label-row {
  display: none;
}
.el-table__header-wrapper table {
  max-width: calc(100% - 48px)!important;
  width: calc(100% - 48px)!important;
}
@media (max-width: 768px) {
  .hidden-sm-only, thead tr th:first-child {
    display: none !important
  }
  .label-row {
    .hidden-sm-only {
      display: table-cell !important;
    }
    display: table-row!important;
    td:not(:first-child) {
      display: none;
    }
  }
}
</style>