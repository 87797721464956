import ApiService from "../../services/api.service";
export default {
    state: {
        regionteam: null
    },
    actions: {
        async getRegionteam(ctx: any) {
            const res = await ApiService.get('regionteam/my')
            if(res.status < 300) {
                ctx.commit('setRegionteam', res.data)
            }
        },
        async putRegionteam(ctx: any, data: any) {
            const res = await ApiService.put('regionteams/' + data.id, data)
            if(res.status < 300) {
                ctx.commit('setRegionteam', res.data)
            }
            return res.status < 300
        },
        async deleteInvite(ctx: any, id: number) {
            const res = await ApiService.delete('regionteaminvites/' + id)
            if(res.status < 300) {
                const res = await ApiService.get('regionteam/my')
                ctx.commit('setRegionteam', res.data)
            }
            return res.status < 300
        },
    },
    mutations: {
        setRegionteam(state: any, data: any) {
            state.regionteam = data
        }
    },
    getters: {
        regionteam(state: any) {
            return state.regionteam
        }
    }
}
